import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import SortArrows from '@rio-cloud/rio-uikit/SortArrows';
import SortDirection from '@rio-cloud/rio-uikit/SortDirection';
import { sortByProperty } from '@rio-cloud/rio-uikit/SortUtils';
import classNames from 'classnames';
import isEmpty from 'lodash/fp/isEmpty';
import React, { useState } from 'react';
import { ServiceProvider, ServiceProviderAction } from './serviceProvider.types';
import { ServiceProviderColumn, serviceProviderColumnDefinition } from './ServiceProviderTableColumns';
import { ServiceProvidersTableRow } from './ServiceProvidersTableRow';
import { FormattedMessage, useIntl } from 'react-intl';
import { ColumnDefinition } from '../sharedComponents/table/SortedTableHeader';

const getSortDir = (sortDir: string, sortBy: string, previousSortBy: string) => {
    if (sortBy === previousSortBy) {
        return sortDir === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
    }
    return SortDirection.ASCENDING;
};

type ServiceProvidersTableProps = {
    serviceProviders: ServiceProvider[];
    allowedActions: ServiceProviderAction[];
};

export const ServiceProvidersTable = ({ serviceProviders, allowedActions }: ServiceProvidersTableProps) => {
    const [sortBy, setSortBy] = useState(ServiceProviderColumn.ACTIVATION_STATUS);
    const [sortDir, setSortDir] = useState<string>(SortDirection.DESCENDING);

    const intl = useIntl();

    const handleSortChange = (newSortBy: ServiceProviderColumn) => {
        if (newSortBy !== undefined && newSortBy !== null) {
            const newSortDir = getSortDir(sortDir, newSortBy, sortBy);
            setSortBy(newSortBy);
            setSortDir(newSortDir);
        }
    };

    const renderTableHead = ({ column, labelId, sortable }: ColumnDefinition<ServiceProviderColumn>) => {
        const tableHeadClassNames = classNames('user-select-none', { 'sort-column': sortable });

        return (
            <th
                key={column}
                className={tableHeadClassNames}
                onClick={() => sortable && handleSortChange(column)}
                data-field={column}
                title={intl.formatMessage({ id: labelId })}
            >
                <span>
                    {sortable && (sortBy === column ? <SortArrows direction={sortDir} /> : <SortArrows />)}
                    <span>
                        <FormattedMessage id={labelId} />
                    </span>
                </span>
            </th>
        );
    };

    const rows = sortByProperty(serviceProviders, sortBy as keyof ServiceProvider, sortDir);

    const tableClassNames = classNames(
        'table',
        'table-layout-fixed',
        'table-column-overflow-hidden',
        'table-bordered',
        'table-sticky',
        'table-head-filled',
    );

    return (
        <div id="ActivationRequests">
            <div>
                <table className={tableClassNames}>
                    <colgroup>
                        {serviceProviderColumnDefinition.map((def) => (
                            <col key={def.column} />
                        ))}
                    </colgroup>
                    <thead>
                        <tr>{serviceProviderColumnDefinition.map(renderTableHead)}</tr>
                    </thead>
                    <tbody>
                        {isEmpty(rows) && (
                            <tr>
                                <td colSpan={serviceProviderColumnDefinition.length}>
                                    <NotFoundState
                                        outerClassName="border-none"
                                        headline={<FormattedMessage id="outboundOrderBook.serviceProviders.notFound" />}
                                    />
                                </td>
                            </tr>
                        )}
                        {rows.map((serviceProvider: ServiceProvider, index: number) => (
                            <ServiceProvidersTableRow
                                key={index}
                                serviceProvider={serviceProvider}
                                allowedActions={allowedActions}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
