import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import {
    DepartedVehiclesPage,
    IncomingVehiclesPage,
    InventoryVehiclesPage,
    OutgoingVehiclesPage,
} from '../compoundManagement/compoundManagement.types';
import { DepartedVehiclesTableColumn } from '../compoundManagement/departed/table/DepartedVehiclesTableColumn';
import { VehicleInventoryTableColumn } from '../compoundManagement/inventory/table/VehicleInventoryTableColumn';
import { VehicleType } from '../sharedComponents/common.types';
import { TableSort } from '../sharedComponents/table/SortedTableHeader';
import {
    mapToDepartedVehicleQueryParameters,
    mapToDepartedVehiclesPage,
    mapToIncomingVehicleQueryParameters,
    mapToIncomingVehiclesPage,
    mapToInventoryVehicleQueryParameters,
    mapToInventoryVehiclesPage,
    mapToOutgoingVehicleQueryParameters,
    mapToOutgoingVehiclesPage,
} from './mappers/compoundManagement/compoundManagementMappers';
import { decodeResponse, validateResponse } from './responseUtil';
import {
    DepartedVehiclesPageCodec,
    IncomingVehiclesPageCodec,
    InventoryVehiclesPageCodec,
    OutgoingVehiclesPageCodec,
} from './types/compoundManagement/compoundManagementApi.types';
import { prepareHeaders } from './utils';

export const COMPOUND_MANAGEMENT_INCOMING_VEHICLES_PATH = 'compound-incoming-vehicles';
export const COMPOUND_MANAGEMENT_INVENTORY_VEHICLES_PATH = 'compound-inventory-vehicles';
export const COMPOUND_MANAGEMENT_OUTGOING_VEHICLES_PATH = 'compound-outgoing-vehicles';
export const COMPOUND_MANAGEMENT_DEPARTED_VEHICLES_PATH = 'compound-departed-vehicles';

export type GetIncomingVehiclesQueryParams = {
    limit?: number;
};

export type GetInventoryVehiclesQueryParams = {
    locationId?: string;
    vehicleType?: VehicleType;
    manufacturer?: string;
    arrivalDateStartAt?: Date;
    arrivalDateEndAt?: Date;
    vin?: string;
    sortBy?: TableSort<VehicleInventoryTableColumn>;
    limit?: number;
};

export type GetOutgoingVehiclesQueryParams = {
    limit?: number;
};

export type GetDepartedVehiclesQueryParams = {
    locationId?: string;
    vehicleType?: VehicleType;
    manufacturer?: string;
    arrivalDateStartAt?: Date;
    arrivalDateEndAt?: Date;
    departureDateStartAt?: Date;
    departureDateEndAt?: Date;
    vin?: string;
    sortBy?: TableSort<DepartedVehiclesTableColumn>;
    limit?: number;
};

const IncomingVehiclesTag = 'IncomingVehiclesTag';
const InventoryVehiclesTag = 'InventoryVehiclesTag';
const OutgoingVehiclesTag = 'OutgoingVehiclesTag';
const DepartedVehiclesTag = 'DepartedVehiclesTag';

export const compoundManagementApi = createApi({
    reducerPath: 'compoundManagementApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.backend.FINISHED_VEHICLES_SERVICE, prepareHeaders }),
    tagTypes: [IncomingVehiclesTag, InventoryVehiclesTag, OutgoingVehiclesTag, DepartedVehiclesTag],
    endpoints: (builder) => ({
        getIncomingVehicles: builder.query<IncomingVehiclesPage, GetIncomingVehiclesQueryParams>({
            query: (queryParameters) => ({
                url: COMPOUND_MANAGEMENT_INCOMING_VEHICLES_PATH,
                params: mapToIncomingVehicleQueryParameters(queryParameters),
                validateStatus: validateResponse(IncomingVehiclesPageCodec),
            }),
            transformResponse: decodeResponse(IncomingVehiclesPageCodec, mapToIncomingVehiclesPage),
            providesTags: [IncomingVehiclesTag],
        }),
        getInventoryVehicles: builder.query<InventoryVehiclesPage, GetInventoryVehiclesQueryParams>({
            query: (queryParameters) => ({
                url: COMPOUND_MANAGEMENT_INVENTORY_VEHICLES_PATH,
                params: mapToInventoryVehicleQueryParameters(queryParameters),
                validateStatus: validateResponse(InventoryVehiclesPageCodec),
            }),
            transformResponse: decodeResponse(InventoryVehiclesPageCodec, mapToInventoryVehiclesPage),
            providesTags: [InventoryVehiclesTag],
        }),
        getOutgoingVehicles: builder.query<OutgoingVehiclesPage, GetOutgoingVehiclesQueryParams>({
            query: (queryParameters) => ({
                url: COMPOUND_MANAGEMENT_OUTGOING_VEHICLES_PATH,
                params: mapToOutgoingVehicleQueryParameters(queryParameters),
                validateStatus: validateResponse(OutgoingVehiclesPageCodec),
            }),
            transformResponse: decodeResponse(OutgoingVehiclesPageCodec, mapToOutgoingVehiclesPage),
            providesTags: [OutgoingVehiclesTag],
        }),
        getDepartedVehicles: builder.query<DepartedVehiclesPage, GetDepartedVehiclesQueryParams>({
            query: (queryParameters) => ({
                url: COMPOUND_MANAGEMENT_DEPARTED_VEHICLES_PATH,
                params: mapToDepartedVehicleQueryParameters(queryParameters),
                validateStatus: validateResponse(DepartedVehiclesPageCodec),
            }),
            transformResponse: decodeResponse(DepartedVehiclesPageCodec, mapToDepartedVehiclesPage),
            providesTags: [DepartedVehiclesTag],
        }),
    }),
});

export const { useGetInventoryVehiclesQuery, useGetDepartedVehiclesQuery, useGetIncomingVehiclesQuery } =
    compoundManagementApi;
