import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import { getSortingForCategory, transportAssignmentsSlice } from '../../reducers/transportAssignmentsSlice';
import { AppTableCardsSorting } from '../../sharedComponents/table/AppTableCardsSorting';
import { TableSort } from '../../sharedComponents/table/SortedTableHeader';
import { transportAssignmentColumnDefinition, TransportAssignmentTableColumn } from './TransportAssignmentTableColumns';
import { TableCategory } from './toolbar/CategoriesSelection';

export const TransportAssignmentsCardsSorting = ({ category }: { category: TableCategory }) => {
    const dispatch = useAppDispatch();
    const sorting = useAppSelector(getSortingForCategory(category));

    return (
        <AppTableCardsSorting
            columnDefinition={transportAssignmentColumnDefinition}
            sorting={sorting}
            setSorting={(sort: TableSort<TransportAssignmentTableColumn>) =>
                dispatch(
                    transportAssignmentsSlice.actions.setSortingForCategory({
                        category,
                        sort,
                    }),
                )
            }
        />
    );
};
