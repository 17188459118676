import React from 'react';
import { formatDateInTimeZone, formatDateTimeInTimeZone, formatTimeInTimeZone } from '../sharedComponents/dateHelper';
import { Interval } from 'date-fns';

export const DateTimeIntervalCell = (props: { dateTimeInterval: Interval; timeZone: string }) => {
    const startString = formatDateTimeInTimeZone(props.dateTimeInterval.start, props.timeZone);
    const endString = formatDateTimeInTimeZone(props.dateTimeInterval.end, props.timeZone);

    if (startString === endString) {
        return <span>{startString}</span>;
    }

    return (
        <>
            <span className="display-block">{startString}&nbsp;&ndash;</span>
            <span className="display-block">{endString}</span>
        </>
    );
};

export const DateTimeField = ({ date, timeZone }: { date: Date | Interval; timeZone: string }) => {
    if (date instanceof Date) {
        return (
            <>
                <time className="display-block">{formatDateInTimeZone(date, timeZone)}</time>
                <time>{formatTimeInTimeZone(date, timeZone)}</time>
            </>
        );
    }

    return <DateTimeIntervalCell dateTimeInterval={date} timeZone={timeZone} />;
};
