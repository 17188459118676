import { VehicleId } from '../sharedComponents/common.types';
import { ServiceType } from './serviceType.types';
import { AccessoryType, CollectedAccessories } from './accessory.types';

export enum ServiceOrderStatus {
    OPEN = 'open',
    COMPLETED = 'completed',
    CANCELLED = 'cancelled',
}

export type ServiceOrderPage = {
    readonly items: ServiceOrder[];
    readonly hasMore: boolean;
};

export type ServiceOrderVehicleModel = {
    readonly name: string;
    readonly manufacturer?: string;
};

export type ServiceOrderVehicle = {
    readonly id: VehicleId;
    readonly requestedAccessories: AccessoryType[];
    readonly model?: ServiceOrderVehicleModel;
    readonly collectedAccessories?: CollectedAccessories;
};

export type ServiceOrder = {
    readonly id: string;
    readonly externalOrderId: string;
    readonly vehicle: ServiceOrderVehicle;
    readonly status: ServiceOrderStatus;
    readonly type: ServiceType;
    readonly timeZone: string;
    readonly completedAt?: Date;
    readonly receivedAt: Date;
    readonly generalInformation?: string;
    readonly description?: string;
    readonly hasUnacknowledgedExternalChanges: boolean;
    readonly isFinalized: boolean;
    readonly isArchived: boolean;
};

export enum ServiceOrderCategory {
    CURRENT = 'current',
    ARCHIVE = 'archive',
}
