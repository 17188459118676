import * as t from 'io-ts';
import { NonEmptyString } from 'io-ts-types';
import { fromEnum } from '../responseUtil';
import { ApiStatus } from './transportAssignment/transportAssignmentsApi.types';
import { VehicleCodec } from './vehicleApi.types';
import { AddressAndContactCodec } from './transportAssignment/addressAndContactApi.types';

export enum ApiSort {
    EXPECTED_DELIVERY_AT_ASC = '+expected_delivery_at',
    EXPECTED_DELIVERY_AT_DESC = '-expected_delivery_at',
    CARRIER_ASC = '+carrier',
    CARRIER_DESC = '-carrier',
}

const QueryParameterCodec = t.intersection([
    t.type({
        expected_delivery_after: t.string,
        expected_delivery_before: t.string,
    }),
    t.partial({
        statuses: t.array(fromEnum<ApiStatus>('ApiStatus', ApiStatus)),
        unloading_address_id: t.string,
        carrier_name: t.string,
        sort: fromEnum<ApiSort>('ApiSort', ApiSort),
        limit: t.string,
        q: t.string,
    }),
]);

const CarrierCodec = t.partial({
    name: t.string,
    additional_name: t.string,
});

const CompoundTransportOrderCodec = t.intersection([
    t.type({
        id: NonEmptyString,
        vehicle: VehicleCodec,
        expected_delivery_at: t.string,
        time_zone: t.string,
        status: fromEnum<ApiStatus>('ApiStatus', ApiStatus),
        delivery_location: AddressAndContactCodec,
    }),
    t.partial({
        carrier: CarrierCodec,
        means_of_transport_id: t.string,
    }),
]);

const CompoundTransportOrderSummaryCodec = t.type({
    number_of_vehicles: t.number,
    number_of_transports: t.number,
});

export const CompoundTransportOrderPageCodec = t.type({
    items: t.array(CompoundTransportOrderCodec),
    summary: CompoundTransportOrderSummaryCodec,
    has_more: t.boolean,
});

export type ApiCompoundTransportOrderQueryParameter = t.TypeOf<typeof QueryParameterCodec>;

export type ApiCompoundTransportOrder = t.TypeOf<typeof CompoundTransportOrderCodec>;
export type ApiCompoundTransportOrderPage = t.TypeOf<typeof CompoundTransportOrderPageCodec>;
export type ApiCompoundTransportOrderSummary = t.TypeOf<typeof CompoundTransportOrderSummaryCodec>;
export type ApiCarrier = t.TypeOf<typeof CarrierCodec>;
