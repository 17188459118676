import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../../configuration/setup/store';
import { TransportOrder } from './transportAssignment/transportAssignment.types';
import { transportOrderApi } from './api/transportOrderApi';

export interface AppState {
    sessionExpiredAcknowledged: boolean;
    transportAssignments: TransportOrder[];
}

const initialState: AppState = {
    sessionExpiredAcknowledged: false,
    transportAssignments: [],
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        hideSessionExpiredDialog: (state) => {
            state.sessionExpiredAcknowledged = true;
        },
    },
    // does not create an action creator function only allows you to respond to an action in your slice reducer
    extraReducers: (builder: ActionReducerMapBuilder<AppState>) => {
        builder.addMatcher(
            transportOrderApi.endpoints.getTransportAssignments.matchFulfilled,
            (state, action: PayloadAction<any>) => {
                state.transportAssignments = action.payload;
            },
        );
    },
});

export const { hideSessionExpiredDialog } = appSlice.actions;

export const getSessionExpiredAcknowledged = (state: RootState) => state.app.sessionExpiredAcknowledged;

export const { reducer: appReducer } = appSlice;
