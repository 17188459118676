import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/hooks';
import { getTableViewType, viewSlice } from '../../../reducers/viewSlice';
import TableViewToggles from '@rio-cloud/rio-uikit/lib/es/TableViewToggles';
import TableToolbar from '@rio-cloud/rio-uikit/lib/es/TableToolbar';
import { DateFilter } from './DateFilter';
import { IncomingCompoundTransportOrderFilterModal } from './IncomingCompoundTransportOrderFilterModal';
import { IncomingCompoundTransportOrderStatusFilter } from './IncomingCompoundTransportOrderStatusFilter';
import { TABLE_VIEW_BUTTONS_TEST_ID } from '../../../sharedComponents/commonConfig';
import { IncomingCompoundTransportOrderToolbarSearch } from './IncomingCompoundTransportOrderToolbarSearch';

export const TRANSPORT_INCOMING_TOOLBAR_TEST_ID = 'toolbar';

export const IncomingCompoundTransportOrderTableToolbar = () => {
    const viewType = useAppSelector(getTableViewType);
    const dispatch = useAppDispatch();

    const handleViewTypeChange = (newViewType: TableViewToggles) =>
        dispatch(viewSlice.actions.setTableViewType(newViewType));

    return (
        <TableToolbar>
            <div data-testid={TRANSPORT_INCOMING_TOOLBAR_TEST_ID} className="table-toolbar-container">
                <div className="table-toolbar-group-left">
                    <DateFilter />
                    <div className="table-toolbar-column min-width-300">
                        <IncomingCompoundTransportOrderStatusFilter />
                    </div>
                </div>
                <div className="table-toolbar-group-right">
                    <div className="table-toolbar-column">
                        <IncomingCompoundTransportOrderFilterModal />
                    </div>
                    <div className="table-toolbar-column">
                        <IncomingCompoundTransportOrderToolbarSearch />
                    </div>
                    <div className="table-toolbar-column">
                        <TableViewToggles
                            data-testid={TABLE_VIEW_BUTTONS_TEST_ID}
                            initialViewType={viewType}
                            onViewTypeChange={handleViewTypeChange}
                        />
                    </div>
                </div>
            </div>
        </TableToolbar>
    );
};
