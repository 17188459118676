import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import classNames from 'classnames';
import { getIdentifier } from '../TransportAssignmentUtils';
import { TransportAssignment, TransportAssignmentType } from '../transportAssignment.types';
import { getTransportAssignmentSidebarExpanderStylesAndSettings } from '../expanderStyleAndSettings';
import { UnacknowledgedChangesAlert } from '../../sharedComponents/sidebar/UnacknowledgedChangesAlert';
import { FeatureToggles, useFeatureToggle } from '../../../../configuration/featureToggle/toggleHook';
import { TrackingEnabledBanner } from '../../sharedComponents/TrackingEnabledBanner';
import { StatusLabel } from '../../sharedComponents/StatusLabel';

type SidebarHeaderProps = {
    transportAssignment: TransportAssignment;
    hasUnacknowledgedChanges: boolean;
};

export const SidebarHeader = (props: SidebarHeaderProps) => {
    const { transportAssignment, hasUnacknowledgedChanges } = props;
    const status = transportAssignment.status;
    const [hasChanges, setHasChanges] = useState(hasUnacknowledgedChanges);
    const borderStyles = getTransportAssignmentSidebarExpanderStylesAndSettings(transportAssignment).borderStyle;
    const orderIdString =
        transportAssignment.type === TransportAssignmentType.TRANSPORT_CAPACITY_ORDER
            ? 'outboundPortal.transportAssignments.sidebar.capacityId'
            : 'outboundPortal.transportAssignments.sidebar.orderId';

    return (
        <div className={classNames('bg-lightest')}>
            {hasChanges && (
                <UnacknowledgedChangesAlert
                    handleOnClick={setHasChanges}
                    alertMessage="outboundPortal.transportAssignments.alerts.unacknowledgedChanges"
                />
            )}
            {useFeatureToggle(FeatureToggles.TRACKING, false).value &&
                props.transportAssignment.meansOfTransport?.id !== undefined &&
                transportAssignment.isAutomaticallyTracked && (
                    <TrackingEnabledBanner transportMeansId={props.transportAssignment.meansOfTransport.id} />
                )}
            <div
                className={classNames(
                    borderStyles,
                    'border-style-solid',
                    'border-width-1',
                    'border-bottom-only',
                    'bg-lightest',
                    'display-flex',
                    'gap-10',
                    'padding-20',
                    { 'padding-top-0': hasChanges },
                )}
            >
                <div className="flex-1-1">
                    <div>
                        <FormattedMessage id={orderIdString} />
                    </div>
                    <div className="text-medium word-break text-size-16 margin-right-10">
                        {getIdentifier(transportAssignment)}
                    </div>
                </div>
                <div className="flex-1-1">
                    <div className="margin-right-10">
                        <FormattedMessage id="outboundOrderBook.common.heading.status" />
                    </div>
                    <StatusLabel status={status} />
                </div>
            </div>
        </div>
    );
};
