import { ToolbarSearch } from '../../sharedComponents/filter/ToolbarSearch';
import { compoundManagementSlice, getVinSearchFilter } from '../../reducers/compoundManagementSlice';

export const CompoundManagementVinSearch = () => {
    return (
        <ToolbarSearch
            getSearchTerm={getVinSearchFilter}
            setSearchTerm={compoundManagementSlice.actions.setVinSearchFilter}
            tooltipMessageId="outboundOrderBook.common.table.toolbar.searchVin.tooltip"
            placeholderMessageId="outboundOrderBook.common.table.toolbar.searchVin.placeholder"
        />
    );
};
