import { ApiAssets, ApiAssetsQueryParameter } from '../types/assetsApi.types';
import { Asset } from '../../transportAssignment/assets.types';
import { GetAssetsQueryParam } from '../assetsApi';
import { NonEmptyString } from 'io-ts-types';

export const mapToAssets = (apiAssets: ApiAssets): Asset[] =>
    apiAssets.items.map((apiAsset) => ({
        id: apiAsset.id,
        licensePlate: apiAsset.license_plate,
    }));

export const mapAssetQueryParameter = (queryParameter: GetAssetsQueryParam): ApiAssetsQueryParameter => ({
    account_id: queryParameter.accountId as NonEmptyString,
    status: queryParameter.status,
    type: queryParameter.type,
});
