import {
    ApiActivation,
    ApiActivationStatus,
    ApiActivationStatuses,
    ApiSupplier,
    ApiTermsAndConditionsAgreement,
} from '../types/activationsApi.types';
import { Activation, ActivationStatus, Supplier, TermsAndConditionsAgreement } from '../../activation/activation.types';

export const mapTermsAndConditionsAgreement = (
    apiTermsAndConditionsAgreement: ApiTermsAndConditionsAgreement,
): TermsAndConditionsAgreement => {
    return { accepted: apiTermsAndConditionsAgreement.accepted };
};

export const mapToActivationStatus = (status: ApiActivationStatus): ActivationStatus => {
    switch (status) {
        case ApiActivationStatus.ACTIVE:
            return ActivationStatus.ACTIVE;
        case ApiActivationStatus.PENDING:
            return ActivationStatus.PENDING;
    }
};

export const mapToSupplier = (apiSupplier: ApiSupplier): Supplier => {
    switch (apiSupplier) {
        case ApiSupplier.VW:
            return Supplier.VW;
        case ApiSupplier.RIO_INTERNAL:
            return Supplier.RIO_INTERNAL;
        case ApiSupplier.RIO_MONITORING:
            return Supplier.RIO_MONITORING;
    }
};

export const mapToActivationStatuses = (activationStatuses: ApiActivationStatuses): Activation[] =>
    activationStatuses.activations.map((activation: ApiActivation) => {
        return {
            supplier: mapToSupplier(activation.supplier),
            status: mapToActivationStatus(activation.status),
        };
    });
