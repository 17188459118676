import TableViewToggles, { TableViewTogglesValues } from '@rio-cloud/rio-uikit/lib/es/TableViewToggles';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../configuration/setup/store';

export type ViewState = {
    tableViewType: TableViewTogglesValues;
    isFetchingTriggeredByUser: boolean;
};

export const initialViewState = {
    tableViewType: TableViewToggles.VIEW_TYPE_TABLE,
    isFetchingTriggeredByUser: false,
};

export const viewSlice = createSlice({
    name: 'view',
    initialState: initialViewState,
    reducers: {
        setTableViewType: (state: ViewState, action: PayloadAction<TableViewToggles>) => {
            state.tableViewType = action.payload;
        },
        setIsFetchingTriggeredByUser: (state: ViewState, action: PayloadAction<boolean>) => {
            state.isFetchingTriggeredByUser = action.payload;
        },
    },
});

export const getTableViewType = (state: RootState) => state.portal.view.tableViewType;
export const getIsFetchingTriggeredByUser = (state: RootState) => state.portal.view.isFetchingTriggeredByUser;
