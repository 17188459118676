import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { CompoundTransportOrderPage } from '../compoundTransportOrders/compoundTransportOrder.types';
import {
    mapToCompoundTransportOrderPage,
    mapToCompoundTransportOrderQueryParameters,
} from './mappers/compoundTransportOrderMappers';
import { FilterableApiStatus } from './transportOrderApi';
import { decodeResponse, validateResponse } from './responseUtil';
import { CompoundTransportOrderPageCodec } from './types/compoundTransportOrderApi.types';
import { prepareHeaders } from './utils';
import { TableSort } from '../sharedComponents/table/SortedTableHeader';
import { TableIncomingCompoundTransportOrderColumn } from '../compoundTransportOrders/table/IncomingCompoundTransportOrderColumns';

export const INCOMING_COMPOUND_TRANSPORT_ORDERS_PATH = 'incoming-compound-transport-orders';

export type GetCompoundTransportOrderQueryParams = {
    expectedDeliveryAfter: Date;
    expectedDeliveryBefore: Date;
    statuses?: FilterableApiStatus[];
    carrierName?: string;
    locationId?: string;
    sortBy?: TableSort<TableIncomingCompoundTransportOrderColumn>;
    limit?: number;
    searchTerm?: string;
};

const IncomingCompoundTransportOrderTag = 'IncomingCompoundTransportOrder';

export const compoundTransportOrderApi = createApi({
    reducerPath: 'compoundTransportOrderApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.backend.FINISHED_VEHICLES_SERVICE, prepareHeaders }),
    tagTypes: [IncomingCompoundTransportOrderTag],
    endpoints: (builder) => ({
        getIncomingCompoundTransportOrders: builder.query<
            CompoundTransportOrderPage,
            GetCompoundTransportOrderQueryParams
        >({
            query: (queryParameters) => ({
                url: INCOMING_COMPOUND_TRANSPORT_ORDERS_PATH,
                params: mapToCompoundTransportOrderQueryParameters(queryParameters),
                validateStatus: validateResponse(CompoundTransportOrderPageCodec),
            }),
            transformResponse: decodeResponse(CompoundTransportOrderPageCodec, mapToCompoundTransportOrderPage),
            providesTags: [IncomingCompoundTransportOrderTag],
        }),
    }),
});

export const { useGetIncomingCompoundTransportOrdersQuery } = compoundTransportOrderApi;
