import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isUserSessionExpired } from '../../../../configuration/login/loginSlice';
import { useAppSelector } from '../../../../configuration/setup/hooks';
import { useGetIncomingVehiclesQuery } from '../../api/compoundManagementApi';
import { handleQueryError } from '../../notifications/ErrorNotification';
import { useSetUserTriggeredFetching } from '../../sharedComponents/userTriggeredFetchingHook';
import { IncomingVehiclesTable } from './table/IncomingVehiclesTable';
import { LoadMoreButton } from '../../sharedComponents/table/LoadMoreButton';
import { IncomingVehiclesToolbar } from './IncomingVehiclesToolbar';

export const DEFAULT_COMPOUND_INCOMING_LIMIT_SIZE = 100;
const POLLING_INTERVAL = 60000;

export const IncomingVehicles: FunctionComponent = () => {
    const isSessionExpired = useAppSelector(isUserSessionExpired);
    const [limit, setLimit] = useState(DEFAULT_COMPOUND_INCOMING_LIMIT_SIZE);

    const {
        data: incomingPage,
        isLoading,
        isFetching,
        isError,
        error,
    } = useGetIncomingVehiclesQuery(
        { limit },
        {
            pollingInterval: isSessionExpired ? undefined : POLLING_INTERVAL,
        },
    );

    useSetUserTriggeredFetching({
        isFetching,
        depsWhichTriggerUserFetching: [],
    });

    const incomingVehicles = incomingPage?.items;

    if (isError) {
        console.error(error);
        handleQueryError(error);
    }

    if (isLoading) {
        return (
            <div data-testid="spinner">
                <Spinner />
            </div>
        );
    }

    if (incomingVehicles === undefined) {
        return (
            <ErrorState
                headline={<FormattedMessage id="intl-msg:common-message.error.generic.headline" />}
                message={<FormattedMessage id="intl-msg:common-message.error.generic.message" />}
            />
        );
    }

    return (
        <>
            <IncomingVehiclesToolbar />
            <IncomingVehiclesTable vehicles={incomingVehicles} />
            {incomingVehicles.length > 0 && (
                <LoadMoreButton
                    onLoadMore={() => setLimit(limit + DEFAULT_COMPOUND_INCOMING_LIMIT_SIZE)}
                    hasMoreToLoad={incomingPage?.hasMore ?? false}
                />
            )}
        </>
    );
};
