import noop from 'lodash/noop';
import { FormattedMessage } from 'react-intl';
import { SortDirection } from '../../../sharedComponents/table/SortedTableHeader';
import { IncomingVehicle } from '../../compoundManagement.types';
import { Table } from '../../shared/table/Table';
import { CompoundManagementTableRow } from '../../table/CompoundManagementTableRow';
import { incomingVehiclesColumnDefinition, IncomingVehiclesTableColumn } from './IncomingVehiclesTableColumn';
import { IncomingVehiclesTableDataContent } from './IncomingVehiclesTableDataContent';

type IncomingVehiclesTableProps = {
    readonly vehicles: readonly IncomingVehicle[];
};

export const IncomingVehiclesTable = ({ vehicles }: IncomingVehiclesTableProps) => {
    return (
        <Table
            columnDefinition={incomingVehiclesColumnDefinition}
            sorting={{ dir: SortDirection.ASCENDING, column: IncomingVehiclesTableColumn.EXPECTED_ARRIVAL_DATE }}
            setSorting={noop}
            items={vehicles}
            notFoundMessage={<FormattedMessage id="outboundOrderBook.compoundManagement.incomingVehicles.notFound" />}
        >
            {vehicles.map((vehicle: IncomingVehicle) => (
                <CompoundManagementTableRow
                    key={vehicle.id}
                    columnDefinition={incomingVehiclesColumnDefinition}
                    vehicle={vehicle}
                >
                    {(column) => <IncomingVehiclesTableDataContent column={column} vehicle={vehicle} />}
                </CompoundManagementTableRow>
            ))}
        </Table>
    );
};
