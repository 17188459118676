import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { LabelledInfoCell } from '../../sharedComponents/sidebar/LabelledInfoCell';

type InventoryVehicleIdProps = {
    vehicle: { readonly vin: string };
};

export const InventoryVehicleId: FunctionComponent<InventoryVehicleIdProps> = ({ vehicle }) => {
    return (
        <div
            className={classNames(
                'border-color-light',
                'border-style-solid',
                'border-width-1',
                'border-bottom-only',
                'bg-lightest',
                'display-flex',
                'gap-10',
                'padding-25',
            )}
        >
            <LabelledInfoCell labelMessageId="outboundOrderBook.common.heading.vin">{vehicle.vin}</LabelledInfoCell>
        </div>
    );
};
