import { FunctionComponent } from 'react';
import {
    ColumnDefinition,
    ColumnTableHeader,
    getSortDir,
    SortColumnTableHeader,
    TableSort,
} from '../../../sharedComponents/table/SortedTableHeader';

type TableHeaderProps<TableColumnEnum extends string> = {
    columnDefinition: ColumnDefinition<TableColumnEnum>[];
    sorting: TableSort<TableColumnEnum>;
    setSorting: (sort: TableSort<TableColumnEnum>) => void;
};

// TODO use this for all tables?
export const TableHeader = <TableColumnEnum extends string>({
    columnDefinition,
    sorting,
    setSorting,
}: TableHeaderProps<TableColumnEnum>) => (
    <thead>
        <tr>
            {columnDefinition.map((def) =>
                def.sortable ? (
                    <SortColumnTableHeader
                        key={def.column}
                        labelId={def.labelId}
                        col={def.column}
                        currentSort={sorting}
                        sortingHandler={(column) => setSorting({ column, dir: getSortDir(column, sorting) })}
                    />
                ) : (
                    <ColumnTableHeader key={def.column} labelId={def.labelId} />
                ),
            )}
        </tr>
    </thead>
);
