import { getServiceOrderTableActionButtonConfig } from '../serviceOrderUtils';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import React from 'react';
import { ServiceOrder, ServiceOrderStatus } from '../ServiceOrders.types';

type FooterProps = {
    serviceOrder: ServiceOrder;
    handleArchiveServiceOrder: () => void;
    handleCompleteServiceOrder: () => void;
    onClose: () => void;
    isRequestPending: boolean;
};

export const FooterButtons = (props: FooterProps) => {
    const buttonConfig = getServiceOrderTableActionButtonConfig(
        props.serviceOrder.status,
        props.serviceOrder.isFinalized,
    );
    const hasActionButton = !Boolean(props.serviceOrder.isArchived);
    const isArchiveButton =
        (props.serviceOrder.isFinalized && props.serviceOrder.status === ServiceOrderStatus.COMPLETED) ||
        props.serviceOrder.status === ServiceOrderStatus.CANCELLED;

    const handleActionButtonClick = () =>
        isArchiveButton ? props.handleArchiveServiceOrder() : props.handleCompleteServiceOrder();

    return (
        <div className="display-flex gap-10 flex-row-reverse">
            <Button className={Button.DEFAULT} onClick={props.onClose}>
                <FormattedMessage id="outboundPortal.close" />
            </Button>
            {hasActionButton && (
                <Button
                    bsStyle={buttonConfig.style}
                    className={classNames(buttonConfig.className, { 'btn-loading': props.isRequestPending })}
                    onClick={handleActionButtonClick}
                >
                    {!props.isRequestPending && <span className={buttonConfig.icon} />}
                    <FormattedMessage id={buttonConfig.labelId} />
                </Button>
            )}
        </div>
    );
};
