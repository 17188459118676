import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

type LoadMoreButtonProps = {
    onLoadMore: () => void;
    hasMoreToLoad: boolean;
};

export const LoadMoreButton = (props: LoadMoreButtonProps) => {
    const { onLoadMore, hasMoreToLoad } = props;

    const wrapperClassNames = classNames(
        'LoadMoreButton',
        'align-items-center',
        'display-flex',
        'justify-content-center',
        'margin-top-25',
        'margin-bottom-25',
        'non-printable',
    );

    return (
        <div className={wrapperClassNames}>
            <div className="min-width-150">
                <div className="display-flex justify-content-center">
                    {hasMoreToLoad && (
                        <button
                            className="btn btn-link padding-right-20 margin-top--5"
                            type="button"
                            onClick={onLoadMore}
                        >
                            <span className="rioglyph rioglyph-arrow-down" />
                            <FormattedMessage id="outboundOrderBook.common.button.loadMore" />
                        </button>
                    )}
                    {!hasMoreToLoad && (
                        <span className="padding-top-4 padding-bottom-10">
                            <FormattedMessage id="outboundOrderBook.common.button.everythingLoaded" />
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};
