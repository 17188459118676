import { TransportAssignment } from '../transportAssignment.types';
import { useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ShipmentDetails } from './details/shipmentDetails/ShipmentDetails';
import { TransportAssignmentTransportDetails } from './details/TransportAssignmentTransportDetails';

export enum SelectableDetails {
    TRANSPORT = 'TRANSPORT',
    SHIPMENT = 'SHIPMENT',
}

export const detailSelectorText: Record<SelectableDetails, JSX.Element> = {
    [SelectableDetails.TRANSPORT]: (
        <FormattedMessage id="outboundPortal.transportOrders.details.body.details.transportOrder" />
    ),
    [SelectableDetails.SHIPMENT]: (
        <FormattedMessage id="outboundPortal.transportOrders.details.body.details.shipment" />
    ),
};

export const DetailSelector = (props: {
    detail: SelectableDetails;
    isSelected: boolean;
    onClick: (selected: SelectableDetails) => void;
    isEnabled: boolean;
}) => {
    const className = classNames({
        active: props.isSelected,
        disabled: !props.isEnabled,
        pointerEventsNone: !props.isEnabled,
    });
    const callback = props.isEnabled ? () => props.onClick(props.detail) : () => {};
    return (
        <li className={className} onClick={callback}>
            <span>{detailSelectorText[props.detail]}</span>
        </li>
    );
};

type TransportAssignmentSidebarTabContentProps = {
    readonly selectedDetail: SelectableDetails;
    readonly transportAssignment: TransportAssignment;
};

const TransportAssignmentSidebarTabContent = ({
    selectedDetail,
    transportAssignment,
}: TransportAssignmentSidebarTabContentProps): JSX.Element => {
    switch (selectedDetail) {
        case SelectableDetails.TRANSPORT:
            return <TransportAssignmentTransportDetails transportAssignment={transportAssignment} />;
        case SelectableDetails.SHIPMENT:
            return <ShipmentDetails transportAssignment={transportAssignment} />;
    }
};

export const TransportAssignmentSidebarBodyContent = (props: { transportAssignment: TransportAssignment }) => {
    const initialState = SelectableDetails.TRANSPORT;
    const [selectedDetail, setSelectedDetail] = useState(initialState);

    return (
        <div className="padding-20">
            <div className="nav nav-pills nav-pills-filled nav-justified margin-bottom-15">
                <DetailSelector
                    detail={SelectableDetails.TRANSPORT}
                    isSelected={selectedDetail === SelectableDetails.TRANSPORT}
                    onClick={setSelectedDetail}
                    isEnabled
                />
                <DetailSelector
                    detail={SelectableDetails.SHIPMENT}
                    isSelected={selectedDetail === SelectableDetails.SHIPMENT}
                    onClick={setSelectedDetail}
                    isEnabled
                />
            </div>
            <TransportAssignmentSidebarTabContent
                selectedDetail={selectedDetail}
                transportAssignment={props.transportAssignment}
            />
        </div>
    );
};
