import { TransportAssignmentStatus } from '../../transportAssignment.types';
import { getStatusFilter, transportAssignmentsSlice } from '../../../reducers/transportAssignmentsSlice';
import { StatusFilter } from '../../../sharedComponents/filter/StatusFilter';

export const TransportAssignmentStatusFilter = () => {
    const additionalOptionClasses = new Map([
        [TransportAssignmentStatus.CANCELLATION, 'bg-white'],
        [TransportAssignmentStatus.COMPLETE_CONFIRMED, 'bg-white'],
    ]);

    return (
        <StatusFilter
            getFilterSelector={getStatusFilter}
            setFilterAction={transportAssignmentsSlice.actions.setStatusFilter}
            statusOptions={[
                TransportAssignmentStatus.COMPLETE_CONFIRMED,
                TransportAssignmentStatus.UNCONFIRMED,
                TransportAssignmentStatus.CANCELLATION,
            ]}
            optionClassName={additionalOptionClasses}
        />
    );
};
