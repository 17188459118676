import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { runInBackground } from '../../../configuration/setup/backgroundActions';
import { routes, TRANSPORT_ORDER_ROUTE, WELCOME_ROUTE } from './routes';

export const DefaultRedirect = (props: { isActive: boolean }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(
        () =>
            runInBackground(async () => {
                if (!props.isActive) {
                    await navigate(WELCOME_ROUTE);
                } else if (!routes.includes(pathname)) {
                    await navigate(TRANSPORT_ORDER_ROUTE);
                }
            }, 'DefaultRedirect'),
        [pathname, navigate, props.isActive],
    );

    return null;
};
