import './polyfills';
import './configuration/lang/momentLocales';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';

import { config } from './config';
import { main } from './configuration';
import { handleLoginRedirect } from './configuration/login/redirect';
import { ErrorBoundary } from './features/app/ErrorBoundary';
import { App } from './features/app/App';
import { createRoot } from 'react-dom/client';
import { runInBackground } from './configuration/setup/backgroundActions';
import { EnhancedStore } from '@reduxjs/toolkit';

const renderApplication = (store: EnhancedStore) => {
    const container = document.getElementById('root');
    const root = createRoot(container!);

    root.render(
        <ErrorBoundary>
            <Provider store={store}>
                <HashRouter>
                    <App />
                </HashRouter>
            </Provider>
        </ErrorBoundary>,
    );
};

const handleRedirect = window.location.href.startsWith(config.login.redirectUri as string);

// If we drop support for older browsers we could use a top level await here instead
// of the runInBackground function. But right now our target browsers do not all
// support top level awaits.
runInBackground(handleRedirect ? handleLoginRedirect() : main(renderApplication));
