import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

type LabelledInfoCellProps = PropsWithChildren<{
    labelMessageId: string;
    additionalLabel?: string;
}>;

export const LabelledInfoCell = ({ labelMessageId, additionalLabel, children }: LabelledInfoCellProps) => (
    <div className="flex-1-1 width-50pct">
        <label>
            <FormattedMessage id={labelMessageId} />
            {additionalLabel !== undefined && ` (${additionalLabel})`}
        </label>
        <div className="word-break text-size-16">{children}</div>
    </div>
);
