import TableViewToggles from '@rio-cloud/rio-uikit/lib/es/TableViewToggles';
import { useAppSelector } from '../../../../configuration/setup/hooks';
import { getTableViewType } from '../../reducers/viewSlice';
import { ColumnDefinition, SortDirection, TableSort } from './SortedTableHeader';
import TableCardsSorting from '@rio-cloud/rio-uikit/lib/es/TableCardsSorting';
import { useIntl } from 'react-intl';

type CardsSortingProps<TableColumnEnum extends string> = {
    columnDefinition: ColumnDefinition<TableColumnEnum>[];
    sorting: TableSort<TableColumnEnum>;
    setSorting: (sort: TableSort<TableColumnEnum>) => void;
};

export const AppTableCardsSorting = <TableColumnEnum extends string>({
    columnDefinition,
    sorting,
    setSorting,
}: CardsSortingProps<TableColumnEnum>) => {
    const intl = useIntl();
    const viewType = useAppSelector(getTableViewType);

    if (viewType === TableViewToggles.VIEW_TYPE_TABLE) {
        return null;
    } else {
        const cardSortingSelectOptions = columnDefinition
            .filter((definition) => definition.sortable)
            .map((definition) => {
                const label =
                    definition.labelId !== undefined ? intl.formatMessage({ id: definition.labelId }) : undefined;

                return {
                    id: definition.column,
                    label,
                    selected: definition.column === sorting.column,
                    disabled: false,
                };
            });

        return (
            <TableCardsSorting
                selectOptions={cardSortingSelectOptions}
                sortName={sorting.column}
                sortOrder={sorting.dir}
                onSortChange={(column: TableColumnEnum, sortDirection: SortDirection) =>
                    setSorting({ column, dir: sortDirection })
                }
            />
        );
    }
};
