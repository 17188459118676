import { Address, Vehicle, VehicleConditionType, VehicleId } from '../sharedComponents/common.types';
import { Interval } from 'date-fns';

export type TransportOrderConfirmation = {
    readonly transportOrderIds: string[];
    readonly pickUpAt?: Date;
    readonly deliverAt?: Date;
    readonly changedAt: Date;
    readonly meansOfTransportId?: string;
    readonly meansOfTransportAssetId?: string;
    readonly trailerId?: string;
    readonly vehicleIds: VehicleId[];
    readonly unloadingAddressId?: OrganizationIdentifier;
};

export type TransportCapacityOrderConfirmation = {
    readonly pickUpAt?: Date;
    readonly deliverAt?: Date;
    readonly changedAt: Date;
    readonly meansOfTransportId?: string;
    readonly trailerId?: string;
};

export type ExecutionStatusChanged = {
    readonly transportOrderIds: string[];
    readonly changedAt: Date;
    readonly statusAt: Date;
    readonly status: ExecutionStatus;
    readonly vehicleIds: VehicleId[];
};

export type Contact = {
    readonly name: string;
    readonly email?: string;
    readonly phone?: string;
};

export type AddressAndContact = {
    readonly identifier: OrganizationIdentifier;
    readonly address?: Address;
    readonly contact?: Contact;
};

export type TransportCapacityOrderAddressAndContact = {
    readonly identifier: OrganizationIdentifier;
    readonly address: Address;
    readonly contact?: Contact;
};

export enum TransportAssignmentStatus {
    CANCELLATION = 'cancellation',
    COMPLETE_CONFIRMED = 'complete_confirmed',
    INCOMPLETE_CONFIRMED = 'incomplete_confirmed',
    UNCONFIRMED = 'unconfirmed',
    LOADED = 'loaded',
    UNLOADED = 'unloaded',
}

/**
 * It represents the status that can be set by the freight forwarder during the transport assignment execution.
 * Being a subset of the {Status} that contains all possible statuses for a transport assignment.
 */
export enum ExecutionStatus {
    LOADED = 'loaded',
    UNLOADED = 'unloaded',
}

export enum ExecutionEventType {
    AUTOMATIC = 'automatic',
    NON_AUTOMATIC = 'non-automatic',
}

export enum ModeOfTransport {
    ROAD_TRANSPORT = 'road-transport',
}

export enum MeansOfTransportType {
    TRUCK = 'truck',
}

export type ReportedIncident = {
    readonly type: VehicleConditionType;
    readonly isEmptyRun: boolean;
    readonly isActive: boolean;
    readonly changedAt: Date;
    readonly description?: string;
};

export type MeansOfTransport = {
    readonly id?: string;
    readonly assetId?: string;
    readonly trailerId?: string;
    readonly type: MeansOfTransportType;
};

export enum OrganizationIdentifierType {
    BUYER = 'buyer',
}

export type OrganizationIdentifier = {
    readonly id: string;
    readonly type: OrganizationIdentifierType;
};

export type Loading = {
    readonly addressAndContact: AddressAndContact;
    readonly requestedPickUpDate: Interval;
    readonly confirmedPickUpDate?: Date;
    readonly timeZone: string;
};

export type Unloading = {
    readonly addressAndContact?: AddressAndContact;
    readonly requestedDeliveryDate: Date;
    readonly confirmedDeliveryDate?: Date;
    readonly timeZone: string;
};

export type TransportCapacityOrderUnloading = {
    readonly addressAndContact: TransportCapacityOrderAddressAndContact;
    readonly requestedDeliveryDate?: Date;
    readonly confirmedDeliveryDate?: Date;
    readonly timeZone: string;
};

export type SelectableUnloadingAddress = {
    readonly address: Address;
    readonly identifier: OrganizationIdentifier;
};

export type TransportAssignmentInterface = {
    readonly loading: Loading;
    readonly status: TransportAssignmentStatus;
    readonly meansOfTransport?: MeansOfTransport;
    readonly modeOfTransport?: ModeOfTransport;
    readonly hasUnacknowledgedExternalChanges: boolean;
    readonly isAutomaticallyTracked: boolean;
    readonly isArchived: boolean;
    readonly isFinalized: boolean;
    readonly receivedAt: Date;
};

export enum TransportAssignmentType {
    TRANSPORT_ORDER = 'transport-order',
    TRANSPORT_ORDER_BUNDLE = 'transport-order-bundle',
    TRANSPORT_CAPACITY_ORDER = 'transport-capacity-order',
}

export interface TransportOrder extends TransportAssignmentInterface {
    readonly id: string;
    readonly externalOrderId: string;
    readonly vehicle: Vehicle;
    readonly executionEvents: ExecutionEvent[];
    readonly unloading: Unloading;
    readonly type: TransportAssignmentType.TRANSPORT_ORDER;
    readonly serviceKey?: string;
    readonly generalInformation?: string;
    readonly hasSelectableUnloadingAddress: boolean;
    readonly incident?: ReportedIncident;
}

export type ExecutionEvent = {
    readonly eventStatus: ExecutionStatus;
    readonly eventAt: Date;
    readonly eventType?: ExecutionEventType;
};

export type OriginatedTransportOrder = {
    readonly id: string;
    readonly externalOrderId: string;
    readonly vehicle: Vehicle;
    readonly isCancelled: boolean;
    readonly isFinalized: boolean;
    readonly isLoaded: boolean;
    readonly executionEvents: ExecutionEvent[];
    readonly serviceKey?: string;
    readonly generalInformation?: string;
    readonly incident?: ReportedIncident;
};

export type TransportOrderBundleDeliveries = {
    readonly originatedTransportOrders: OriginatedTransportOrder[];
    readonly unloading: Unloading;
};

export interface TransportOrderBundle extends TransportAssignmentInterface {
    readonly id: string;
    readonly modelGroups: string[];
    readonly numberOfVehicles: number;
    readonly deliveries: TransportOrderBundleDeliveries[];
    readonly type: TransportAssignmentType.TRANSPORT_ORDER_BUNDLE;
}

export interface TransportCapacityOrder extends TransportAssignmentInterface {
    readonly id: string;
    readonly type: TransportAssignmentType.TRANSPORT_CAPACITY_ORDER;
    readonly bundleId: string;
    readonly unloading: TransportCapacityOrderUnloading;
    readonly requestedTransportCapacity: number;
    readonly generalInformation?: string;
}

export type TransportAssignment = TransportOrder | TransportOrderBundle | TransportCapacityOrder;

export type TransportAssignmentPage = {
    readonly items: TransportAssignment[];
    readonly hasMore: boolean;
    readonly statusCount: StatusCount;
};

export type StatusCount = {
    readonly unconfirmed: number;
    readonly confirmed: number;
    readonly cancellation: number;
    readonly loaded: number;
    readonly unloaded: number;
};
