import { ExecutionStatus, TransportAssignment } from '../../transportAssignment.types';
import React, { useMemo } from 'react';
import { DeliveryDetailsRow, PickUpDetailsRow } from './TransportDetailRow';
import { ExecutionStatusControl } from './ExecutionStatusControl';
import {
    getIsConfirmed,
    isBundle,
    isTransportOrder,
    mapToVehiclesInExecution,
    VehicleInExecution,
} from '../../TransportAssignmentUtils';
import { NoValue } from '../../../sharedComponents/NoValue';

type TransportAssignmentTransportDetailsProps = {
    readonly transportAssignment: TransportAssignment;
};

export const TransportAssignmentTransportDetails = ({
    transportAssignment,
}: TransportAssignmentTransportDetailsProps) => {
    const isConfirmed = getIsConfirmed(transportAssignment);

    const allVehicles = useMemo<VehicleInExecution[]>(
        () => mapToVehiclesInExecution(transportAssignment),
        [transportAssignment],
    );

    const deliveryDetails = isBundle(transportAssignment) ? (
        <>
            {transportAssignment.deliveries.map((delivery, index) => {
                const vehiclesWithStatus: VehicleInExecution[] = delivery.originatedTransportOrders.map(
                    (originatedTransportOrder) => ({
                        ...originatedTransportOrder.vehicle,
                        executionEvents: originatedTransportOrder.executionEvents,
                        isCancelled: originatedTransportOrder.isCancelled,
                        originatedTransportOrderId: originatedTransportOrder.id,
                        isFinalized: originatedTransportOrder.isFinalized,
                        incident: originatedTransportOrder.incident,
                    }),
                );

                return (
                    <DeliveryDetailsRow
                        key={index}
                        unloading={delivery.unloading}
                        footer={
                            <ExecutionStatusControl
                                targetStatus={ExecutionStatus.UNLOADED}
                                executionPointTimezone={delivery.unloading.timeZone}
                                vehicles={vehiclesWithStatus}
                                isTransportAssignmentConfirmed={isConfirmed}
                                transportAssignmentReceivedAt={transportAssignment.receivedAt}
                            />
                        }
                    />
                );
            })}
        </>
    ) : isTransportOrder(transportAssignment) ? (
        <DeliveryDetailsRow
            unloading={transportAssignment.unloading}
            footer={
                <ExecutionStatusControl
                    targetStatus={ExecutionStatus.UNLOADED}
                    executionPointTimezone={transportAssignment.unloading.timeZone}
                    vehicles={allVehicles}
                    isTransportAssignmentConfirmed={isConfirmed}
                    transportAssignmentReceivedAt={transportAssignment.receivedAt}
                />
            }
        />
    ) : (
        <NoValue />
    );

    return (
        <>
            <div className="margin-bottom-10">
                <PickUpDetailsRow
                    loading={transportAssignment.loading}
                    footer={
                        <ExecutionStatusControl
                            targetStatus={ExecutionStatus.LOADED}
                            executionPointTimezone={transportAssignment.loading.timeZone}
                            vehicles={allVehicles}
                            isTransportAssignmentConfirmed={isConfirmed}
                            transportAssignmentReceivedAt={transportAssignment.receivedAt}
                        />
                    }
                />
            </div>
            <div className="text-center text-size-20 margin-bottom-10">
                <span className="rioglyph rioglyph-arrow-down text-size-200pct text-color-gray" aria-hidden="true" />
            </div>
            {deliveryDetails}
        </>
    );
};
