import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { reportErrorToSentry } from '../../../configuration/setup/sentry';
import { FormattedMessage } from 'react-intl';
import { SerializedError } from '@reduxjs/toolkit';

export type Problem = {
    title: string;
    status: number;
    detail: string;
};

const isFetchBaseQueryError = (error: unknown | undefined): error is FetchBaseQueryError =>
    (error as FetchBaseQueryError)?.status !== undefined;

const isProblem = (error: unknown): error is Problem =>
    (error as Problem).title !== undefined &&
    (error as Problem).status !== undefined &&
    (error as Problem).detail !== undefined;

enum Errors {
    PARSING_ERROR = 'PARSING_ERROR',
    FETCH_ERROR = 'FETCH_ERROR',
}

const getErrorMessageForFetchBaseQuery = (error: FetchBaseQueryError) => {
    switch (error.status) {
        case Errors.PARSING_ERROR:
        case Errors.FETCH_ERROR:
        case 500: {
            return 'outboundPortal.notification.technicalError';
        }
        case 401:
        case 403:
            return 'outboundPortal.notification.unauthorizedError';
        default: {
            if (error.data !== undefined && error.data !== null && isProblem(error.data)) {
                if (error.data.detail.startsWith('Pick up confirmation failed')) {
                    return 'outboundPortal.notification.confirmTransportAssignment.unconfirmableState';
                }
                if (error.data.detail.startsWith('An unloading address must')) {
                    return 'outboundPortal.notification.confirmUnloading.notProvidedAddress';
                }
            }
            return 'outboundPortal.notification.technicalError';
        }
    }
};

const reportRequestErrorToSentry = (error: FetchBaseQueryError) => {
    if (error.status === 500 || error.status === Errors.PARSING_ERROR || error.status === Errors.FETCH_ERROR) {
        reportErrorToSentry(error);
    }
};

export const getErrorMessage = (error: FetchBaseQueryError | undefined | SerializedError) => {
    if (isFetchBaseQueryError(error)) {
        reportRequestErrorToSentry(error);
        return getErrorMessageForFetchBaseQuery(error);
    } else {
        return 'intl-msg:common-message.error.generic.retryOrSupport';
    }
};

export const handleQueryError = (error: unknown) => {
    if (isFetchBaseQueryError(error)) {
        showErrorNotification(getErrorMessageForFetchBaseQuery(error));
        reportRequestErrorToSentry(error);
    } else {
        reportErrorToSentry(error);
    }
};

export const showErrorNotification = (message: string) => {
    return Notification.error(
        <span>
            <FormattedMessage id={message} />
            <span className="notification-close">
                <span className="rioglyph rioglyph-remove" />
            </span>
        </span>,
        '',
        999999,
        () => {},
    );
};
