import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompoundManagementView, CompoundVehicle } from '../compoundManagement/compoundManagement.types';
import type { RootState } from '../../../configuration/setup/store';
import { VehicleType } from '../sharedComponents/common.types';
import { SortDirection, TableSort } from '../sharedComponents/table/SortedTableHeader';
import { VehicleInventoryTableColumn } from '../compoundManagement/inventory/table/VehicleInventoryTableColumn';
import { DepartedVehiclesTableColumn } from '../compoundManagement/departed/table/DepartedVehiclesTableColumn';

const NO_SELECTED_COMPOUND_VEHICLE = undefined;

type Sorting = {
    inventory: TableSort<VehicleInventoryTableColumn>;
    departed: TableSort<DepartedVehiclesTableColumn>;
};

export type CompoundManagementModalFilter = {
    locationIdentifierFilter: string | undefined;
    vehicleTypeFilter: VehicleType | undefined;
    manufacturerFilter: string | undefined;
    arrivalDateStartAtFilter: Date | undefined;
    arrivalDateEndAtFilter: Date | undefined;
    departureDateStartAtFilter: Date | undefined;
    departureDateEndAtFilter: Date | undefined;
};

export type CompoundManagementState = {
    activeCompoundManagementView: CompoundManagementView;
    selectedVehicle: CompoundVehicle | undefined;
    sorting: Sorting;
    compoundManagementModalFilter: CompoundManagementModalFilter;
    vinSearchFilter: string;
};

export const initialCompoundManagementState: CompoundManagementState = {
    activeCompoundManagementView: CompoundManagementView.INVENTORY,
    selectedVehicle: NO_SELECTED_COMPOUND_VEHICLE,
    sorting: {
        inventory: {
            dir: SortDirection.DESCENDING,
            column: VehicleInventoryTableColumn.ARRIVAL_DATE,
        },
        departed: {
            dir: SortDirection.DESCENDING,
            column: DepartedVehiclesTableColumn.DEPARTURE_DATE,
        },
    },
    compoundManagementModalFilter: {
        locationIdentifierFilter: undefined,
        vehicleTypeFilter: undefined,
        manufacturerFilter: undefined,
        arrivalDateStartAtFilter: undefined,
        arrivalDateEndAtFilter: undefined,
        departureDateStartAtFilter: undefined,
        departureDateEndAtFilter: undefined,
    },
    vinSearchFilter: '',
};

export const compoundManagementSlice = createSlice({
    name: 'compoundManagement',
    initialState: initialCompoundManagementState,
    reducers: {
        switchView: (state: CompoundManagementState, action: PayloadAction<CompoundManagementView>) => {
            const sameView = state.activeCompoundManagementView === action.payload;

            state.activeCompoundManagementView = action.payload;
            if (!sameView) {
                state.selectedVehicle = NO_SELECTED_COMPOUND_VEHICLE;
            }
        },
        setInventorySorting: (
            state: CompoundManagementState,
            action: PayloadAction<TableSort<VehicleInventoryTableColumn>>,
        ) => {
            state.sorting.inventory = action.payload;
        },
        setDepartedSorting: (
            state: CompoundManagementState,
            action: PayloadAction<TableSort<DepartedVehiclesTableColumn>>,
        ) => {
            state.sorting.departed = action.payload;
        },
        setCompoundManagementModalFilter: (
            state: CompoundManagementState,
            action: PayloadAction<CompoundManagementModalFilter>,
        ) => {
            state.compoundManagementModalFilter = action.payload;
        },
        setVinSearchFilter: (state: CompoundManagementState, action: PayloadAction<string>) => {
            state.vinSearchFilter = action.payload;
        },
        selectCompoundVehicle: (state: CompoundManagementState, action: PayloadAction<CompoundVehicle>) => {
            state.selectedVehicle = action.payload;
        },
        deselectCompoundVehicle: (state: CompoundManagementState) => {
            state.selectedVehicle = NO_SELECTED_COMPOUND_VEHICLE;
        },
        setInitialState: () => initialCompoundManagementState,
    },
});

const getCompoundManagementState = (state: RootState) => state.portal.compoundManagement;

export const getActiveCompoundManagementView = (state: RootState) =>
    getCompoundManagementState(state).activeCompoundManagementView;

export const getCompoundManagementModalFilter = (state: RootState) =>
    getCompoundManagementState(state).compoundManagementModalFilter;

export const getVinSearchFilter = (state: RootState) => getCompoundManagementState(state).vinSearchFilter;

export const getSelectedCompoundVehicle = (state: RootState) => getCompoundManagementState(state).selectedVehicle;

export const getVehicleInventorySorting = (state: RootState) => getCompoundManagementState(state).sorting.inventory;
export const getDepartedVehiclesSorting = (state: RootState) => getCompoundManagementState(state).sorting.departed;
