import { FunctionComponent } from 'react';
import { LabelledInfoCell } from '../../sharedComponents/sidebar/LabelledInfoCell';
import { formatDateInTimeZone } from '../../sharedComponents/dateHelper';
import classNames from 'classnames';
import { NoValue } from '../../sharedComponents/NoValue';
import { Vehicle } from '../compoundManagement.types';
import { AddressDetails } from '../../sharedComponents/AddressDetails';

type DateTimeInfoProps = {
    date: Date;
};

const DateInfo = ({ date }: DateTimeInfoProps) => (
    <span>
        <div>{formatDateInTimeZone(date, 'UTC')}</div>
    </span>
);

type OtherDetailsProps = {
    vehicle: Vehicle;
};

export const OtherDetails: FunctionComponent<OtherDetailsProps> = ({ vehicle }) => {
    const rowClasses = classNames('display-flex', 'gap-10', 'margin-top-20', 'margin-left-25', 'align-items-start');

    return (
        <div className={rowClasses}>
            <LabelledInfoCell labelMessageId="outboundPortal.incomingCompoundTransportOrder.sidebar.manufacturedAt">
                {vehicle.manufacturedAt !== undefined ? <DateInfo date={vehicle.manufacturedAt} /> : <NoValue />}
            </LabelledInfoCell>
            <LabelledInfoCell labelMessageId="outboundPortal.incomingCompoundTransportOrder.sidebar.finalDestination">
                {vehicle.finalDestination?.address !== undefined ? (
                    <AddressDetails address={vehicle.finalDestination.address} />
                ) : (
                    <NoValue />
                )}
            </LabelledInfoCell>
        </div>
    );
};
