import TableToolbar from '@rio-cloud/rio-uikit/lib/es/TableToolbar';
import TableViewToggles from '@rio-cloud/rio-uikit/lib/es/TableViewToggles';
import { CategoriesSelection, TableCategory } from './CategoriesSelection';
import { TransportAssignmentStatusFilter } from './TransportAssignmentStatusFilter';
import { TransportOrderToolbarSearch } from './TransportOrderToolbarSearch';
import { StatusCount } from '../../transportAssignment.types';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/hooks';
import { getSelectedCategory } from '../../../reducers/transportAssignmentsSlice';
import { getTableViewType, viewSlice } from '../../../reducers/viewSlice';
import { ExcelDownload } from './ExcelDownload';
import { TransportAssignmentFilterModal } from './TransportAssignmentFilterModal';
import { TABLE_VIEW_BUTTONS_TEST_ID } from '../../../sharedComponents/commonConfig';

type TransportAssignmentTableToolbarProps = {
    readonly assignmentsPerStatus: StatusCount | undefined;
};

export const TransportAssignmentsTableToolbar = (props: TransportAssignmentTableToolbarProps) => {
    const activeCategory = useAppSelector(getSelectedCategory);
    const viewType = useAppSelector(getTableViewType);
    const dispatch = useAppDispatch();

    const handleViewTypeChange = (newViewType: TableViewToggles) =>
        dispatch(viewSlice.actions.setTableViewType(newViewType));

    return (
        <TableToolbar>
            <div className="table-toolbar-container">
                <div className="table-toolbar-group-left">
                    <div className="table-toolbar-column">
                        <div className="btn-toolbar table-btn-toolbar">
                            <CategoriesSelection assignmentsPerStatus={props.assignmentsPerStatus} />
                        </div>
                    </div>
                </div>
                <div className="table-toolbar-group-right">
                    {activeCategory === TableCategory.INBOX && (
                        <div className="table-toolbar-column table-toolbar-column-spacer min-width-300">
                            <TransportAssignmentStatusFilter />
                        </div>
                    )}
                    <div className="table-toolbar-column">
                        <TransportAssignmentFilterModal />
                    </div>
                    <div className="table-toolbar-column">
                        <TransportOrderToolbarSearch />
                    </div>
                    <div className="table-toolbar-column">
                        <ExcelDownload />
                    </div>
                    <div className="table-toolbar-column">
                        <TableViewToggles
                            data-testid={TABLE_VIEW_BUTTONS_TEST_ID}
                            initialViewType={viewType}
                            onViewTypeChange={handleViewTypeChange}
                        />
                    </div>
                </div>
            </div>
        </TableToolbar>
    );
};
