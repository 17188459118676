import { ServiceOrderStatus } from './ServiceOrders.types';
import { FormattedMessage } from 'react-intl';
import { ServiceType } from './serviceType.types';
import { AccessoryType, CollectedAccessories } from './accessory.types';
import React from 'react';

import { ActionButtonConfig } from '../sharedComponents/common.types';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';

export const getServiceNameByType = (serviceType: ServiceType): JSX.Element => {
    return <FormattedMessage id={`outboundPortal.serviceOrder.serviceName.${serviceType}`} />;
};

export const getObjectKeyForAccessory = (accessory: AccessoryType): keyof CollectedAccessories => {
    switch (accessory) {
        case AccessoryType.KEY:
            return 'key';
        case AccessoryType.MANUAL:
            return 'manual';
        case AccessoryType.LICENSE_PLATE:
            return 'licensePlate';
        case AccessoryType.SERVICE_SCHEDULE:
            return 'serviceSchedule';
        case AccessoryType.SUMMER_WHEELS:
            return 'summerWheels';
        case AccessoryType.WINTER_WHEELS:
            return 'winterWheels';
        case AccessoryType.VEHICLE_REGISTRATION_DOCUMENT:
            return 'vehicleRegistrationDocument';
    }
};

export const serviceOrderArchiveButtonConfig: ActionButtonConfig = {
    labelId: 'outboundPortal.serviceOrder.button.archive',
    style: Button.DANGER,
    className: 'btn-outline',
    icon: 'rioglyph rioglyph-folder-closed text-size-400pct',
    expandSidebar: false,
    isSpinnerInverse: true,
};

export const serviceOrderCompleteButtonConfig: ActionButtonConfig = {
    labelId: 'outboundPortal.serviceOrder.button.complete',
    style: Button.PRIMARY,
    icon: 'rioglyph rioglyph-ok text-size-400pct',
    expandSidebar: true,
    isSpinnerInverse: false,
};

export const serviceOrderUpdateButtonConfig: ActionButtonConfig = {
    labelId: 'outboundPortal.serviceOrder.button.update',
    style: Button.DEFAULT,
    icon: 'rioglyph rioglyph-refresh text-size-400pct',
    expandSidebar: true,
    isSpinnerInverse: true,
};

export const getServiceOrderTableActionButtonConfig = (
    status: ServiceOrderStatus,
    isFinalized: boolean,
): ActionButtonConfig => {
    if (isFinalized) {
        return serviceOrderArchiveButtonConfig;
    }
    switch (status) {
        case ServiceOrderStatus.OPEN:
            return serviceOrderCompleteButtonConfig;
        case ServiceOrderStatus.COMPLETED:
            return serviceOrderUpdateButtonConfig;
        case ServiceOrderStatus.CANCELLED:
            return serviceOrderArchiveButtonConfig;
    }
};
