import { DependencyList, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';
import { getIsFetchingTriggeredByUser, viewSlice } from '../reducers/viewSlice';

export type UserTriggeredFetchingProps = {
    isFetching: boolean;
    depsWhichTriggerUserFetching: DependencyList;
};

export const useSetUserTriggeredFetching = ({
    isFetching,
    depsWhichTriggerUserFetching,
}: UserTriggeredFetchingProps) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (isFetching) {
            dispatch(viewSlice.actions.setIsFetchingTriggeredByUser(true));
        }
        // not exhaustive on purpose only should set isFetchingNew if user caused reload
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, ...depsWhichTriggerUserFetching]);

    useEffect(() => {
        if (!isFetching) {
            dispatch(viewSlice.actions.setIsFetchingTriggeredByUser(false));
        }
    }, [dispatch, isFetching]);
};

export const useGetUserTriggeredFetching = () => {
    return useAppSelector(getIsFetchingTriggeredByUser);
};
