import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

type VehicleDetailsHeaderProps = {
    messageId: string;
    isCancelled: boolean;
    model: { readonly name: string };
};
export const VehicleDetailsHeader: FunctionComponent<VehicleDetailsHeaderProps> = ({
    messageId,
    isCancelled,
    model,
}) => {
    return (
        <>
            <label className={classNames({ 'text-decoration-line-through': isCancelled })}>
                <FormattedMessage id={messageId} />
            </label>
            <div
                className={classNames('flex-1-1 text-normal ellipsis-3', {
                    'text-decoration-line-through': isCancelled,
                })}
            >
                <span className="rioglyph rioglyph-car text-size-18 margin-right-5" aria-hidden="true" />
                &nbsp;
                {model.name.replace(/\s+/g, ' ')}
            </div>
        </>
    );
};
