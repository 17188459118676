import { NoValue } from '../../../sharedComponents/NoValue';
import { DateTime } from '../../../sharedComponents/table/BaseTableComponents';
import { DepartedVehicle } from '../../compoundManagement.types';
import {
    DispatchTypeDataContent,
    FreightForwarderDataContent,
    FuelTypeDataContent,
    ModelNameDataContent,
} from '../../table/CompoundDataTableContent';
import { DepartedVehiclesTableColumn } from './DepartedVehiclesTableColumn';

type TableDataContentProps = {
    column: DepartedVehiclesTableColumn;
    vehicle: DepartedVehicle;
};

export const DepartedVehicleTableDataContent = ({ vehicle, column }: TableDataContentProps) => {
    const spanOrNoValue = (text: string | undefined) => (Boolean(text) ? <span>{text}</span> : <NoValue />);

    switch (column) {
        case DepartedVehiclesTableColumn.VIN:
            return <div className="ellipsis-1">{vehicle.vehicle.vin}</div>;
        case DepartedVehiclesTableColumn.LOCATION_ID:
            return <span>{vehicle.compoundLocation.identifier}</span>;
        case DepartedVehiclesTableColumn.LOCATION_CITY:
            return <span>{vehicle.compoundLocation.city}</span>;
        case DepartedVehiclesTableColumn.MANUFACTURER:
            return spanOrNoValue(vehicle.vehicle.model.manufacturer);
        case DepartedVehiclesTableColumn.MODEL:
            return <ModelNameDataContent vehicle={vehicle.vehicle} />;
        case DepartedVehiclesTableColumn.DISPATCH_TYPE:
            return <DispatchTypeDataContent vehicle={vehicle.vehicle} />;
        case DepartedVehiclesTableColumn.FUEL_TYPE:
            return <FuelTypeDataContent vehicle={vehicle.vehicle} />;
        case DepartedVehiclesTableColumn.MODEL_VERSION:
            return spanOrNoValue(vehicle.vehicle.model.version);
        case DepartedVehiclesTableColumn.ARRIVAL_DATE:
            return (
                <DateTime dateTime={vehicle.arrival.arrivalCompletedAt} timeZone={vehicle.compoundLocation.timeZone} />
            );
        case DepartedVehiclesTableColumn.ARRIVAL_FREIGHT_FORWARDER:
            return <FreightForwarderDataContent freightForwarder={vehicle.arrival.freightForwarder} />;
        case DepartedVehiclesTableColumn.DEPARTURE_DATE:
            return (
                <DateTime
                    dateTime={vehicle.departure.departureCompletedAt}
                    timeZone={vehicle.compoundLocation.timeZone}
                />
            );
        case DepartedVehiclesTableColumn.DEPARTURE_FREIGHT_FORWARDER:
            return <FreightForwarderDataContent freightForwarder={vehicle.departure.freightForwarder} />;
        default:
            throw new Error('exhaustive switch exception - table content');
    }
};
