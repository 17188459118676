import { CompoundTransportOrder, CompoundTransportOrderStatus } from '../compoundTransportOrder.types';
import {
    TableIncomingCompoundTransportOrderColumn,
    incomingCompoundTransportOrderDefinition,
} from './IncomingCompoundTransportOrderColumns';
import { NoValue } from '../../sharedComponents/NoValue';
import classNames from 'classnames';
import { formatDateInTimeZone, formatTimeInTimeZone } from '../../sharedComponents/dateHelper';
import { StatusLabel } from '../../sharedComponents/StatusLabel';
import {
    getSelectedIncomingCompoundTransportOrder,
    incomingCompoundTransportOrdersSlice,
} from '../../reducers/incomingCompoundTransportOrdersSlice';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';

const TableDataRow = (props: {
    column: TableIncomingCompoundTransportOrderColumn;
    compoundTransportOrder: CompoundTransportOrder;
}) => {
    const { column, compoundTransportOrder } = props;

    switch (column) {
        case TableIncomingCompoundTransportOrderColumn.VIN:
            return (
                <td key={column} className="ellipsis-1">
                    {compoundTransportOrder.vehicle.id.vin ?? <NoValue />}
                </td>
            );

        case TableIncomingCompoundTransportOrderColumn.EXPECTED_DELIVERY_AT:
            return (
                <td key={column}>
                    <>
                        <span>
                            <div>
                                {formatDateInTimeZone(
                                    compoundTransportOrder.expectedDeliveryAt,
                                    compoundTransportOrder.timezone,
                                )}
                            </div>
                            <div>
                                {formatTimeInTimeZone(
                                    compoundTransportOrder.expectedDeliveryAt,
                                    compoundTransportOrder.timezone,
                                )}
                            </div>
                        </span>
                    </>
                </td>
            );

        case TableIncomingCompoundTransportOrderColumn.STATUS:
            return (
                <td key={column} className="no-line-wrap">
                    <StatusLabel status={compoundTransportOrder.status} />
                </td>
            );

        case TableIncomingCompoundTransportOrderColumn.MANUFACTURER:
            return <td key={column}>{compoundTransportOrder.vehicle.model.manufacturer ?? <NoValue />}</td>;

        case TableIncomingCompoundTransportOrderColumn.MODEL:
            return (
                <td key={column}>
                    <span>
                        <span className="rioglyph rioglyph-car text-size-18 padding-right-5" />
                        {compoundTransportOrder.vehicle.model.name}
                    </span>
                </td>
            );

        case TableIncomingCompoundTransportOrderColumn.CARRIER:
            return <td key={column}>{compoundTransportOrder.carrier ?? <NoValue />}</td>;

        case TableIncomingCompoundTransportOrderColumn.MEANS_OF_TRANSPORT:
            return <td key={column}>{compoundTransportOrder.meansOfTransportId ?? <NoValue />}</td>;
    }
};

export const IncomingCompoundTransportOrderTableRow = (props: { compoundTransportOrder: CompoundTransportOrder }) => {
    const compoundTransportOrder = props.compoundTransportOrder;
    const isCancelled = compoundTransportOrder.status === CompoundTransportOrderStatus.CANCELLED;

    const selectedIncomingCompoundTransportOrder = useAppSelector(getSelectedIncomingCompoundTransportOrder);
    const dispatch = useAppDispatch();
    const isSelected = selectedIncomingCompoundTransportOrder?.id === compoundTransportOrder.id;

    const handleRowClick = () => {
        if (isSelected) {
            dispatch(incomingCompoundTransportOrdersSlice.actions.deselectIncomingCompoundTransportOrder());
        } else {
            dispatch(
                incomingCompoundTransportOrdersSlice.actions.selectIncomingCompoundTransportOrder(
                    compoundTransportOrder,
                ),
            );
        }
    };

    return (
        <tr
            key={compoundTransportOrder.id}
            className={classNames('cursor-pointer', { danger: isCancelled, active: isSelected })}
            onClick={handleRowClick}
        >
            {incomingCompoundTransportOrderDefinition.map((def) => {
                return (
                    <TableDataRow
                        key={def.column}
                        column={def.column}
                        compoundTransportOrder={compoundTransportOrder}
                    />
                );
            })}
        </tr>
    );
};
