import Sidebar from '@rio-cloud/rio-uikit/lib/es/Sidebar';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import {
    getSelectedIncomingCompoundTransportOrder,
    incomingCompoundTransportOrdersSlice,
} from '../../reducers/incomingCompoundTransportOrdersSlice';
import { CompoundTransportOrder } from '../compoundTransportOrder.types';
import { ShipmentDetails } from './ShipmentDetails';
import { TransportDetails } from './TransportDetails';
import { VehicleIdAndStatus } from './VehicleIdAndStatus';

export const IncomingCompoundTransportOrderSidebar = () => {
    const compoundTransportOrder = useAppSelector(getSelectedIncomingCompoundTransportOrder);

    return <IncomingCompoundTransportOrderSidebarInternal compoundTransportOrder={compoundTransportOrder} />;
};

type IncomingCompoundTransportOrderSidebarInternalProps = {
    compoundTransportOrder: CompoundTransportOrder | undefined;
};
const IncomingCompoundTransportOrderSidebarInternal = ({
    compoundTransportOrder,
}: IncomingCompoundTransportOrderSidebarInternalProps) => {
    const dispatch = useAppDispatch();

    const sidebarProperties = {
        showHeaderBorder: true,
        resizable: true,
        position: 'right',
        minWidth: 400,
        maxWidth: 550,
        width: 550,
        fly: true,
    };

    if (compoundTransportOrder === undefined) {
        return <Sidebar {...sidebarProperties} closed />;
    }

    const closeSidebar = () => {
        dispatch(incomingCompoundTransportOrdersSlice.actions.deselectIncomingCompoundTransportOrder());
    };

    const title = (
        <FormattedMessage id="outboundPortal.incomingCompoundTransportOrder.sidebar.header.titleIncomingCompoundTransportOrder" />
    );

    const footerButton = (
        <div className="display-flex gap-10 flex-row-reverse">
            <button className="btn btn-default" type="button" onClick={closeSidebar}>
                <FormattedMessage id="outboundOrderBook.common.button.close" />
            </button>
        </div>
    );

    return (
        <Sidebar
            {...sidebarProperties}
            title={title}
            titleClassName="padding-left-10"
            closed={false}
            onClose={closeSidebar}
            footer={footerButton}
            footerClassName="margin-right-10"
        >
            <VehicleIdAndStatus compoundTransportOrder={compoundTransportOrder} />
            <TransportDetails compoundTransportOrder={compoundTransportOrder} />
            <ShipmentDetails compoundTransportOrder={compoundTransportOrder} />
        </Sidebar>
    );
};
