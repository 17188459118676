import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { FormattedMessage } from 'react-intl';
import { CompoundManagementView } from '../compoundManagement.types';

type CompoundManagementViewSelectionProps = {
    activeView: CompoundManagementView;
    setActiveView: (newView: CompoundManagementView) => void;
};

export const CompoundManagementViewSelection = ({
    activeView,
    setActiveView,
}: CompoundManagementViewSelectionProps) => (
    <div className="btn-group" data-testid="toolbar-button-group">
        {[
            { view: CompoundManagementView.INCOMING_VEHICLES, icon: <span className="rioglyph rioglyph-delivery" /> },
            { view: CompoundManagementView.INVENTORY, icon: <span className="rioglyph rioglyph-car" /> },
            { view: CompoundManagementView.DEPARTED, icon: <span className="rioglyph rioglyph-folder-closed" /> },
        ].map(({ view, icon }) => (
            <Button key={view} active={activeView === view} onClick={() => setActiveView(view)}>
                {icon}
                <FormattedMessage id={view} />
            </Button>
        ))}
    </div>
);
