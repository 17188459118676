import { FormattedMessage } from 'react-intl';
import { CompoundTransportOrderStatus } from '../compoundTransportOrders/compoundTransportOrder.types';
import { TransportAssignmentStatus } from '../transportAssignment/transportAssignment.types';
import classNames from 'classnames';
import { ServiceOrderStatus } from '../serviceOrders/ServiceOrders.types';
import { ActivationStatus } from '../activation/activation.types';

export type StatusLabelProps = {
    status: CompoundTransportOrderStatus | TransportAssignmentStatus | ServiceOrderStatus | ActivationStatus;
    className?: string;
};

const statusLabelColoring = (
    status: CompoundTransportOrderStatus | TransportAssignmentStatus | ServiceOrderStatus | ActivationStatus,
): string => {
    const defaultStatusLabelStyle = 'label label-condensed';
    switch (status) {
        case CompoundTransportOrderStatus.CANCELLED:
        case TransportAssignmentStatus.CANCELLATION:
        case ServiceOrderStatus.CANCELLED:
            return `${defaultStatusLabelStyle} label-danger`;
        case CompoundTransportOrderStatus.CONFIRMED:
        case TransportAssignmentStatus.INCOMPLETE_CONFIRMED:
        case TransportAssignmentStatus.COMPLETE_CONFIRMED:
            return `${defaultStatusLabelStyle} label-primary`;
        case CompoundTransportOrderStatus.UNCONFIRMED:
        case TransportAssignmentStatus.UNCONFIRMED:
        case ServiceOrderStatus.OPEN:
            return `${defaultStatusLabelStyle} label-muted label-filled`;
        case ActivationStatus.PENDING:
        case CompoundTransportOrderStatus.LOADED:
        case TransportAssignmentStatus.LOADED:
            return `${defaultStatusLabelStyle} label-primary label-filled`;
        case CompoundTransportOrderStatus.UNLOADED:
        case TransportAssignmentStatus.UNLOADED:
        case ActivationStatus.ACTIVE:
        case ServiceOrderStatus.COMPLETED:
            return `${defaultStatusLabelStyle} label-success label-filled`;
    }
};

export const statusMessageId = (
    status: CompoundTransportOrderStatus | TransportAssignmentStatus | ServiceOrderStatus | ActivationStatus,
): string => {
    switch (status) {
        case CompoundTransportOrderStatus.CANCELLED:
        case TransportAssignmentStatus.CANCELLATION:
        case ServiceOrderStatus.CANCELLED:
            return 'outboundOrderBook.common.status.cancelled';
        case CompoundTransportOrderStatus.CONFIRMED:
        case TransportAssignmentStatus.INCOMPLETE_CONFIRMED:
        case TransportAssignmentStatus.COMPLETE_CONFIRMED:
            return 'outboundOrderBook.common.status.confirmed';
        case CompoundTransportOrderStatus.UNCONFIRMED:
        case TransportAssignmentStatus.UNCONFIRMED:
            return 'outboundOrderBook.common.status.unconfirmed';
        case CompoundTransportOrderStatus.LOADED:
        case TransportAssignmentStatus.LOADED:
            return 'outboundOrderBook.common.status.loaded';
        case CompoundTransportOrderStatus.UNLOADED:
        case TransportAssignmentStatus.UNLOADED:
            return 'outboundOrderBook.common.status.unloaded';
        case ServiceOrderStatus.OPEN:
            return 'outboundPortal.serviceOrders.status.open';
        case ServiceOrderStatus.COMPLETED:
            return 'outboundPortal.serviceOrders.status.completed';
        case ActivationStatus.PENDING:
            return 'outboundOrderBook.serviceProviders.pending';
        case ActivationStatus.ACTIVE:
            return 'outboundOrderBook.serviceProviders.active';
    }
};

export const StatusLabel = ({ status, className }: StatusLabelProps) => {
    return (
        <span className={classNames(className, statusLabelColoring(status))}>
            <FormattedMessage id={statusMessageId(status)} />
        </span>
    );
};
