import { ValidationResult } from './confirmationForm.types';
import { ReactNode } from 'react';
import classNames from 'classnames';
import { InputSign } from '../../../sharedComponents/InputSign';

type ValidatedFormGroupProps = {
    validation: ValidationResult;
    additionalClassNames?: string;
    children: ReactNode;
};

export const ValidatedFormGroup = (props: ValidatedFormGroupProps) => {
    const showError = Boolean(props.validation.error);
    const showWarning = Boolean(props.validation.warning) && !showError;

    const messageId = props.validation.error ?? props.validation.warning;
    const glyph = showError ? 'rioglyph-error-sign' : undefined;

    return (
        <div
            className={classNames(
                'form-group',
                { 'has-feedback has-error margin-bottom-50': showError },
                { 'has-feedback has-warning margin-bottom-25': showWarning },
                props.additionalClassNames,
            )}
        >
            {props.children}
            {messageId && <InputSign messageId={messageId} glyph={glyph} />}
        </div>
    );
};
