import { ToolbarSearch } from '../../../sharedComponents/filter/ToolbarSearch';
import { getServiceOrderSearch, serviceOrdersSlice } from '../../../reducers/serviceOrdersSlice';

export const ServiceOrderToolbarSearch = () => {
    return (
        <ToolbarSearch
            getSearchTerm={getServiceOrderSearch}
            setSearchTerm={serviceOrdersSlice.actions.setSearchTerm}
            tooltipMessageId="outboundPortal.serviceOrders.table.toolbar.search.placeholder.tooltip"
            placeholderMessageId="outboundPortal.serviceOrders.table.toolbar.search.placeholder"
        />
    );
};
