import { config } from '../../../config';
import { prepareHeaders } from './utils';
import { decodeResponse, validateResponse } from './responseUtil';
import { AccountDetails } from '../transportAssignment/userDetails.types';
import { AccountsDetailsCodec } from './types/accountsDetailsApi.types';
import { mapAccountsDetails } from './mappers/accountsDetailsMapper';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const accountsDetailsApi = 'AccountsDetailsApi';
export const ACCOUNTS_DETAILS_PATH = 'accounts';

export const accountsApi = createApi({
    reducerPath: 'accountsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.ACCOUNTS_DETAILS_SERVICE,
        prepareHeaders,
    }),
    tagTypes: [accountsDetailsApi],
    endpoints: (builder) => ({
        getAccountsDetails: builder.query<AccountDetails, { accountId: string }>({
            query: (arg) => ({
                url: `${ACCOUNTS_DETAILS_PATH}/${arg.accountId}`,
                validateStatus: validateResponse(AccountsDetailsCodec),
            }),
            transformResponse: decodeResponse(AccountsDetailsCodec, mapAccountsDetails),
        }),
    }),
});

export const { useGetAccountsDetailsQuery } = accountsApi;
