import React from 'react';
import { FormattedMessage } from 'react-intl';

export const ThankYou = (): React.ReactElement => {
    return (
        <div>
            <div className="display-flex flex-column align-items-center justify-content-center min-height-500">
                <div className="text-size-h2 text-bold">
                    <FormattedMessage id="outboundPortal.activation.thankYou.thankYou" />
                </div>
                <div className="text-size-h3">
                    <FormattedMessage id="outboundPortal.activation.thankYou.checkData" />
                </div>
                <p className="text-size-h4">
                    <span className="icon rioglyph rioglyph-ok-sign text-color-primary text-size-400pct" />
                </p>
                <div className="text-size-16 text-center">
                    <p>
                        <FormattedMessage id="outboundPortal.activation.thankYou.emailSoon" />
                    </p>
                    <div>
                        <FormattedMessage id="outboundPortal.activation.thankYou.informedByVw.line1" />
                    </div>
                    <div className="text-bold">
                        <FormattedMessage id="outboundPortal.activation.thankYou.informedByVw.line2" />
                    </div>
                    <div>
                        <FormattedMessage id="outboundPortal.activation.thankYou.informedByVw.line3" />
                    </div>
                </div>
            </div>
        </div>
    );
};
