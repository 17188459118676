import {
    ApiServiceOrderCompletion,
    ApiVehicleCompletion,
} from '../../types/serviceOrder/completeServiceOrderApi.types';
import { mapToApiServiceType } from './serviceTypeMapper';
import { ServiceOrderCompletion, VehicleCompletion } from '../../../serviceOrders/serviceOrderCompletion.types';
import { mapToApiVehicleId } from '../vehicleMappers';
import { mapToApiCollectedAccessories } from './accessoriesMapper';

export const mapToApiVehicleCompletion = (vehicleCompletion: VehicleCompletion): ApiVehicleCompletion => ({
    id: mapToApiVehicleId(vehicleCompletion.id),
    collected_accessories:
        vehicleCompletion.collectedAccessories !== undefined
            ? mapToApiCollectedAccessories(vehicleCompletion.collectedAccessories)
            : undefined,
});
export const mapToApiServiceOrderCompletion = (
    serviceOrderCompletion: ServiceOrderCompletion,
): ApiServiceOrderCompletion => ({
    type: mapToApiServiceType(serviceOrderCompletion.type),
    vehicle: mapToApiVehicleCompletion(serviceOrderCompletion.vehicle),
    completed_at: serviceOrderCompletion.completedAt.toISOString(),
    changed_at: serviceOrderCompletion.changedAt.toISOString(),
    general_information: serviceOrderCompletion.generalInformation,
});
