import { FormattedMessage } from 'react-intl';
import { NoValue } from '../../sharedComponents/NoValue';
import { FreightForwarder, Vehicle } from '../compoundManagement.types';

export const ModelNameDataContent = ({ vehicle }: { vehicle: Vehicle }) => {
    return (
        <span>
            <span className="rioglyph rioglyph-car text-size-18 padding-right-5" />
            {vehicle.model.name}
        </span>
    );
};

export const DispatchTypeDataContent = ({ vehicle }: { vehicle: Vehicle }) => {
    return vehicle.vehicleType === undefined ? (
        <NoValue />
    ) : (
        <div className="ellipsis-1">
            <FormattedMessage id={`outboundOrderBook.common.vehicleType.${vehicle.vehicleType}`} />
        </div>
    );
};

export const FuelTypeDataContent = ({ vehicle }: { vehicle: Vehicle }) => {
    const fuelType = vehicle.model.fuelType;
    if (fuelType === undefined) {
        return <NoValue />;
    } else {
        const messageId = `outboundOrderBook.common.fuelType.${fuelType}`;
        return (
            <div className="ellipsis-1">
                <FormattedMessage id={messageId} />
            </div>
        );
    }
};

export const FreightForwarderDataContent = ({
    freightForwarder,
}: {
    freightForwarder: FreightForwarder | undefined;
}) => {
    return freightForwarder === undefined ? (
        <NoValue />
    ) : (
        <div className="ellipsis-1">
            {freightForwarder.name} {freightForwarder.additionalName}
        </div>
    );
};
