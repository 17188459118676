import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../configuration/setup/store';
import { addDays, endOfToday, startOfToday } from 'date-fns';
import { TableIncomingCompoundTransportOrderColumn } from '../compoundTransportOrders/table/IncomingCompoundTransportOrderColumns';
import { SortDirection, TableSort } from '../sharedComponents/table/SortedTableHeader';
import {
    CompoundTransportOrder,
    CompoundTransportOrderStatus,
} from '../compoundTransportOrders/compoundTransportOrder.types';

export const NO_SELECTED_INCOMING_COMPOUND_TRANSPORT_ORDER = undefined;

export type DateFilter = { from: Date; to: Date };
export type ModalFilter = { carrierNameFilter: string | undefined; locationIdFilter: string | undefined };

export type IncomingCompoundTransportOrdersFilterState = {
    dateFilter: DateFilter;
    statusFilter: CompoundTransportOrderStatus[];
    modalFilter: ModalFilter;
    searchTerm: string;
};

export type IncomingCompoundTransportOrdersState = {
    selectedIncomingCompoundTransportOrder: CompoundTransportOrder | undefined;
    filters: IncomingCompoundTransportOrdersFilterState;
    sorting: TableSort<TableIncomingCompoundTransportOrderColumn>;
};

export const initialFilterState: IncomingCompoundTransportOrdersFilterState = {
    dateFilter: { from: startOfToday(), to: addDays(endOfToday(), 6) },
    statusFilter: [],
    modalFilter: { carrierNameFilter: undefined, locationIdFilter: undefined },
    searchTerm: '',
};

export const initialSortingState: TableSort<TableIncomingCompoundTransportOrderColumn> = {
    dir: SortDirection.ASCENDING,
    column: TableIncomingCompoundTransportOrderColumn.EXPECTED_DELIVERY_AT,
};

export const initialIncomingCompoundTransportOrdersState: IncomingCompoundTransportOrdersState = {
    selectedIncomingCompoundTransportOrder: NO_SELECTED_INCOMING_COMPOUND_TRANSPORT_ORDER,
    filters: initialFilterState,
    sorting: initialSortingState,
};

export const incomingCompoundTransportOrdersSlice = createSlice({
    name: 'incomingCompoundTransportOrder',
    initialState: initialIncomingCompoundTransportOrdersState,
    reducers: {
        selectIncomingCompoundTransportOrder: (
            state: IncomingCompoundTransportOrdersState,
            action: PayloadAction<CompoundTransportOrder>,
        ) => {
            state.selectedIncomingCompoundTransportOrder = action.payload;
        },
        deselectIncomingCompoundTransportOrder: (state: IncomingCompoundTransportOrdersState) => {
            state.selectedIncomingCompoundTransportOrder = NO_SELECTED_INCOMING_COMPOUND_TRANSPORT_ORDER;
        },
        setDateFilterFrom: (state: IncomingCompoundTransportOrdersState, action: PayloadAction<Date>) => {
            state.filters.dateFilter.from = action.payload;
        },
        setDateFilterTo: (state: IncomingCompoundTransportOrdersState, action: PayloadAction<Date>) => {
            state.filters.dateFilter.to = action.payload;
        },
        setStatusFilter: (
            state: IncomingCompoundTransportOrdersState,
            action: PayloadAction<CompoundTransportOrderStatus[]>,
        ) => {
            state.filters.statusFilter = action.payload;
        },
        setSorting: (
            state: IncomingCompoundTransportOrdersState,
            action: PayloadAction<TableSort<TableIncomingCompoundTransportOrderColumn>>,
        ) => {
            state.sorting = action.payload;
        },
        setModalFilters: (state: IncomingCompoundTransportOrdersState, action: PayloadAction<ModalFilter>) => {
            state.filters.modalFilter = action.payload;
        },
        setSearchTerm: (state: IncomingCompoundTransportOrdersState, action: PayloadAction<string>) => {
            state.filters.searchTerm = action.payload;
        },
        setInitialState: () => initialIncomingCompoundTransportOrdersState,
    },
});

export const getSelectedIncomingCompoundTransportOrder = (state: RootState) =>
    state.portal.incomingCompoundTransportOrders.selectedIncomingCompoundTransportOrder;

export const getFilters = (state: RootState) => state.portal.incomingCompoundTransportOrders.filters;
export const getIncomingCompoundTransportOrdersDateFilter = (state: RootState) =>
    state.portal.incomingCompoundTransportOrders.filters.dateFilter;
export const getIncomingCompoundTransportOrdersSort = (state: RootState) =>
    state.portal.incomingCompoundTransportOrders.sorting;
export const getIncomingCompoundTransportOrdersStatusFilter = (state: RootState) =>
    state.portal.incomingCompoundTransportOrders.filters.statusFilter;
export const getIncomingCompoundTransportOrdersModalFilter = (state: RootState) =>
    state.portal.incomingCompoundTransportOrders.filters.modalFilter;
export const getIncomingCompoundTransportOrderSearch = (state: RootState) => getFilters(state).searchTerm;
