import { ApiAccessoryType, ApiCollectedAccessories } from '../../types/serviceOrder/accessoriesApi.types';
import { AccessoryType, CollectedAccessories } from '../../../serviceOrders/accessory.types';

export const mapToAccessoryType = (apiAccessoryType: ApiAccessoryType): AccessoryType => {
    switch (apiAccessoryType) {
        case ApiAccessoryType.KEY:
            return AccessoryType.KEY;
        case ApiAccessoryType.LICENSE_PLATE:
            return AccessoryType.LICENSE_PLATE;
        case ApiAccessoryType.MANUAL:
            return AccessoryType.MANUAL;
        case ApiAccessoryType.SERVICE_SCHEDULE:
            return AccessoryType.SERVICE_SCHEDULE;
        case ApiAccessoryType.SUMMER_WHEELS:
            return AccessoryType.SUMMER_WHEELS;
        case ApiAccessoryType.VEHICLE_REGISTRATION_DOCUMENT:
            return AccessoryType.VEHICLE_REGISTRATION_DOCUMENT;
        case ApiAccessoryType.WINTER_WHEELS:
            return AccessoryType.WINTER_WHEELS;
    }
};
export const mapToApiAccessoryType = (accessoryType: AccessoryType): ApiAccessoryType => {
    switch (accessoryType) {
        case AccessoryType.KEY:
            return ApiAccessoryType.KEY;
        case AccessoryType.LICENSE_PLATE:
            return ApiAccessoryType.LICENSE_PLATE;
        case AccessoryType.MANUAL:
            return ApiAccessoryType.MANUAL;
        case AccessoryType.SERVICE_SCHEDULE:
            return ApiAccessoryType.SERVICE_SCHEDULE;
        case AccessoryType.SUMMER_WHEELS:
            return ApiAccessoryType.SUMMER_WHEELS;
        case AccessoryType.VEHICLE_REGISTRATION_DOCUMENT:
            return ApiAccessoryType.VEHICLE_REGISTRATION_DOCUMENT;
        case AccessoryType.WINTER_WHEELS:
            return ApiAccessoryType.WINTER_WHEELS;
    }
};
export const mapToCollectedAccessories = (apiCollectedAccessories: ApiCollectedAccessories): CollectedAccessories => {
    return {
        key: apiCollectedAccessories.key,
        licensePlate: apiCollectedAccessories.license_plate,
        manual: apiCollectedAccessories.manual,
        serviceSchedule: apiCollectedAccessories.service_schedule,
        summerWheels: apiCollectedAccessories.summer_wheels,
        vehicleRegistrationDocument: apiCollectedAccessories.vehicle_registration_document,
        winterWheels: apiCollectedAccessories.winter_wheels,
    };
};
export const mapToApiCollectedAccessories = (collectedAccessories: CollectedAccessories): ApiCollectedAccessories => {
    return {
        key: collectedAccessories.key,
        license_plate: collectedAccessories.licensePlate,
        manual: collectedAccessories.manual,
        service_schedule: collectedAccessories.serviceSchedule,
        summer_wheels: collectedAccessories.summerWheels,
        vehicle_registration_document: collectedAccessories.vehicleRegistrationDocument,
        winter_wheels: collectedAccessories.winterWheels,
    };
};
