import { FunctionComponent } from 'react';
import { Vehicle } from '../compoundManagement.types';
import { VehicleDetailsHeader } from '../../sharedComponents/sidebar/VehicleDetailsHeader';
import { VehicleDetailsTable } from './VehicleDetailsTable';

type VehicleDetailsProps = {
    vehicle: Vehicle;
    isCancelled: boolean;
};
export const VehicleDetails: FunctionComponent<VehicleDetailsProps> = ({ vehicle, isCancelled }) => {
    return (
        <div className="panel panel-default margin-bottom-0 margin-20">
            <div className="panel-heading margin-x-15">
                <VehicleDetailsHeader
                    messageId="outboundOrderBook.common.heading.model"
                    model={vehicle.model}
                    isCancelled={isCancelled}
                />
            </div>
            <div className="panel-body">
                <VehicleDetailsTable vehicle={vehicle} />
            </div>
        </div>
    );
};
