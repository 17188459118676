import camelCase from 'lodash/camelCase';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { FuelType, ModelCategory, VehicleDimensions, VehicleType } from '../common.types';
import { NoValue } from '../NoValue';

export const FormattedDimensions: FunctionComponent<{ dimensions: VehicleDimensions | undefined }> = ({
    dimensions,
}) =>
    dimensions?.length !== undefined || dimensions?.width !== undefined || dimensions?.height !== undefined ? (
        <>
            {dimensions?.length ?? <NoValue />} mm x {dimensions?.width ?? <NoValue />} mm x{' '}
            {dimensions?.height ?? <NoValue />} mm
        </>
    ) : (
        <NoValue />
    );

export const FormattedWeight: FunctionComponent<{ weight: number | undefined }> = ({ weight }) =>
    weight !== undefined ? `${weight} kg` : <NoValue />;

export const FormattedFuelType: FunctionComponent<{ fuelType: FuelType | undefined }> = ({ fuelType }) =>
    fuelType !== undefined ? <FormattedMessage id={`outboundOrderBook.common.fuelType.${fuelType}`} /> : <NoValue />;

export const FormattedVehicleType: FunctionComponent<{
    vehicleType: VehicleType | undefined;
}> = ({ vehicleType }) =>
    vehicleType !== undefined ? (
        <FormattedMessage id={`outboundOrderBook.common.vehicleType.${vehicleType}`} />
    ) : (
        <NoValue />
    );

export const FormattedModelCategory: FunctionComponent<{ category: ModelCategory | undefined }> = ({ category }) => {
    if (category !== undefined) {
        const id = `outboundOrderBook.common.sidebar.details.vehicle.model.category.${camelCase(category)}`;
        return <FormattedMessage id={id} />;
    } else {
        return <NoValue />;
    }
};
