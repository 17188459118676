import { useId } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FeatureToggles, useFeatureToggle } from '../../../../../../configuration/featureToggle/toggleHook';
import { MeansOfTransport } from '../../../transportAssignment.types';
import { ValidationResult } from '../confirmationForm.types';
import { AutoSuggestAssetInput } from './AutoSuggestAssetInput';
import { SimpleAssetInput } from './SimpleAssetInput';
import { ValidatedFormGroup } from '../ValidatedFormGroup';

type TransportMeansIdFormFieldProps = {
    meansOfTransport: MeansOfTransport | undefined;
    setMeansOfTransportId: (value: { id?: string; assetId?: string }) => void;
    validation: ValidationResult;
    isFormDisabled: boolean;
};

export const MeansOfTransportFormInput = ({
    meansOfTransport,
    setMeansOfTransportId,
    validation,
    isFormDisabled,
}: TransportMeansIdFormFieldProps) => {
    const inputId = useId();
    const intl = useIntl();

    const inputProps = {
        id: inputId,
        placeholder: intl.formatMessage({
            id: 'outboundOrderBook.transportAssignments.sidebar.confirmationForm.meansOfTransportId.placeholder',
        }),
        value: meansOfTransport?.id ?? '',
        disabled: isFormDisabled,
    };

    return (
        <div className="width-50pct padding-right-5">
            <label htmlFor={inputId} className="display-flex flex-column flex-wrap justify-content-between height-35">
                <span>
                    <FormattedMessage id="outboundOrderBook.transportAssignments.sidebar.confirmationForm.meansOfTransportId" />
                </span>
                <span className="text-italic">
                    <FormattedMessage id="outboundPortal.transportAssignments.sidebar.assignmentInformation.transportMeansId.info" />
                </span>
            </label>
            <ValidatedFormGroup validation={validation}>
                {useFeatureToggle(FeatureToggles.TRACKING, false).value ? (
                    <AutoSuggestAssetInput
                        meansOfTransport={meansOfTransport}
                        setMeansOfTransport={setMeansOfTransportId}
                        inputProps={inputProps}
                    />
                ) : (
                    <SimpleAssetInput
                        setAssetName={(assetName) => setMeansOfTransportId({ id: assetName, assetId: undefined })}
                        {...inputProps}
                    />
                )}
            </ValidatedFormGroup>
        </div>
    );
};
