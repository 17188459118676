import isNil from 'lodash/isNil';
import { Address } from './common.types';
import { NoValue } from './NoValue';

type AddressDetailsProps = {
    readonly address: Partial<Address>;
};

export const AddressDetails = ({ address }: AddressDetailsProps) => {
    const isEmptyAddress = Object.values(address).every(isNil);
    return isEmptyAddress ? (
        <NoValue />
    ) : (
        <div>
            <div className="text-medium margin-bottom-10">{address.name}</div>
            <div>{address.street}</div>
            <div>
                <span className="margin-right-5">{address.postalCode}</span>
                <span>{address.city}</span>
            </div>
            <div>{address.countryCode}</div>
        </div>
    );
};
