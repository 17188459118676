import { Address, Vehicle } from '../sharedComponents/common.types';

export enum CompoundTransportOrderStatus {
    CANCELLED = 'cancelled',
    CONFIRMED = 'confirmed',
    UNCONFIRMED = 'unconfirmed',
    LOADED = 'loaded',
    UNLOADED = 'unloaded',
}

export type CompoundTransportOrderSummary = {
    readonly numberOfVehicles: number;
    readonly numberOfTransports: number;
};

export type LocationIdentifier = {
    readonly id: string;
};

export type Location = {
    readonly identifier: LocationIdentifier;
    readonly address: Address | undefined;
};

export type CompoundTransportOrder = {
    readonly id: string;
    readonly vehicle: Vehicle;
    readonly expectedDeliveryAt: Date;
    readonly deliveryLocation: Location;
    readonly timezone: string;
    readonly status: CompoundTransportOrderStatus;
    readonly carrier: string | undefined;
    readonly meansOfTransportId: string | undefined;
};

export type CompoundTransportOrderPage = {
    readonly items: CompoundTransportOrder[];
    readonly summary: CompoundTransportOrderSummary;
    readonly hasMore: boolean;
};
