import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isUserSessionExpired } from '../../../../configuration/login/loginSlice';
import { useAppSelector } from '../../../../configuration/setup/hooks';
import { useGetInventoryVehiclesQuery } from '../../api/compoundManagementApi';
import { handleQueryError } from '../../notifications/ErrorNotification';
import {
    getCompoundManagementModalFilter,
    getVehicleInventorySorting,
    getVinSearchFilter,
} from '../../reducers/compoundManagementSlice';
import { LoadMoreButton } from '../../sharedComponents/table/LoadMoreButton';
import { useSetUserTriggeredFetching } from '../../sharedComponents/userTriggeredFetchingHook';
import { VehicleInventoryTable } from './table/VehicleInventoryTable';
import { CompoundManagementToolbar } from '../toolbar/CompoundManagementToolbar';

export const DEFAULT_COMPOUND_INVENTORY_LIMIT_SIZE = 100;
const POLLING_INTERVAL = 60000;

export const VehicleInventory: FunctionComponent = () => {
    const isSessionExpired = useAppSelector(isUserSessionExpired);
    const filters = useAppSelector(getCompoundManagementModalFilter);
    const vinSearch = useAppSelector(getVinSearchFilter);
    const sorting = useAppSelector(getVehicleInventorySorting);

    const [limit, setLimit] = useState(DEFAULT_COMPOUND_INVENTORY_LIMIT_SIZE);

    const {
        data: inventoryPage,
        isLoading,
        isFetching,
        isError,
        error,
    } = useGetInventoryVehiclesQuery(
        {
            locationId: filters.locationIdentifierFilter,
            vehicleType: filters.vehicleTypeFilter,
            manufacturer: filters.manufacturerFilter,
            arrivalDateStartAt: filters.arrivalDateStartAtFilter,
            arrivalDateEndAt: filters.arrivalDateEndAtFilter,
            vin: vinSearch.length === 0 ? undefined : vinSearch,
            limit,
            sortBy: sorting,
        },
        {
            pollingInterval: isSessionExpired ? undefined : POLLING_INTERVAL,
        },
    );

    useSetUserTriggeredFetching({
        isFetching,
        depsWhichTriggerUserFetching: [],
    });

    const inventoryVehicles = inventoryPage?.items;

    if (isError) {
        console.error(error);
        handleQueryError(error);
    }

    if (isLoading) {
        return (
            <div data-testid="spinner">
                <Spinner />
            </div>
        );
    }

    if (inventoryVehicles === undefined) {
        return (
            <ErrorState
                headline={<FormattedMessage id="intl-msg:common-message.error.generic.headline" />}
                message={<FormattedMessage id="intl-msg:common-message.error.generic.message" />}
            />
        );
    }

    return (
        <>
            <CompoundManagementToolbar />
            <VehicleInventoryTable vehicles={inventoryVehicles} />
            {inventoryVehicles.length > 0 && (
                <LoadMoreButton
                    onLoadMore={() => setLimit(limit + DEFAULT_COMPOUND_INVENTORY_LIMIT_SIZE)}
                    hasMoreToLoad={inventoryPage?.hasMore ?? false}
                />
            )}
        </>
    );
};
