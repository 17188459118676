import DatePicker from '@rio-cloud/rio-uikit/lib/es/DatePicker';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import moment, { Moment } from 'moment';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/hooks';
import {
    getIncomingCompoundTransportOrdersDateFilter,
    incomingCompoundTransportOrdersSlice,
} from '../../../reducers/incomingCompoundTransportOrdersSlice';
import { LabelWithTimezone } from '../../../sharedComponents/LabelWithTimezone';

export const DATE_QUICK_FILTER = 'incoming-compound-transport-orders-date-filter-quick';
const DATE_PICKER_INPUT_FIELD_ID_TO = 'incoming-compound-transport-orders-date-filter-to';
const DATE_PICKER_INPUT_FIELD_ID_FROM = 'incoming-compound-transport-orders-date-filter-from';

export const DateFilter = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const datesFromStore = useAppSelector(getIncomingCompoundTransportOrdersDateFilter);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [minDate, setMinDate] = useState<Moment | string>(moment(datesFromStore.from));
    const [maxDate, setMaxDate] = useState<Moment | string>(moment(datesFromStore.to));
    const [isQuickFilterOpen, setIsQuickFilterOpen] = useState(false);

    const handleMinDateChange = (selectedDate: Moment | string) => {
        if (moment.isMoment(selectedDate) && selectedDate.isValid()) {
            dispatch(
                incomingCompoundTransportOrdersSlice.actions.setDateFilterFrom(selectedDate.startOf('day').toDate()),
            );
        }
        setMinDate(selectedDate);
    };
    const handleMaxDateChange = (selectedDate: Moment | string) => {
        if (moment.isMoment(selectedDate) && selectedDate.isValid()) {
            dispatch(incomingCompoundTransportOrdersSlice.actions.setDateFilterTo(selectedDate.endOf('day').toDate()));
        }
        setMaxDate(selectedDate);
    };

    const isValidMaxDate = (current: Moment) => current.isSameOrAfter(minDate, 'day');
    const isValidMinDate = (current: Moment) => current.isSameOrBefore(maxDate, 'day');

    const startOfToday = moment().startOf('day');
    const quickFilterItems = (
        [
            ['outboundOrderBook.compoundTransportOrder.table.toolbar.filter.date.optionOne', moment().endOf('day')],
            [
                'outboundOrderBook.compoundTransportOrder.table.toolbar.filter.date.optionTwo',
                moment().endOf('day').add(6, 'days'),
            ],
            [
                'outboundOrderBook.compoundTransportOrder.table.toolbar.filter.date.optionThree',
                moment().endOf('day').add(13, 'days'),
            ],
        ] as [string, Moment][]
    ).map(([messageId, selectedMaxDate], idx) => (
        <li key={idx} role="presentation">
            <a
                onClick={() => {
                    handleMinDateChange(startOfToday);
                    handleMaxDateChange(selectedMaxDate);
                    setIsQuickFilterOpen(!isQuickFilterOpen);
                }}
                role="menuitem"
            >
                <FormattedMessage id={messageId} />
            </a>
        </li>
    ));

    return (
        <>
            <div className="table-toolbar-column">
                <ButtonDropdown
                    id={DATE_QUICK_FILTER}
                    data-testid={DATE_QUICK_FILTER}
                    title={<span className="rioglyph rioglyph-settings" aria-hidden="true" />}
                    items={quickFilterItems}
                    iconOnly
                    customDropdown={quickFilterItems}
                    open={isQuickFilterOpen}
                    onOpen={() => setIsQuickFilterOpen(true)}
                    onClose={() => setIsQuickFilterOpen(false)}
                />
            </div>
            <div className="table-toolbar-column">
                <LabelWithTimezone
                    className="table-toolbar-label"
                    htmlFor={DATE_PICKER_INPUT_FIELD_ID_FROM}
                    messageId="outboundOrderBook.compoundTransportOrder.table.toolbar.filter.date.from"
                    timeZoneData={{ timeZone, date: datesFromStore.from }}
                />
                <div className="min-width-150">
                    <DatePicker
                        inputProps={{
                            id: DATE_PICKER_INPUT_FIELD_ID_FROM,
                            placeholder: intl.formatMessage({
                                id: 'outboundPortal.common.chooseDatePlaceholder',
                            }),
                        }}
                        value={minDate}
                        onChange={handleMinDateChange}
                        timeFormat={false}
                        locale={intl.locale}
                        isValidDate={isValidMinDate}
                    />
                </div>
            </div>
            <div className="table-toolbar-column margin-left-5 table-toolbar-column-spacer">
                <LabelWithTimezone
                    className="table-toolbar-label"
                    htmlFor={DATE_PICKER_INPUT_FIELD_ID_TO}
                    messageId="outboundOrderBook.compoundTransportOrder.table.toolbar.filter.date.to"
                    timeZoneData={{ timeZone, date: datesFromStore.to }}
                />
                <div className="min-width-150">
                    <DatePicker
                        inputProps={{
                            id: DATE_PICKER_INPUT_FIELD_ID_TO,
                            placeholder: intl.formatMessage({
                                id: 'outboundPortal.common.chooseDatePlaceholder',
                            }),
                        }}
                        value={maxDate}
                        onChange={handleMaxDateChange}
                        timeFormat={false}
                        locale={intl.locale}
                        isValidDate={isValidMaxDate}
                    />
                </div>
            </div>
        </>
    );
};
