import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ExpanderList from '@rio-cloud/rio-uikit/lib/es/ExpanderList';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { getLocale } from '../../../configuration/lang/langSlice';

const headerClassName = 'text-size-18 text-color-highlight';
const bodyClassName = 'text-size-18';

export const GeneralFaq = (): React.ReactElement => {
    const supportFormUrlParams = new URLSearchParams();
    supportFormUrlParams.set('opener', window.location.origin);
    supportFormUrlParams.set('locale', useAppSelector(getLocale) ?? '');
    const supportFormUrl = `https://contact-form.rio.cloud/contact?${supportFormUrlParams.toString()}`;

    const userLanguage = useAppSelector(getLocale) ?? 'en';
    const languageForLinks = userLanguage.substring(0, 2);

    const faqListItems = [
        {
            header: <FormattedMessage id="welcomePage.faq.general.item0.question" />,
            headerClassName,
            body: (
                <FormattedMessage
                    id="welcomePage.faq.general.item0.answer"
                    values={{
                        _newLine: <br />,
                    }}
                />
            ),
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.general.item1.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.general.item1.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.general.item2.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.general.item2.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.general.item3.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.general.item3.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.general.item4.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.general.item4.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.general.item5.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.general.item5.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.general.item6.question" />,
            headerClassName,
            body: (
                <FormattedMessage
                    id="welcomePage.faq.general.item6.answer"
                    values={{
                        a: (chunks) => (
                            <a
                                href="https://www.odette.org/process/finished-vehicle-logistics"
                                rel="noreferrer noopener"
                                target="_blank"
                            >
                                {chunks}
                            </a>
                        ),
                    }}
                />
            ),
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.general.item7.question" />,
            headerClassName,
            body: (
                <FormattedMessage
                    id="welcomePage.faq.general.item7.answer"
                    values={{
                        _newLine: <br />,
                    }}
                />
            ),
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.general.item8.question" />,
            headerClassName,
            body: (
                <FormattedMessage
                    id="welcomePage.faq.general.item8.answer"
                    values={{
                        a: (chunks) => (
                            <a href={supportFormUrl} rel="noreferrer noopener" target="_blank">
                                {chunks}
                            </a>
                        ),
                    }}
                />
            ),
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.general.item9.question" />,
            headerClassName,
            body: (
                <FormattedMessage
                    id="welcomePage.faq.general.item9.answer"
                    values={{
                        a: (chunks) => (
                            <a
                                href={`https://rio.cloud/${languageForLinks}/documents/outbound-order-book/privacy-policy`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {chunks}
                            </a>
                        ),
                    }}
                />
            ),
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.general.item10.question" />,
            headerClassName,
            body: (
                <FormattedMessage
                    id="welcomePage.faq.general.item10.answer"
                    values={{
                        a: (chunks) => (
                            <a href={supportFormUrl} rel="noreferrer noopener" target="_blank">
                                {chunks}
                            </a>
                        ),
                    }}
                />
            ),
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.general.item11.question" />,
            headerClassName,
            body: (
                <FormattedMessage
                    id="welcomePage.faq.general.item11.answer"
                    values={{
                        a: (chunks) => (
                            <a
                                href={`https://rio.cloud/${languageForLinks}/documents/outbound-order-book/dos/v06072023`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {chunks}
                            </a>
                        ),
                    }}
                />
            ),
            bodyClassName,
        },
    ];

    return <ExpanderList items={faqListItems} />;
};

export const InterfaceFaq = (): React.ReactElement => {
    const supportFormUrlParams = new URLSearchParams();
    supportFormUrlParams.set('opener', window.location.origin);
    supportFormUrlParams.set('locale', useAppSelector(getLocale) ?? '');
    const supportFormUrl = `https://contact-form.rio.cloud/contact?${supportFormUrlParams.toString()}`;

    const languageForApiLink = useAppSelector(getLocale)?.startsWith('de') === true ? 'de' : 'en';

    const faqListItems = [
        {
            header: <FormattedMessage id="welcomePage.faq.interface.item0.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.interface.item0.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.interface.item1.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.interface.item1.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.interface.item2.question" />,
            headerClassName,
            body: (
                <FormattedMessage
                    id="welcomePage.faq.interface.item2.answer"
                    values={{
                        a: (chunks) => (
                            <a
                                href="https://api-doc.finished-vehicles.outbound-order-book.rio.cloud/OOB-Security-Documentation.pdf"
                                rel="noreferrer noopener"
                                target="_blank"
                            >
                                {chunks}
                            </a>
                        ),
                    }}
                />
            ),
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.interface.item3.question" />,
            headerClassName,
            body: (
                <FormattedMessage
                    id="welcomePage.faq.interface.item3.answer"
                    values={{
                        _newLine: <br />,
                    }}
                />
            ),
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.interface.item4.question" />,
            headerClassName,
            body: (
                <FormattedMessage
                    id="welcomePage.faq.interface.item4.answer"
                    values={{
                        a: (chunks) => (
                            <a
                                href={`https://rio.cloud/${languageForApiLink}/apis/finished-vehicle-logistics`}
                                rel="noreferrer noopener"
                                target="_blank"
                            >
                                {chunks}
                            </a>
                        ),
                    }}
                />
            ),
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.interface.item5.question" />,
            headerClassName,
            body: (
                <FormattedMessage
                    id="welcomePage.faq.interface.item5.answer"
                    values={{
                        a: (chunks) => (
                            <a href={supportFormUrl} rel="noreferrer noopener" target="_blank">
                                {chunks}
                            </a>
                        ),
                    }}
                />
            ),
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.interface.item6.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.interface.item6.answer" />,
            bodyClassName,
        },
    ];

    return <ExpanderList items={faqListItems} />;
};

export const TransportOrderFaq = (): React.ReactElement => {
    const faqListItems = [
        {
            header: <FormattedMessage id="welcomePage.faq.transportOrders.item0.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.transportOrders.item0.answer" />,
            bodyClassName,
        },
        {
            // moved after feedback
            header: <FormattedMessage id="welcomePage.faq.transportOrders.item5.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.transportOrders.item5.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.transportOrders.item1.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.transportOrders.item1.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.transportOrders.item2.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.transportOrders.item2.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.transportOrders.item3.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.transportOrders.item3.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.transportOrders.item4.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.transportOrders.item4.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.transportOrders.item6.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.transportOrders.item6.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.transportOrders.item7.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.transportOrders.item7.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.transportOrders.item8.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.transportOrders.item8.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.transportOrders.item9.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.transportOrders.item9.answer" />,
            bodyClassName,
        },
    ];

    return <ExpanderList items={faqListItems} />;
};

export const ServiceOrderFaq = (): React.ReactElement => {
    const faqListItems = [
        {
            header: <FormattedMessage id="welcomePage.faq.serviceOrders.item0.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.serviceOrders.item0.answer" />,
            bodyClassName,
        },
    ];

    return <ExpanderList items={faqListItems} />;
};

export const IncomingCompoundTransportOrderFaq = () => {
    const faqListItems = [
        {
            header: <FormattedMessage id="welcomePage.faq.incomingCompoundTransportOrder.item0.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.incomingCompoundTransportOrder.item0.answer" />,
            bodyClassName,
        },
    ];

    return <ExpanderList items={faqListItems} />;
};

export const TransportCapacityOrderFaq = () => {
    const faqListItems = [
        {
            header: <FormattedMessage id="welcomePage.faq.transportCapacityOrder.item0.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.transportCapacityOrder.item0.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.transportCapacityOrder.item1.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.transportCapacityOrder.item1.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.transportCapacityOrder.item2.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.transportCapacityOrder.item2.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.transportCapacityOrder.item3.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.transportCapacityOrder.item3.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.transportCapacityOrder.item4.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.transportCapacityOrder.item4.answer" />,
            bodyClassName,
        },
    ];

    return <ExpanderList items={faqListItems} />;
};
