import Teaser from '@rio-cloud/rio-uikit/lib/es/Teaser';
import TeaserContainer from '@rio-cloud/rio-uikit/lib/es/TeaserContainer';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLocale } from '../../../configuration/lang/langSlice';
import { runInBackgroundCallback } from '../../../configuration/setup/backgroundActions';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { TRANSPORT_ORDER_ROUTE } from '../routes/routes';
import { Activation, ActivationStatus } from './activation.types';
import {
    GeneralFaq,
    IncomingCompoundTransportOrderFaq,
    InterfaceFaq,
    ServiceOrderFaq,
    TransportCapacityOrderFaq,
    TransportOrderFaq,
} from './Faq';
import oobTrackingBrowser from './oob_automated_tracking_browser.png';
import oobHeaderDesktop from './oob_header_desktop_right.png';
import oobServiceBrowser from './oob_serviceorders_browser.png';
import oobTransportBrowser from './oob_transportorders_browser.png';
import { RegistrationFormDialog } from './RegistrationFormDialog';
import { useGetActivationStatusesQuery } from '../api/activationApi';

export const WELCOME_PENDING_STATUS = 'WELCOME_PENDING_STATUS';
export const SCROLL_TO_FAQ = 'faq';

export const WelcomePage = () => {
    const [showActivationModal, setShowActivationModal] = useState(false);
    const { data: activations } = useGetActivationStatusesQuery();
    const navigate = useNavigate();
    const { state } = useLocation();
    const supportRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (state?.scrollTo === SCROLL_TO_FAQ && supportRef.current !== null) {
            supportRef.current.scrollIntoView();
        }
    });

    const languageForApiLink = useAppSelector(getLocale)?.startsWith('de') === true ? 'de' : 'en';

    const isActive = () =>
        activations !== undefined &&
        activations?.filter((activation: Activation) => activation.status === ActivationStatus.ACTIVE).length > 0;

    const isPending = () =>
        activations !== undefined &&
        !isActive() &&
        activations.filter((activation: Activation) => activation.status === ActivationStatus.PENDING).length > 0;

    const marginTeaser = 'margin-left-25';

    const handleOnClick = () => setShowActivationModal(true);
    const getButtonConfiguration = () => {
        if (!isActive()) {
            return {
                text: (
                    <div>
                        <span className="rioglyph rioglyph-user margin-right-10" />
                        <FormattedMessage id="outboundPortal.welcomePage.button.registerWithShipper" />
                    </div>
                ),
                bsStyle: 'primary',
                className: classNames(marginTeaser, 'margin-top-10', 'btn-lg', isPending() ? 'disabled' : ''),
                onClick: handleOnClick,
            };
        } else {
            return {
                text: <FormattedMessage id="outboundPortal.welcomePage.button.transportOrders" />,
                onClick: runInBackgroundCallback(async () => {
                    await navigate(TRANSPORT_ORDER_ROUTE);
                }, 'WelcomePage'),
                bsStyle: 'default',
                className: classNames(marginTeaser, 'margin-top-15', 'btn-lg'),
            };
        }
    };

    return (
        <div className="container fluid-default">
            <RegistrationFormDialog show={showActivationModal} setShow={setShowActivationModal} />
            <TeaserContainer>
                <Teaser
                    segmentation="50"
                    headline={
                        <div className="text-left">
                            <img
                                src="https://rio.cloud/fileadmin/Marketplace/AppIcons/OutboundOrderBook.svg"
                                alt="outbound-order-book-icon"
                                className="margin-bottom-25 margin-left-25 padding-left-5"
                            />
                            <div className={`text-size-h1 text-bold text-uppercase ${marginTeaser}`}>
                                <FormattedMessage id="outboundPortal.welcomePage.outboundOrderBook" />
                            </div>
                        </div>
                    }
                    content={
                        <div className={marginTeaser}>
                            <p className="text-size-h3">
                                <FormattedMessage id="outboundPortal.welcomePage.manageTransportServiceOrders" />
                            </p>
                            <FormattedMessage id="outboundPortal.welcomePage.summary" />
                            {isPending() && (
                                <div className="margin-bottom-10" data-testid={WELCOME_PENDING_STATUS}>
                                    <span className="rioglyph rioglyph-exclamation-sign text-color-warning margin-right-5" />
                                    <span className="text-color-warning">
                                        <FormattedMessage
                                            id="outboundPortal.welcomePage.pending"
                                            values={{
                                                supplier: (
                                                    <FormattedMessage id="outboundPortal.welcomePage.supplier.volkswagen" />
                                                ),
                                            }}
                                        />
                                    </span>
                                </div>
                            )}
                        </div>
                    }
                    verticalAlignment="center"
                    className="padding-25-xs padding-20-ls padding-0-sm"
                    image={{
                        src: oobHeaderDesktop,
                        aspectRatio: '3:2',
                        align: 'right',
                        alt: 'outbound-order-book-browser',
                    }}
                    button={getButtonConfiguration()}
                />
            </TeaserContainer>
            <TeaserContainer>
                <Teaser
                    segmentation="50"
                    className="padding-25"
                    verticalAlignment="center"
                    headline={
                        <div className="margin-25">
                            <FormattedMessage id="outboundPortal.welcomePage.transportOrders.caption" />
                        </div>
                    }
                    content={
                        <div className="margin-25">
                            <FormattedMessage id="outboundPortal.welcomePage.transportOrders.body" />
                        </div>
                    }
                    image={{
                        src: oobTransportBrowser,
                        aspectRatio: '3:2',
                        align: 'left',
                        alt: 'outbound-order-book-browser',
                    }}
                />
            </TeaserContainer>
            <TeaserContainer>
                <Teaser
                    segmentation="50"
                    className="padding-25"
                    verticalAlignment="center"
                    headline={
                        <div className="margin-25">
                            <FormattedMessage id="outboundPortal.welcomePage.serviceOrders.caption" />
                        </div>
                    }
                    content={
                        <div className="margin-25">
                            <FormattedMessage id="outboundPortal.welcomePage.serviceOrders.body" />
                        </div>
                    }
                    image={{
                        src: oobServiceBrowser,
                        aspectRatio: '3:2',
                        align: 'right',
                        alt: 'outbound-order-book-browser',
                    }}
                />
            </TeaserContainer>
            <TeaserContainer>
                <Teaser
                    segmentation="50"
                    className="padding-25"
                    verticalAlignment="center"
                    headline={
                        <div className="margin-25">
                            <div>
                                <span className="badge badge-lg badge-primary">
                                    <FormattedMessage id="outboundPortal.welcomePage.comingSoon" />
                                </span>
                            </div>
                            <FormattedMessage id="outboundPortal.welcomePage.status.caption" />
                        </div>
                    }
                    content={
                        <div className="margin-25">
                            <FormattedMessage id="outboundPortal.welcomePage.status.body" />
                        </div>
                    }
                    image={{
                        src: oobTrackingBrowser,
                        aspectRatio: '3:2',
                        align: 'left',
                        alt: 'outbound-order-book-browser',
                    }}
                />
            </TeaserContainer>
            <TeaserContainer>
                <Teaser
                    className="padding-50"
                    headline={<FormattedMessage id="outboundPortal.welcomePage.frontend.caption" />}
                    content={
                        <>
                            <FormattedMessage id="outboundPortal.welcomePage.frontend.body" />
                            <div className="margin-20" />
                            <ul>
                                <li>
                                    <FormattedMessage id="outboundPortal.welcomePage.frontend.body.item1" />
                                </li>
                                <li>
                                    <FormattedMessage id="outboundPortal.welcomePage.frontend.body.item2" />
                                </li>
                                <li>
                                    <FormattedMessage id="outboundPortal.welcomePage.frontend.body.item3" />
                                </li>
                            </ul>
                        </>
                    }
                />
                <Teaser
                    className="padding-50"
                    headline={<FormattedMessage id="outboundPortal.welcomePage.api.caption" />}
                    content={
                        <>
                            <FormattedMessage id="outboundPortal.welcomePage.api.body" />
                            <div className="margin-20" />
                            <ul>
                                <li>
                                    <FormattedMessage id="outboundPortal.welcomePage.api.body.item1" />
                                </li>
                                <li>
                                    <FormattedMessage id="outboundPortal.welcomePage.api.body.item2" />
                                </li>
                            </ul>
                            <div className="margin-20" />
                            <FormattedMessage
                                id="outboundPortal.welcomePage.api.body.end"
                                values={{
                                    _link: (
                                        <a
                                            href={`https://rio.cloud/${languageForApiLink}/apis/finished-vehicle-logistics`}
                                            rel="noreferrer noopener"
                                            target="_blank"
                                        >
                                            <FormattedMessage id="outboundPortal.welcomePage.api.body.apiPage" />
                                        </a>
                                    ),
                                }}
                            />
                        </>
                    }
                />
            </TeaserContainer>
            <div className="text-center margin-top-5pct margin-bottom-25" ref={supportRef}>
                <h1 className="headline_text visible">
                    <FormattedMessage id="welcomePage.faq" />
                </h1>
            </div>
            <FaqSection headlineId="welcomePage.general.faq">
                <GeneralFaq />
            </FaqSection>
            <FaqSection headlineId="welcomePage.interface.faq">
                <InterfaceFaq />
            </FaqSection>
            <FaqSection headlineId="welcomePage.transportOrder.faq">
                <TransportOrderFaq />
            </FaqSection>
            <FaqSection headlineId="welcomePage.serviceOrder.faq">
                <ServiceOrderFaq />
            </FaqSection>
            <FaqSection headlineId="welcomePage.incomingCompoundTransportOrder.faq">
                <IncomingCompoundTransportOrderFaq />
            </FaqSection>
            <FaqSection headlineId="welcomePage.transportCapacityOrder.faq">
                <TransportCapacityOrderFaq />
            </FaqSection>
        </div>
    );
};

const FaqSection = ({ headlineId, children }: { headlineId: string; children: React.ReactNode }) => (
    <div className="sectioncontainer">
        <section className="bg-lighter sectioncontainer">
            <div className="container-fluid fluid-default">
                <div className="sectionbox">
                    <div className="row display-flex-sm">
                        <div className="col-sm-12 display-flex-sm">
                            <div className="panel panel-default panel-body padding-25-sm width-100pct">
                                <div className="display-flex align-items-center justify-content-between margin-bottom-25">
                                    <h3 className="headline_text">
                                        <FormattedMessage id={headlineId} />
                                    </h3>
                                </div>
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
);
