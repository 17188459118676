import { FunctionComponent } from 'react';
import { DetailsTable } from '../../sharedComponents/sidebar/DetailsTable';
import { DetailsTableRow } from '../../sharedComponents/sidebar/DetailsTableRow';
import {
    FormattedDimensions,
    FormattedFuelType,
    FormattedModelCategory,
    FormattedVehicleType,
    FormattedWeight,
} from '../../sharedComponents/sidebar/FormattedVehicleData';
import { Vehicle } from '../compoundManagement.types';

type CompoundVehicleDetailsTableProps = {
    vehicle: Vehicle;
};
export const VehicleDetailsTable: FunctionComponent<CompoundVehicleDetailsTableProps> = ({ vehicle }) => {
    return (
        <DetailsTable>
            <DetailsTableRow
                messageId="outboundOrderBook.common.sidebar.details.vehicle.model.modelGroup"
                value={vehicle.model.group}
            />
            <DetailsTableRow
                messageId="outboundOrderBook.common.sidebar.details.vehicle.dimensions"
                value={<FormattedDimensions dimensions={vehicle.dimensions} />}
            />
            <DetailsTableRow
                messageId="outboundOrderBook.common.sidebar.details.vehicle.weight"
                value={<FormattedWeight weight={vehicle.weight} />}
            />
            <DetailsTableRow
                messageId="outboundOrderBook.common.sidebar.details.vehicle.colour"
                value={vehicle.colour}
            />
            <DetailsTableRow
                messageId="outboundOrderBook.common.heading.vehicleType"
                value={<FormattedVehicleType vehicleType={vehicle.vehicleType} />}
            />
            <DetailsTableRow
                messageId="outboundOrderBook.common.sidebar.details.vehicle.licensePlate"
                value={vehicle.licensePlate}
            />
            <DetailsTableRow
                messageId="outboundOrderBook.common.sidebar.details.vehicle.model.vehicleCategory"
                value={<FormattedModelCategory category={vehicle.model.category} />}
            />
            <DetailsTableRow
                messageId="outboundOrderBook.common.heading.fuelType"
                value={<FormattedFuelType fuelType={vehicle.model.fuelType} />}
            />
        </DetailsTable>
    );
};
