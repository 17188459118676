import { ServiceOrderStatus } from '../../ServiceOrders.types';
import { getServiceOrderStatusFilter, serviceOrdersSlice } from '../../../reducers/serviceOrdersSlice';
import { StatusFilter } from '../../../sharedComponents/filter/StatusFilter';

export const ServiceOrderStatusFilter = () => {
    const additionalOptionClasses = new Map([[ServiceOrderStatus.CANCELLED, 'bg-white']]);

    return (
        <StatusFilter
            getFilterSelector={getServiceOrderStatusFilter}
            setFilterAction={serviceOrdersSlice.actions.setServiceOrderStatusFilter}
            statusOptions={Object.values(ServiceOrderStatus)}
            optionClassName={additionalOptionClasses}
        />
    );
};
