import * as t from 'io-ts';
import { NonEmptyString } from 'io-ts-types';
import { fromEnum } from '../../responseUtil';

export enum ApiOrganizationIdentifierType {
    BUYER = 'buyer',
}

export const OrganizationIdentifierCodec = t.type({
    id: NonEmptyString,
    type: fromEnum<ApiOrganizationIdentifierType>('ApiOrganizationIdentifierType', ApiOrganizationIdentifierType),
});

export type ApiOrganizationIdentifier = t.TypeOf<typeof OrganizationIdentifierCodec>;
