import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { FormattedMessage } from 'react-intl';
import { ExecutionStatus } from '../transportAssignment/transportAssignment.types';
import messagesEN from '../../translations/en-GB.json';

export const showSuccessNotificationAfterStatusUpdate = (
    newStatus: ExecutionStatus.LOADED | ExecutionStatus.UNLOADED,
) => {
    switch (newStatus) {
        case ExecutionStatus.LOADED:
            showSuccessNotification('outboundPortal.notification.confirmLoading.success');
            break;
        case ExecutionStatus.UNLOADED:
            showSuccessNotification('outboundPortal.notification.confirmUnloading.success');
            break;
    }
};

type allowedMessages = keyof typeof messagesEN;

export const showSuccessNotification = (message: allowedMessages) => {
    Notification.success(<FormattedMessage id={message} />);
};
