import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import { getServiceOrderSort, serviceOrdersSlice } from '../../reducers/serviceOrdersSlice';
import { AppTableCardsSorting } from '../../sharedComponents/table/AppTableCardsSorting';
import { TableSort } from '../../sharedComponents/table/SortedTableHeader';
import { serviceOrderColumnDefinition, ServiceOrdersTableColumns } from './ServiceOrdersTableColumns';

export const ServiceOrderCardsSorting = () => {
    const dispatch = useAppDispatch();
    const sorting = useAppSelector(getServiceOrderSort);

    return (
        <AppTableCardsSorting
            columnDefinition={serviceOrderColumnDefinition}
            sorting={sorting}
            setSorting={(sort: TableSort<ServiceOrdersTableColumns>) =>
                dispatch(serviceOrdersSlice.actions.setSortingForActiveCategory({ sort }))
            }
        />
    );
};
