import { createSlice } from '@reduxjs/toolkit';
import { config, ConfigState } from '../../config';

const initialState: ConfigState = config;

const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {},
});

export const { reducer: configReducer } = configSlice;
