import { parseOptionalStringDate, parseStringDate } from '../../../sharedComponents/dateHelper';
import {
    ServiceOrder,
    ServiceOrderPage,
    ServiceOrderStatus,
    ServiceOrderVehicle,
    ServiceOrderVehicleModel,
} from '../../../serviceOrders/ServiceOrders.types';
import {
    ApiServiceOrder,
    ApiServiceOrderPage,
    ApiServiceOrderStatus,
    ApiServiceOrderVehicle,
    ApiServiceOrderVehicleModel,
} from '../../types/serviceOrder/serviceOrdersApi.type';
import { mapToTimeZone } from '../timeZoneMappers';
import { ApiAccessoryType } from '../../types/serviceOrder/accessoriesApi.types';
import { mapToServiceType } from './serviceTypeMapper';
import { mapToVehicleId } from '../vehicleMappers';
import { mapToAccessoryType, mapToCollectedAccessories } from './accessoriesMapper';

export const mapToServiceOrderStatus = (apiServiceOrderStatus: ApiServiceOrderStatus): ServiceOrderStatus => {
    switch (apiServiceOrderStatus) {
        case ApiServiceOrderStatus.OPEN:
            return ServiceOrderStatus.OPEN;
        case ApiServiceOrderStatus.COMPLETED:
            return ServiceOrderStatus.COMPLETED;
        case ApiServiceOrderStatus.CANCELLED:
            return ServiceOrderStatus.CANCELLED;
    }
};

export const mapToServiceOrderVehicleModel = (
    apiServiceOrderVehicleModel: ApiServiceOrderVehicleModel,
): ServiceOrderVehicleModel => {
    return {
        name: apiServiceOrderVehicleModel.name,
        manufacturer: apiServiceOrderVehicleModel.manufacturer,
    };
};

export const mapToServiceOrderVehicle = (apiServiceOrderVehicle: ApiServiceOrderVehicle): ServiceOrderVehicle => {
    return {
        id: mapToVehicleId(apiServiceOrderVehicle.id),
        requestedAccessories: apiServiceOrderVehicle.requested_accessories.map((accessory: ApiAccessoryType) =>
            mapToAccessoryType(accessory),
        ),
        model: apiServiceOrderVehicle.model && mapToServiceOrderVehicleModel(apiServiceOrderVehicle.model),
        collectedAccessories:
            apiServiceOrderVehicle.collected_accessories &&
            mapToCollectedAccessories(apiServiceOrderVehicle.collected_accessories),
    };
};

export const mapToServiceOrder = (apiServiceOrder: ApiServiceOrder): ServiceOrder => {
    return {
        id: apiServiceOrder.id,
        externalOrderId: apiServiceOrder.external_order_id,
        timeZone: mapToTimeZone(apiServiceOrder.time_zone),
        generalInformation: apiServiceOrder.general_information,
        completedAt: parseOptionalStringDate(apiServiceOrder.completed_at),
        receivedAt: parseStringDate(apiServiceOrder.received_at),
        vehicle: mapToServiceOrderVehicle(apiServiceOrder.vehicle),
        status: mapToServiceOrderStatus(apiServiceOrder.status),
        type: mapToServiceType(apiServiceOrder.type),
        description: apiServiceOrder.description,
        hasUnacknowledgedExternalChanges: apiServiceOrder.has_unacknowledged_external_changes,
        isFinalized: apiServiceOrder.is_finalized,
        isArchived: apiServiceOrder.is_archived,
    };
};

export const mapToServiceOrders = (apiServiceOrderPage: ApiServiceOrderPage): ServiceOrderPage => ({
    items: apiServiceOrderPage.items.map(
        (apiServiceOrder: ApiServiceOrder): ServiceOrder => mapToServiceOrder(apiServiceOrder),
    ),
    hasMore: apiServiceOrderPage.has_more,
});
