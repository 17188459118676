import { ShipmentDetailsTable } from '../../sharedComponents/sidebar/ShipmentDetailsTable';
import { VehicleDetailsHeader } from '../../sharedComponents/sidebar/VehicleDetailsHeader';
import { CompoundTransportOrder, CompoundTransportOrderStatus } from '../compoundTransportOrder.types';

type ShipmentDetailsProps = {
    compoundTransportOrder: CompoundTransportOrder;
};

export const ShipmentDetails = ({ compoundTransportOrder }: ShipmentDetailsProps) => {
    const isCancelled = compoundTransportOrder.status === CompoundTransportOrderStatus.CANCELLED;
    const { model } = compoundTransportOrder.vehicle;

    return (
        <ul className="list-group margin-20">
            <li className="list-group-item">
                <VehicleDetailsHeader
                    messageId="outboundOrderBook.common.heading.model"
                    model={model}
                    isCancelled={isCancelled}
                />
            </li>
            <li className="list-group-item">
                <ShipmentDetailsTable
                    vehicle={compoundTransportOrder.vehicle}
                    serviceKey={undefined}
                    displayServiceKey={false}
                />
            </li>
        </ul>
    );
};
