import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { prepareHeaders } from './utils';
import { Asset } from '../transportAssignment/assets.types';
import { decodeResponse, validateResponse } from './responseUtil';
import { AssetsCodec, AssetStatus, AssetType } from './types/assetsApi.types';
import { mapAssetQueryParameter, mapToAssets } from './mappers/assetsMapper';

export const ASSETS_PATH = 'assets';
const ASSETS_TAG = 'ASSETS_TAG';

export type GetAssetsQueryParam = {
    accountId: string;
    status: AssetStatus;
    type: AssetType;
};
export const assetsApi = createApi({
    reducerPath: 'assetsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.ASSET_SERVICE,
        prepareHeaders,
    }),
    tagTypes: [ASSETS_TAG],
    endpoints: (builder) => ({
        getAssets: builder.query<Asset[], GetAssetsQueryParam>({
            query: (queryParameters) => ({
                url: ASSETS_PATH,
                params: mapAssetQueryParameter(queryParameters),
                validateStatus: validateResponse(AssetsCodec),
            }),
            transformResponse: decodeResponse(AssetsCodec, mapToAssets),
            providesTags: [ASSETS_TAG],
        }),
    }),
});

export const { useGetAssetsQuery } = assetsApi;
