import React, { ReactElement } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { SubmitHandler, UseFormRegister } from 'react-hook-form/dist/types/form';
import { useGetAccountsDetailsQuery } from '../../api/accountApi';
import { FormattedMessage } from 'react-intl';
import { RegistrationInputs } from '../registration.types';
import { getUserProfile } from '../../../../configuration/login/loginSlice';
import { useAppSelector } from '../../../../configuration/setup/hooks';
import { InterfaceSelection } from './InterfaceSelection';
import { getLocale } from '../../../../configuration/lang/langSlice';
import { reportErrorToSentry } from '../../../../configuration/setup/sentry';
import { runInBackgroundCallback } from '../../../../configuration/setup/backgroundActions';

const DisabledInput = (props: { label: ReactElement; value: string; elementName: string }) => (
    <div className="flex-basis-100pct">
        <label className="control-label" htmlFor={props.elementName}>
            {props.label}
        </label>
        <input disabled id={props.elementName} className="form-control" type="text" value={props.value} />
    </div>
);

const Input = (props: {
    label: ReactElement;
    elementName: keyof RegistrationInputs;
    error: FieldError | undefined;
    register: UseFormRegister<RegistrationInputs>;
    required: boolean;
    maxLength: number;
}): React.ReactElement => {
    return (
        <div className={`flex-basis-100pct form-group ${props.error ? 'has-feedback has-error' : ''}`}>
            <label className="control-label" htmlFor={props.elementName}>
                {props.label}
            </label>
            <input
                className="form-control"
                id={props.elementName}
                type="text"
                {...props.register(props.elementName, {
                    required: props.required,
                    maxLength: props.maxLength,
                    setValueAs: (value) => value?.trim() ?? '',
                })}
            />
            {props.error && (
                <>
                    <span className="form-control-feedback rioglyph rioglyph-error-sign" />
                    <span className="help-block">
                        {props.error.type === 'required' && (
                            <FormattedMessage id="outboundPortal.activation.form.required" />
                        )}
                        {props.error.type === 'maxLength' && (
                            <FormattedMessage
                                id="outboundPortal.activation.form.maxLength"
                                values={{ count: props.maxLength }}
                            />
                        )}
                    </span>
                </>
            )}
        </div>
    );
};

const Header = () => (
    <div className="display-flex flex-wrap text-center justify-content-center padding-left-50 padding-right-50 margin-top-20">
        <div className="col-ls-8 text-size-h4 text-bold">
            <FormattedMessage id="outboundPortal.activation.form.header" />
        </div>
        <div className="col-ls-8 margin-top-20">
            <FormattedMessage id="outboundPortal.activation.form.header.description" />
        </div>
    </div>
);

export const FORM_VALUE_NOT_AVAILABLE = '';
const MAX_LENGTH_PHONE = 25;
const MAX_LENGTH_DUNS = 9;
const MAX_LENGTH_SUPPLIER = 10;
export const RegistrationForm = (formProps: {
    formId: string;
    onSubmitHandler: SubmitHandler<RegistrationInputs>;
}): React.ReactElement | null => {
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<RegistrationInputs>({ mode: 'onSubmit' });

    const userProfile = useAppSelector(getUserProfile);
    const {
        data: accountDetails,
        isError,
        error,
    } = useGetAccountsDetailsQuery({ accountId: userProfile?.account!! }, { skip: userProfile?.account === undefined });

    const supportFormUrlParams = new URLSearchParams();
    supportFormUrlParams.set('opener', window.location.origin);
    supportFormUrlParams.set('locale', useAppSelector(getLocale) ?? '');
    const supportFormUrl = `https://contact-form.rio.cloud/contact?${supportFormUrlParams.toString()}`;

    if (isError) {
        reportErrorToSentry(error);
    }

    return (
        <div>
            <Header />
            <form onSubmit={runInBackgroundCallback(handleSubmit(formProps.onSubmitHandler))} id={formProps.formId}>
                <div className="padding-left-25 padding-right-25 margin-top-50">
                    <div className="display-flex gap-20">
                        <DisabledInput
                            elementName="givenName"
                            label={<FormattedMessage id="outboundPortal.activation.form.givenName" />}
                            value={userProfile?.givenName ?? FORM_VALUE_NOT_AVAILABLE}
                        />
                        <DisabledInput
                            elementName="familyName"
                            label={<FormattedMessage id="outboundPortal.activation.form.familyName" />}
                            value={userProfile?.familyName ?? FORM_VALUE_NOT_AVAILABLE}
                        />
                    </div>
                    <div className="display-flex gap-20 margin-top-20">
                        <DisabledInput
                            elementName="company"
                            label={<FormattedMessage id="outboundPortal.activation.form.company" />}
                            value={accountDetails?.name ?? FORM_VALUE_NOT_AVAILABLE}
                        />
                        <DisabledInput
                            elementName="email"
                            label={<FormattedMessage id="outboundPortal.activation.form.email" />}
                            value={userProfile?.email ?? FORM_VALUE_NOT_AVAILABLE}
                        />
                        <Input
                            label={<FormattedMessage id="outboundPortal.activation.form.phone" />}
                            elementName="phone"
                            error={errors.phone}
                            register={register}
                            required={false}
                            maxLength={MAX_LENGTH_PHONE}
                        />
                    </div>
                    <div className="bg-lightest padding-20  margin-top-20">
                        <div className="display-flex gap-20">
                            <Input
                                label={
                                    <FormattedMessage id="outboundPortal.activation.form.serviceProviderIdentification" />
                                }
                                elementName="serviceProviderIdentification"
                                error={errors.serviceProviderIdentification}
                                register={register}
                                required
                                maxLength={MAX_LENGTH_SUPPLIER}
                            />
                            <Input
                                label={<FormattedMessage id="outboundPortal.activation.form.dunsNumber" />}
                                elementName="duns"
                                error={errors.duns}
                                register={register}
                                required
                                maxLength={MAX_LENGTH_DUNS}
                            />
                        </div>
                        <InterfaceSelection control={control} />
                    </div>
                    <div className="text-color-gray text-size-12 text-center margin-top-10">
                        <FormattedMessage
                            id="outboundPortal.activation.form.support"
                            values={{
                                a: (chunks) => (
                                    <a href={supportFormUrl} rel="noreferrer noopener" target="_blank">
                                        {chunks}
                                    </a>
                                ),
                            }}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};
