import {
    TableIncomingCompoundTransportOrderColumn,
    incomingCompoundTransportOrderDefinition,
} from './IncomingCompoundTransportOrderColumns';
import {
    ColumnDefinition,
    ColumnTableHeader,
    defaultTableHeaderClassnames,
    getSortDir,
    SortColumnTableHeader,
} from '../../sharedComponents/table/SortedTableHeader';
import OnboardingTip from '@rio-cloud/rio-uikit/lib/es/OnboardingTip';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useLocalStorage from '@rio-cloud/rio-uikit/lib/es/useLocalStorage';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import {
    getIncomingCompoundTransportOrdersSort,
    incomingCompoundTransportOrdersSlice,
} from '../../reducers/incomingCompoundTransportOrdersSlice';
import SimpleTooltip from '@rio-cloud/rio-uikit/lib/es/SimpleTooltip';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

export const IncomingCompoundTransportOrderHeader = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const sort = useAppSelector(getIncomingCompoundTransportOrdersSort);

    const [showOnboarding, setShowOnboarding] = useLocalStorage('onboarding.date.incomingVehicles', 'true');

    const isTrueOnboarding = () => showOnboarding === 'true';
    const getSortingHandler = (col: TableIncomingCompoundTransportOrderColumn) =>
        dispatch(incomingCompoundTransportOrdersSlice.actions.setSorting({ column: col, dir: getSortDir(col, sort) }));

    const renderHeaderColumn = (def: ColumnDefinition<TableIncomingCompoundTransportOrderColumn>) => {
        switch (def.column) {
            case TableIncomingCompoundTransportOrderColumn.VIN:
            case TableIncomingCompoundTransportOrderColumn.MANUFACTURER:
            case TableIncomingCompoundTransportOrderColumn.MODEL:
            case TableIncomingCompoundTransportOrderColumn.MEANS_OF_TRANSPORT:
                return <ColumnTableHeader key={def.column} labelId={def.labelId} />;
            case TableIncomingCompoundTransportOrderColumn.EXPECTED_DELIVERY_AT:
                return (
                    <OnboardingTip
                        key={def.column}
                        className="z-index-10"
                        show={isTrueOnboarding()}
                        placement="bottom-start"
                        width={250}
                        title={intl.formatMessage({ id: 'outboundOrderBook.common.onboarding.date.title' })}
                        content={intl.formatMessage({
                            id: 'outboundOrderBook.compoundTransportOrder.onboarding.date.content',
                        })}
                        onHide={() => {
                            setShowOnboarding('false');
                        }}
                    >
                        <SortColumnTableHeader
                            col={def.column}
                            labelId={def.labelId}
                            sortingHandler={getSortingHandler}
                            currentSort={sort}
                        />
                    </OnboardingTip>
                );
            case TableIncomingCompoundTransportOrderColumn.STATUS:
                return (
                    <th key={def.column} className={defaultTableHeaderClassnames}>
                        <FormattedMessage id={def.labelId} />
                        <span className="margin-left-5">
                            <SimpleTooltip
                                width={250}
                                content={
                                    <>
                                        <p>
                                            <FormattedMessage id="outboundOrderBook.compoundTransportOrder.table.heading.status.unconfirmed.info" />
                                        </p>
                                        <p>
                                            <FormattedMessage id="outboundOrderBook.compoundTransportOrder.table.heading.status.confirmed.info" />
                                        </p>
                                    </>
                                }
                                placement={Tooltip.BOTTOM}
                            >
                                <span className="rioglyph rioglyph rioglyph-info-sign text-color-info" />
                            </SimpleTooltip>
                        </span>
                    </th>
                );
            case TableIncomingCompoundTransportOrderColumn.CARRIER:
                return (
                    <SortColumnTableHeader
                        key={def.column}
                        col={def.column}
                        labelId={def.labelId}
                        sortingHandler={getSortingHandler}
                        currentSort={sort}
                    />
                );
        }
    };

    return (
        <thead>
            <tr>{incomingCompoundTransportOrderDefinition.map((def) => renderHeaderColumn(def))}</tr>
        </thead>
    );
};
