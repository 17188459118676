type SimpleAssetInputProps = {
    id: string;
    setAssetName: (value: string | undefined) => void;
    placeholder: string;
    value: string;
    disabled: boolean;
};

export const SimpleAssetInput = ({ id, setAssetName, disabled, placeholder, value }: SimpleAssetInputProps) => {
    return (
        <input
            id={id}
            className="form-control"
            type="text"
            disabled={disabled}
            placeholder={placeholder}
            value={value}
            onChange={(event) => {
                setAssetName(event.currentTarget.value);
            }}
            onBlur={(event) => {
                setAssetName(event.currentTarget.value?.trim() || undefined);
            }}
        />
    );
};
