import * as t from 'io-ts';
import { nonEmptyArray, NonEmptyString } from 'io-ts-types';
import { MeansOfTransportCodec, ModeOfTransportCodec } from './transportApi.types';
import { AddressAndContactCodec, TransportCapacityOrderAddressAndContactCodec } from './addressAndContactApi.types';
import { fromEnum } from '../../responseUtil';
import { VehicleCodec, VehicleConditionTypeCodec } from '../vehicleApi.types';

export enum ApiTransportAssignmentType {
    TRANSPORT_ORDER = 'transport-order',
    TRANSPORT_ORDER_BUNDLE = 'transport-order-bundle',
    TRANSPORT_CAPACITY_ORDER = 'transport-capacity-order',
}

export const AssignmentTypeCodec = fromEnum<ApiTransportAssignmentType>(
    'ApiTransportAssignmentType',
    ApiTransportAssignmentType,
);

export enum ApiStatus {
    CANCELLATION = 'cancellation',
    CONFIRMED = 'confirmed',
    UNCONFIRMED = 'unconfirmed',
    LOADED = 'loaded',
    UNLOADED = 'unloaded',
}

export enum ApiSort {
    REQUESTED_PICK_UP_AT_ASC = '+requested_pick_up_at',
    REQUESTED_PICK_UP_AT_DES = '-requested_pick_up_at',
    REQUESTED_DELIVERY_AT_ASC = '+requested_delivery_at',
    REQUESTED_DELIVERY_AT_DES = '-requested_delivery_at',
    LOADING_CITY_ASC = '+loading_city',
    LOADING_CITY_DES = '-loading_city',
    UNLOADING_CITY_ASC = '+unloading_city',
    UNLOADING_CITY_DES = '-unloading_city',
    STATUS_ASC = '+status',
    STATUS_DES = '-status',
}

export enum ApiExecutionStatus {
    LOADED = 'loaded',
    UNLOADED = 'unloaded',
}

const QueryParameterCodec = t.partial({
    statuses: t.array(fromEnum<ApiStatus>('ApiStatus', ApiStatus)),
    archived: t.boolean,
    sort: fromEnum<ApiSort>('ApiSort', ApiSort),
    requested_pick_up_at_start_at_before: t.string,
    requested_pick_up_at_end_at_after: t.string,
    unloading_city: t.string,
    loading_city: t.string,
    q: t.string,
    limit: t.string,
});

export enum ApiExecutionEventType {
    AUTOMATIC = 'automatic',
    NON_AUTOMATIC = 'non-automatic',
}

export type ApiExecutionEvent = t.TypeOf<typeof ApiExecutionEventCodec>;

export const ApiExecutionEventCodec = t.intersection([
    t.type({
        event_status: fromEnum<ApiExecutionStatus>('ApiExecutionStatus', ApiExecutionStatus),
        event_at: t.string,
    }),
    t.partial({ event_type: fromEnum<ApiExecutionEventType>('ApiExecutionEventType', ApiExecutionEventType) }),
]);

const ReportedIncidentCodec = t.intersection([
    t.type({
        type: VehicleConditionTypeCodec,
        is_empty_run: t.boolean,
        is_active: t.boolean,
        changed_at: t.string,
    }),
    t.partial({
        description: t.string,
    }),
]);

const OriginatedTransportOrderCodec = t.intersection([
    t.type({
        id: NonEmptyString,
        external_order_id: NonEmptyString,
        vehicle: VehicleCodec,
        is_cancelled: t.boolean,
        is_loaded: t.boolean,
        is_finalized: t.boolean,
        execution_events: t.array(ApiExecutionEventCodec),
    }),
    t.partial({
        service_key: NonEmptyString,
        general_information: NonEmptyString,
        incident: ReportedIncidentCodec,
    }),
]);

const DateTimeIntervalCodec = t.type({
    start_at: t.string,
    end_at: t.string,
});

const LoadingCodec = t.intersection([
    t.type({
        address_and_contact: AddressAndContactCodec,
        requested_pick_up_at: DateTimeIntervalCodec,
        time_zone: t.string,
    }),
    t.partial({
        confirmed_pick_up_at: t.string,
    }),
]);

const UnloadingCodec = t.intersection([
    t.type({
        requested_delivery_at: t.string,
        time_zone: t.string,
    }),
    t.partial({
        address_and_contact: AddressAndContactCodec,
        confirmed_delivery_at: t.string,
    }),
]);

const TransportCapacityOrderUnloadingCodec = t.intersection([
    t.type({
        address_and_contact: TransportCapacityOrderAddressAndContactCodec,
        time_zone: t.string,
    }),
    t.partial({
        requested_delivery_at: t.string,
        confirmed_delivery_at: t.string,
    }),
]);

const TransportOrderBundleDeliveryCodec = t.type({
    originated_transport_orders: nonEmptyArray(OriginatedTransportOrderCodec),
    unloading: UnloadingCodec,
});

export const TransportAssignmentInterfaceCodec = t.intersection([
    t.type({
        type: AssignmentTypeCodec,
        loading: LoadingCodec,
        status: fromEnum<ApiStatus>('ApiStatus', ApiStatus),
        has_unacknowledged_external_changes: t.boolean,
        is_archived: t.boolean,
        is_finalized: t.boolean,
        is_qualified_for_automatic_tracking: t.boolean,
        received_at: t.string,
    }),
    t.partial({
        means_of_transport: MeansOfTransportCodec,
        mode_of_transport: ModeOfTransportCodec,
    }),
]);

const TransportOrderCodec = t.intersection([
    TransportAssignmentInterfaceCodec,
    t.type({
        id: NonEmptyString,
        external_order_id: NonEmptyString,
        vehicle: VehicleCodec,
        unloading: UnloadingCodec,
        has_selectable_unloading_address: t.boolean,
        execution_events: t.array(ApiExecutionEventCodec),
    }),
    t.partial({
        service_key: NonEmptyString,
        general_information: NonEmptyString,
        incident: ReportedIncidentCodec,
    }),
]);

const TransportOrderBundleCodec = t.intersection([
    TransportAssignmentInterfaceCodec,
    t.type({
        id: NonEmptyString,
        model_groups: t.array(NonEmptyString),
        number_of_vehicles: t.number,
        deliveries: nonEmptyArray(TransportOrderBundleDeliveryCodec),
    }),
]);

const TransportCapacityOrderCodec = t.intersection([
    TransportAssignmentInterfaceCodec,
    t.type({
        id: NonEmptyString,
        bundle_id: NonEmptyString,
        unloading: TransportCapacityOrderUnloadingCodec,
        requested_transport_capacity: t.number,
    }),
    t.partial({
        general_information: NonEmptyString,
    }),
]);

const StatusCountCodec = t.type({
    unconfirmed: t.number,
    confirmed: t.number,
    cancellation: t.number,
    loaded: t.number,
    unloaded: t.number,
});

export const TransportAssignmentCodec = t.union([
    TransportOrderCodec,
    TransportOrderBundleCodec,
    TransportCapacityOrderCodec,
]);

export const TransportAssignmentPageCodec = t.type({
    items: t.array(TransportAssignmentCodec),
    has_more: t.boolean,
    total_per_status: StatusCountCodec,
});

export type ApiLoading = t.TypeOf<typeof LoadingCodec>;
export type ApiUnloading = t.TypeOf<typeof UnloadingCodec>;
export type ApiDateTimeInterval = t.TypeOf<typeof DateTimeIntervalCodec>;
export type ApiTransportOrder = t.TypeOf<typeof TransportOrderCodec>;
export type ApiTransportCapacityOrderUnloading = t.TypeOf<typeof TransportCapacityOrderUnloadingCodec>;
export type ApiTransportCapacityOrder = t.TypeOf<typeof TransportCapacityOrderCodec>;
export type ApiTransportOrderBundle = t.TypeOf<typeof TransportOrderBundleCodec>;
export type ApiTransportOrderBundleDeliveries = t.TypeOf<typeof TransportOrderBundleDeliveryCodec>;
export type ApiTransportAssignment = t.TypeOf<typeof TransportAssignmentCodec>;
export type ApiTransportAssignmentPage = t.TypeOf<typeof TransportAssignmentPageCodec>;
export type ApiOriginatedTransportOrder = t.TypeOf<typeof OriginatedTransportOrderCodec>;
export type ApiTransportAssignmentQueryParameter = t.TypeOf<typeof QueryParameterCodec>;
export type ApiReportedIncident = t.TypeOf<typeof ReportedIncidentCodec>;
export type ApiStatusCount = t.TypeOf<typeof StatusCountCodec>;
