import { FunctionComponent } from 'react';
import {
    ExecutionStatus,
    TransportAssignment,
    TransportOrder,
    TransportOrderBundle,
} from '../transportAssignment.types';
import { mapFormParamsToTransportOrderConfirmation } from './mapFormParamsToTransportOrderConfirmation';
import { ConfirmationFormParams, TransportAssignmentConfirmationForm } from './TransportAssignmentConfirmationForm';
import { getIsConfirmed, hasNewCar, hasVehiclesInStatus, mapToVehiclesInExecution } from '../TransportAssignmentUtils';
import { usePostTransportOrderConfirmationMutation } from '../../api/transportOrderApi';
import { handleQueryError } from '../../notifications/ErrorNotification';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { showSuccessNotification } from '../../notifications/SuccessNotification';

interface TransportOrderConfirmationFormProps {
    transportAssignment: TransportOrder | TransportOrderBundle;
}

export const TransportOrderConfirmationForm: FunctionComponent<TransportOrderConfirmationFormProps> = ({
    transportAssignment,
}) => {
    const [requestConfirmation, confirmationRequest] = usePostTransportOrderConfirmationMutation();

    const handleConfirmTransportOrder = async (formParams: ConfirmationFormParams) => {
        const result = await requestConfirmation(
            mapFormParamsToTransportOrderConfirmation(transportAssignment, formParams),
        );

        const hasError = result.hasOwnProperty('error');

        if (hasError) {
            handleQueryError((result as { error: FetchBaseQueryError | SerializedError }).error);
        } else {
            showSuccessNotification('outboundPortal.notification.confirmTransportAssignment.success');
        }
        return !hasError;
    };

    return (
        <TransportAssignmentConfirmationForm
            transportAssignment={transportAssignment}
            isConfirmPrimaryButton={shouldConfirmBePrimaryButton(transportAssignment)}
            isProcessingConfirmation={confirmationRequest.isLoading}
            handleConfirm={handleConfirmTransportOrder}
            showIncidentButton={!hasNewCar(transportAssignment)}
        />
    );
};

export const shouldConfirmBePrimaryButton = (transportAssignment: TransportAssignment): boolean => {
    const allVehicles = mapToVehiclesInExecution(transportAssignment);
    const isConfirmed = getIsConfirmed(transportAssignment);

    return (
        !isConfirmed &&
        !hasVehiclesInStatus(allVehicles, ExecutionStatus.LOADED) &&
        !hasVehiclesInStatus(allVehicles, ExecutionStatus.UNLOADED)
    );
};
