import * as t from 'io-ts';
import { OrganizationIdentifierCodec } from './sharedTypesApi.types';
import { AddressCodec } from '../address.types';

const ContactCodec = t.intersection([
    t.type({
        name: t.string,
    }),
    t.partial({
        email: t.string,
        phone: t.string,
    }),
]);

export const AddressAndContactCodec = t.intersection([
    t.type({
        identifier: OrganizationIdentifierCodec,
    }),
    t.partial({
        address: AddressCodec,
        contact: ContactCodec,
    }),
]);

export const TransportCapacityOrderAddressAndContactCodec = t.intersection([
    t.type({
        identifier: OrganizationIdentifierCodec,
        address: AddressCodec,
    }),
    t.partial({
        contact: ContactCodec,
    }),
]);

export const SelectableUnloadingAddressCodec = t.type({
    identifier: OrganizationIdentifierCodec,
    address: AddressCodec,
});

export const SelectableUnloadingAddressesCodec = t.type({
    items: t.array(SelectableUnloadingAddressCodec),
});

export type ApiAddressAndContact = t.TypeOf<typeof AddressAndContactCodec>;
export type ApiTransportCapacityOrderAddressAndContact = t.TypeOf<typeof TransportCapacityOrderAddressAndContactCodec>;
export type ApiContact = t.TypeOf<typeof ContactCodec>;
export type ApiSelectableUnloadingAddresses = t.TypeOf<typeof SelectableUnloadingAddressesCodec>;
