import { ServiceType } from '../../../serviceOrders/serviceType.types';
import { ApiServiceType } from '../../types/serviceOrder/serviceTypesApi.type';

export const mapToServiceType = (apiServiceType: ApiServiceType): ServiceType => {
    switch (apiServiceType) {
        case ApiServiceType.X01:
            return ServiceType.X01;
        case ApiServiceType.X02:
            return ServiceType.X02;
        case ApiServiceType.X03:
            return ServiceType.X03;
        case ApiServiceType.X04:
            return ServiceType.X04;
        case ApiServiceType.X05:
            return ServiceType.X05;
        case ApiServiceType.X06:
            return ServiceType.X06;
        case ApiServiceType.X07:
            return ServiceType.X07;
        case ApiServiceType.X08:
            return ServiceType.X08;
        case ApiServiceType.X09:
            return ServiceType.X09;
        case ApiServiceType.X10:
            return ServiceType.X10;
        case ApiServiceType.X11:
            return ServiceType.X11;
        case ApiServiceType.X12:
            return ServiceType.X12;
        case ApiServiceType.X13:
            return ServiceType.X13;
        case ApiServiceType.X14:
            return ServiceType.X14;
        case ApiServiceType.X15:
            return ServiceType.X15;
        case ApiServiceType.X16:
            return ServiceType.X16;
        case ApiServiceType.X17:
            return ServiceType.X17;
        case ApiServiceType.X18:
            return ServiceType.X18;
        case ApiServiceType.X19:
            return ServiceType.X19;
        case ApiServiceType.X20:
            return ServiceType.X20;
        case ApiServiceType.X21:
            return ServiceType.X21;
        case ApiServiceType.X22:
            return ServiceType.X22;
        case ApiServiceType.X23:
            return ServiceType.X23;
        case ApiServiceType.X24:
            return ServiceType.X24;
        case ApiServiceType.X25:
            return ServiceType.X25;
        case ApiServiceType.X26:
            return ServiceType.X26;
        case ApiServiceType.X27:
            return ServiceType.X27;
        case ApiServiceType.X99:
            return ServiceType.X99;
    }
};
export const mapToApiServiceType = (serviceType: ServiceType): ApiServiceType => {
    switch (serviceType) {
        case ServiceType.X01:
            return ApiServiceType.X01;
        case ServiceType.X02:
            return ApiServiceType.X02;
        case ServiceType.X03:
            return ApiServiceType.X03;
        case ServiceType.X04:
            return ApiServiceType.X04;
        case ServiceType.X05:
            return ApiServiceType.X05;
        case ServiceType.X06:
            return ApiServiceType.X06;
        case ServiceType.X07:
            return ApiServiceType.X07;
        case ServiceType.X08:
            return ApiServiceType.X08;
        case ServiceType.X09:
            return ApiServiceType.X09;
        case ServiceType.X10:
            return ApiServiceType.X10;
        case ServiceType.X11:
            return ApiServiceType.X11;
        case ServiceType.X12:
            return ApiServiceType.X12;
        case ServiceType.X13:
            return ApiServiceType.X13;
        case ServiceType.X14:
            return ApiServiceType.X14;
        case ServiceType.X15:
            return ApiServiceType.X15;
        case ServiceType.X16:
            return ApiServiceType.X16;
        case ServiceType.X17:
            return ApiServiceType.X17;
        case ServiceType.X18:
            return ApiServiceType.X18;
        case ServiceType.X19:
            return ApiServiceType.X19;
        case ServiceType.X20:
            return ApiServiceType.X20;
        case ServiceType.X21:
            return ApiServiceType.X21;
        case ServiceType.X22:
            return ApiServiceType.X22;
        case ServiceType.X23:
            return ApiServiceType.X23;
        case ServiceType.X24:
            return ApiServiceType.X24;
        case ServiceType.X25:
            return ApiServiceType.X25;
        case ServiceType.X26:
            return ApiServiceType.X26;
        case ServiceType.X27:
            return ApiServiceType.X27;
        case ServiceType.X99:
            return ApiServiceType.X99;
    }
};
