import { Model, Vehicle } from '../../../compoundManagement/compoundManagement.types';
import { mapToCategory, mapToFuelType, mapToVehicleDimensions, mapToVehicleType } from '../vehicleMappers';
import { parseStringDate } from '../../../sharedComponents/dateHelper';
import { mapToFinalDestination } from './finalDestinationMapper';
import { ApiModel, ApiVehicle } from '../../types/compoundManagement/vehicleCompound.types';

export const mapToVehicle = (apiVehicle: ApiVehicle): Vehicle => ({
    vin: apiVehicle.vin,
    vehicleType: apiVehicle.vehicle_type && mapToVehicleType(apiVehicle.vehicle_type),
    model: mapToModel(apiVehicle.model),
    manufacturedAt: apiVehicle.manufactured_at && parseStringDate(apiVehicle.manufactured_at),
    finalDestination: apiVehicle.final_destination && mapToFinalDestination(apiVehicle.final_destination),
    dimensions: apiVehicle.dimensions && mapToVehicleDimensions(apiVehicle.dimensions),
    weight: apiVehicle.weight,
    colour: apiVehicle.colour,
    licensePlate: apiVehicle.license_plate,
});

export const mapToModel = (apiModel: ApiModel): Model => ({
    name: apiModel.name,
    version: apiModel.version,
    group: apiModel.group,
    fuelType: apiModel.fuel_type && mapToFuelType(apiModel.fuel_type),
    manufacturer: apiModel.manufacturer,
    category: apiModel.category && mapToCategory(apiModel.category),
});
