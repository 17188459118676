import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../../configuration/setup/store';
import { TransportAssignment, TransportAssignmentStatus } from '../transportAssignment/transportAssignment.types';
import { Filters, ModalFilters } from '../transportAssignment/filter.types';
import { TransportAssignmentTableColumn } from '../transportAssignment/table/TransportAssignmentTableColumns';
import { TableCategory } from '../transportAssignment/table/toolbar/CategoriesSelection';
import { SortDirection, TableSort } from '../sharedComponents/table/SortedTableHeader';

export const NO_SELECTED_TRANSPORT_ASSIGNMENT = undefined;

export type Sorting = { [key in TableCategory]: TableSort<TransportAssignmentTableColumn> };

export type TransportAssignmentsState = {
    selectedTransportAssignment: TransportAssignment | undefined;
    filters: Filters;
    sorting: Sorting;
    search: string;
};

export const initialFilterState = {
    modalFilters: {
        startDateFilter: undefined,
        endDateFilter: undefined,
        pickUpCityFilter: undefined,
        deliveryCityFilter: undefined,
    },
    statusFilter: [],
    category: TableCategory.INBOX,
};

export const initialSortingState: Sorting = {
    [TableCategory.INBOX]: { dir: SortDirection.DESCENDING, column: TransportAssignmentTableColumn.REQUESTED_PICKUP },
    [TableCategory.EN_ROUTE]: {
        dir: SortDirection.ASCENDING,
        column: TransportAssignmentTableColumn.REQUESTED_DELIVERY,
    },
    [TableCategory.FINISHED]: {
        dir: SortDirection.ASCENDING,
        column: TransportAssignmentTableColumn.REQUESTED_DELIVERY,
    },
    [TableCategory.ARCHIVE]: {
        dir: SortDirection.ASCENDING,
        column: TransportAssignmentTableColumn.REQUESTED_DELIVERY,
    },
};

export const initialTransportAssignmentsState: TransportAssignmentsState = {
    selectedTransportAssignment: NO_SELECTED_TRANSPORT_ASSIGNMENT,
    filters: initialFilterState,
    sorting: initialSortingState,
    search: '',
};

export const transportAssignmentsSlice = createSlice({
    name: 'transportAssignments',
    initialState: initialTransportAssignmentsState,
    reducers: {
        selectTransportAssignment: (state: TransportAssignmentsState, action: PayloadAction<TransportAssignment>) => {
            state.selectedTransportAssignment = action.payload;
        },
        deselectTransportAssignment: (state: TransportAssignmentsState) => {
            state.selectedTransportAssignment = NO_SELECTED_TRANSPORT_ASSIGNMENT;
        },
        setModalFilters: (state: TransportAssignmentsState, action: PayloadAction<ModalFilters>) => {
            state.filters.modalFilters = action.payload;
        },
        setStatusFilter: (state: TransportAssignmentsState, action: PayloadAction<TransportAssignmentStatus[]>) => {
            state.filters.statusFilter = action.payload;
        },
        setCategory: (state: TransportAssignmentsState, action: PayloadAction<TableCategory>) => {
            state.filters.category = action.payload;
        },
        setSortingForCategory: (
            state: TransportAssignmentsState,
            action: PayloadAction<{ category: TableCategory; sort: TableSort<TransportAssignmentTableColumn> }>,
        ) => {
            state.sorting[action.payload.category] = action.payload.sort;
        },
        setSearchTerm: (state: TransportAssignmentsState, action: PayloadAction<string>) => {
            state.search = action.payload;
        },
        setInitialState: () => initialTransportAssignmentsState,
    },
});

export const getSelectedTransportAssignment = (state: RootState) =>
    state.portal.transportAssignments.selectedTransportAssignment;

export const getFilters = (state: RootState) => state.portal.transportAssignments.filters;
export const getModalFilters = (state: RootState) => state.portal.transportAssignments.filters.modalFilters;
export const getStatusFilter = (state: RootState) => state.portal.transportAssignments.filters.statusFilter;
export const getSelectedCategory = (state: RootState) => state.portal.transportAssignments.filters.category;
export const getSortingForCategory = (category: TableCategory) => (state: RootState) =>
    state.portal.transportAssignments.sorting[category];
export const getTransportAssignmentSearchTerm = (state: RootState) => state.portal.transportAssignments.search;
