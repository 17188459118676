import TableCardsSorting from '@rio-cloud/rio-uikit/lib/es/TableCardsSorting';
import TableViewToggles from '@rio-cloud/rio-uikit/lib/es/TableViewToggles';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import { getTableViewType } from '../../reducers/viewSlice';
import { SortDirection, TableSort } from '../../sharedComponents/table/SortedTableHeader';
import {
    TableIncomingCompoundTransportOrderColumn,
    incomingCompoundTransportOrderDefinition,
} from './IncomingCompoundTransportOrderColumns';
import { incomingCompoundTransportOrdersSlice } from '../../reducers/incomingCompoundTransportOrdersSlice';

type IncomingCompoundTransportOrderCardsSortingProps = {
    sorting: TableSort<TableIncomingCompoundTransportOrderColumn>;
};

export const IncomingCompoundTransportOrderCardsSorting = (props: IncomingCompoundTransportOrderCardsSortingProps) => {
    const intl = useIntl();
    const viewType = useAppSelector(getTableViewType);
    const dispatch = useAppDispatch();

    const cardSortingSelectOptions = incomingCompoundTransportOrderDefinition
        .filter((def) => def.sortable)
        .map((def) => {
            const label = def.labelId !== undefined ? intl.formatMessage({ id: def.labelId }) : undefined;

            return {
                id: def.column,
                label,
                selected: def.column === props.sorting.column,
                disabled: false,
            };
        });

    const handleCardSortChange = (sortByColumn: TableIncomingCompoundTransportOrderColumn, sortDir: SortDirection) =>
        dispatch(incomingCompoundTransportOrdersSlice.actions.setSorting({ column: sortByColumn, dir: sortDir }));

    return viewType === TableViewToggles.VIEW_TYPE_TABLE ? (
        <></>
    ) : (
        <TableCardsSorting
            selectOptions={cardSortingSelectOptions}
            sortName={props.sorting.column}
            sortOrder={props.sorting.dir}
            onSortChange={handleCardSortChange}
        />
    );
};
