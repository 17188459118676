import {
    AddressAndContact,
    ExecutionStatus,
    OriginatedTransportOrder,
    TransportAssignment,
    TransportAssignmentStatus,
    TransportAssignmentType,
    TransportOrder,
    TransportOrderBundle,
    TransportOrderBundleDeliveries,
    Unloading,
} from './transportAssignment.types';
import { Vehicle, VehicleType } from '../sharedComponents/common.types';
import { max, maxBy } from 'lodash';

export type HierarchyCases<T extends unknown> = {
    archived: T;
    finalized: T;
    cancelled: T;
    unconfirmed: T;
    incompleteConfirmed: T;
    completeConfirmed: T;
    loaded: T;
    unloaded: T;
};

export const getByDefaultHierarchy = <T extends unknown>(
    status: TransportAssignmentStatus,
    isArchived: boolean,
    isFinalized: boolean,
    cases: HierarchyCases<T>,
): T => {
    if (isArchived) {
        return cases.archived;
    }
    if (isFinalized && status !== TransportAssignmentStatus.CANCELLATION) {
        return cases.finalized;
    }
    switch (status) {
        case TransportAssignmentStatus.CANCELLATION:
            return cases.cancelled;
        case TransportAssignmentStatus.INCOMPLETE_CONFIRMED:
            return cases.incompleteConfirmed;
        case TransportAssignmentStatus.COMPLETE_CONFIRMED:
            return cases.completeConfirmed;
        case TransportAssignmentStatus.UNCONFIRMED:
            return cases.unconfirmed;
        case TransportAssignmentStatus.LOADED:
            return cases.loaded;
        case TransportAssignmentStatus.UNLOADED:
            return cases.unloaded;
    }
};

export const isBundle = (assignment: TransportAssignment): assignment is TransportOrderBundle =>
    assignment.type === TransportAssignmentType.TRANSPORT_ORDER_BUNDLE;

export const isTransportOrder = (assignment: TransportAssignment): assignment is TransportOrder =>
    assignment.type === TransportAssignmentType.TRANSPORT_ORDER;

export const hasNewCar = (assignment: TransportAssignment): boolean => {
    if (isBundle(assignment)) {
        return assignment.deliveries
            .flatMap((delivery) => delivery.originatedTransportOrders.map((order) => order.vehicle.vehicleType))
            .some((type) => type === VehicleType.NEW);
    } else {
        return (assignment as TransportOrder).vehicle.vehicleType === VehicleType.NEW;
    }
};

export const getTransportAssignmentIds = (transportAssignment: TransportAssignment): string[] => {
    switch (transportAssignment.type) {
        case TransportAssignmentType.TRANSPORT_CAPACITY_ORDER:
            return [transportAssignment.id];
        case TransportAssignmentType.TRANSPORT_ORDER:
            return [transportAssignment.id];
        case TransportAssignmentType.TRANSPORT_ORDER_BUNDLE:
            return transportAssignment.deliveries.flatMap((delivery) =>
                delivery.originatedTransportOrders.map((it) => it.id),
            );
    }
};

export const getIdentifier = (assignment: TransportAssignment): string => {
    switch (assignment.type) {
        case TransportAssignmentType.TRANSPORT_ORDER:
            return assignment.externalOrderId;
        case TransportAssignmentType.TRANSPORT_ORDER_BUNDLE:
            return assignment.id;
        case TransportAssignmentType.TRANSPORT_CAPACITY_ORDER:
            return assignment.bundleId;
    }
};

export const getUnloadingAddress = (transportAssignment: TransportAssignment): AddressAndContact | undefined => {
    if (transportAssignment.type === TransportAssignmentType.TRANSPORT_ORDER_BUNDLE) {
        if ((transportAssignment as TransportOrderBundle).deliveries.length === 1) {
            return (transportAssignment as TransportOrderBundle).deliveries[0].unloading.addressAndContact;
        } else {
            return undefined;
        }
    } else {
        return transportAssignment.unloading.addressAndContact;
    }
};

export const getIsConfirmed = (transportAssignment: TransportAssignment): boolean =>
    transportAssignment.status in
        [TransportAssignmentStatus.COMPLETE_CONFIRMED, TransportAssignmentStatus.INCOMPLETE_CONFIRMED] ||
    getHasConfirmationInfo(transportAssignment);

const getHasConfirmationInfo = (transportAssignment: TransportAssignment): boolean => {
    const isUnloadingDateConfirmed = isBundle(transportAssignment)
        ? transportAssignment.deliveries.some((delivery) => delivery.unloading.confirmedDeliveryDate !== undefined)
        : transportAssignment.unloading.confirmedDeliveryDate !== undefined;

    const hasUserProvidedAddress =
        isTransportOrder(transportAssignment) &&
        transportAssignment.hasSelectableUnloadingAddress &&
        transportAssignment.unloading.addressAndContact !== undefined;

    return (
        transportAssignment.meansOfTransport?.id !== undefined ||
        transportAssignment.loading.confirmedPickUpDate !== undefined ||
        isUnloadingDateConfirmed ||
        hasUserProvidedAddress
    );
};

export type VehicleInExecution = Vehicle &
    Pick<OriginatedTransportOrder, 'isCancelled' | 'executionEvents' | 'isFinalized' | 'incident'> & {
        originatedTransportOrderId: OriginatedTransportOrder['id'];
    };

export const mapToVehiclesInExecution = (transportAssignment: TransportAssignment): VehicleInExecution[] => {
    return isBundle(transportAssignment)
        ? transportAssignment.deliveries.flatMap((delivery) =>
              delivery.originatedTransportOrders.map((originatedTransportOrder) => ({
                  ...originatedTransportOrder.vehicle,
                  isCancelled: originatedTransportOrder.isCancelled,
                  executionEvents: originatedTransportOrder.executionEvents,
                  originatedTransportOrderId: originatedTransportOrder.id,
                  isFinalized: originatedTransportOrder.isFinalized,
                  incident: originatedTransportOrder.incident,
              })),
          )
        : isTransportOrder(transportAssignment)
          ? [
                {
                    ...transportAssignment.vehicle,
                    isCancelled: transportAssignment.status === TransportAssignmentStatus.CANCELLATION,
                    executionEvents: transportAssignment.executionEvents,
                    originatedTransportOrderId: transportAssignment.id,
                    isFinalized: transportAssignment.isFinalized,
                    incident: transportAssignment.incident,
                },
            ]
          : [];
};

export const hasVehiclesInStatus = (vehicles: VehicleInExecution[], status: ExecutionStatus): boolean =>
    vehicles.some((vehicle) => vehicle.executionEvents.some((event) => event.eventStatus === status));

export const getHasActiveIncident = (transportAssignment: TransportAssignment) => {
    switch (transportAssignment.type) {
        case TransportAssignmentType.TRANSPORT_ORDER:
            return transportAssignment.incident?.isActive === true;
        case TransportAssignmentType.TRANSPORT_ORDER_BUNDLE:
            return transportAssignment.deliveries
                .flatMap((delivery: TransportOrderBundleDeliveries) =>
                    delivery.originatedTransportOrders.map((transportOrder) => transportOrder.incident?.isActive),
                )
                .some((value: boolean | undefined) => value === true);
        case TransportAssignmentType.TRANSPORT_CAPACITY_ORDER:
            return false;
    }
};

/**
 * Returns a requested delivery date for a Bundle, which may contain multiple requested delivery dates.
 * The selected date will be the one that is further in the future.
 */
const getBundleRequestedDeliveryDate = (
    transportOrderBundle: TransportOrderBundle,
): { requestedDeliveryDate: Date; timeZone: string } => {
    return (
        maxBy(
            transportOrderBundle.deliveries,
            (delivery) => delivery.unloading.requestedDeliveryDate,
        ) as TransportOrderBundleDeliveries
    ).unloading;
};

/**
 * Returns a confirmed delivery date for an unloading list, part of a Bundle which may contain multiple confirmed dates.
 * The selected date will be the one that is further in the future.
 */
export const getBundleUnloadingConfirmedDeliveryDate = (unloadingList: Unloading[]): Date | undefined => {
    return max(unloadingList.map((unloading) => unloading.confirmedDeliveryDate));
};

/**
 * Returns the requested and confirmed delivery dates and its timezone for the given transport assignment.
 */
export const getTransportAssignmentDeliveryDates = (
    transportAssignment: TransportAssignment,
): { requestedDeliveryDate: Date | undefined; confirmedDeliveryDate: Date | undefined; timeZone: string } => {
    if (transportAssignment.type === TransportAssignmentType.TRANSPORT_ORDER_BUNDLE) {
        const bundleRequestedDeliveryDate = getBundleRequestedDeliveryDate(transportAssignment as TransportOrderBundle);
        const unloadingList = (transportAssignment as TransportOrderBundle).deliveries.map(
            (delivery) => delivery.unloading,
        );
        return {
            requestedDeliveryDate: bundleRequestedDeliveryDate.requestedDeliveryDate,
            confirmedDeliveryDate: getBundleUnloadingConfirmedDeliveryDate(unloadingList),
            timeZone: bundleRequestedDeliveryDate.timeZone,
        };
    } else {
        return {
            requestedDeliveryDate: transportAssignment.unloading.requestedDeliveryDate,
            confirmedDeliveryDate: transportAssignment.unloading.confirmedDeliveryDate,
            timeZone: transportAssignment.unloading.timeZone,
        };
    }
};
