import classNames from 'classnames';
import { StatusLabel } from '../../sharedComponents/StatusLabel';
import { CompoundTransportOrder } from '../compoundTransportOrder.types';
import { LabelledInfoCell } from '../../sharedComponents/sidebar/LabelledInfoCell';

type VehicleIdAndStatusProps = {
    compoundTransportOrder: CompoundTransportOrder;
};

export const VehicleIdAndStatus = ({ compoundTransportOrder }: VehicleIdAndStatusProps) => {
    const status = compoundTransportOrder.status;

    return (
        <div
            className={classNames(
                'border-color-light',
                'border-style-solid',
                'border-width-1',
                'border-bottom-only',
                'bg-lightest',
                'display-flex',
                'gap-10',
                'padding-25',
            )}
        >
            <LabelledInfoCell labelMessageId="outboundOrderBook.common.heading.vin">
                {getIdentifier(compoundTransportOrder)}
            </LabelledInfoCell>
            <LabelledInfoCell labelMessageId="outboundOrderBook.common.heading.status">
                <StatusLabel status={status} />
            </LabelledInfoCell>
        </div>
    );
};

const getIdentifier = (compoundTransportOrder: CompoundTransportOrder) => {
    const vehicleId = compoundTransportOrder.vehicle.id;
    return vehicleId.vin ?? vehicleId.productionNumber;
};
