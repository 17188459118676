export type SidebarExpanderStyles = {
    bodyBackgroundStyles: string[];
    headerTextStyles: string[];
    titleIcon: string[] | undefined;
    borderStyle: string[];
};

export const sidebarDefaultStyles: SidebarExpanderStyles = {
    bodyBackgroundStyles: ['bg-lightest'],
    headerTextStyles: ['text-color-success'],
    titleIcon: undefined,
    borderStyle: ['border-color-highlight'],
};
