import Sidebar from '@rio-cloud/rio-uikit/lib/es/Sidebar';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import {
    compoundManagementSlice,
    getActiveCompoundManagementView,
    getSelectedCompoundVehicle,
} from '../../reducers/compoundManagementSlice';
import { CompoundManagementView } from '../compoundManagement.types';
import { InventoryVehicleId } from './InventoryVehicleId';
import { OtherDetails } from './OtherDetails';
import { VehicleDetails } from './VehicleDetails';

export const DepartedVehiclesSidebar: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const selectedVehicle = useAppSelector(getSelectedCompoundVehicle);
    const activeManagementView = useAppSelector(getActiveCompoundManagementView);

    const sidebarProperties = {
        showHeaderBorder: true,
        resizable: true,
        position: 'right',
        minWidth: 400,
        maxWidth: 550,
        width: 550,
        fly: true,
    };

    const closeSidebar = () => {
        dispatch(compoundManagementSlice.actions.deselectCompoundVehicle());
    };

    if (selectedVehicle !== undefined && activeManagementView === CompoundManagementView.DEPARTED) {
        const title = <FormattedMessage id="outboundOrderBook.compoundManagement.common.sidebar.header.titleVehicle" />;

        const footerButton = (
            <div className="display-flex gap-10 flex-row-reverse">
                <button className="btn btn-default" type="button" onClick={closeSidebar}>
                    <FormattedMessage id="outboundOrderBook.common.button.close" />
                </button>
            </div>
        );

        return (
            <Sidebar
                {...sidebarProperties}
                closed={false}
                onClose={closeSidebar}
                title={title}
                titleClassName="padding-left-10"
                footer={footerButton}
                footerClassName="margin-right-10"
            >
                <InventoryVehicleId vehicle={selectedVehicle.vehicle} />
                <OtherDetails vehicle={selectedVehicle.vehicle} />
                <VehicleDetails vehicle={selectedVehicle.vehicle} isCancelled={false} />
            </Sidebar>
        );
    } else {
        return <Sidebar {...sidebarProperties} closed />;
    }
};
