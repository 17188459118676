import classNames from 'classnames';
import { AddressDetails } from '../../sharedComponents/AddressDetails';
import { NoValue } from '../../sharedComponents/NoValue';
import { formatDateInTimeZone, formatTimeInTimeZone, formatTimeZone } from '../../sharedComponents/dateHelper';
import { CompoundTransportOrder } from '../compoundTransportOrder.types';
import React from 'react';
import { LabelledInfoCell } from '../../sharedComponents/sidebar/LabelledInfoCell';

type DateTimeInfoProps = {
    date: Date;
    timezone: string;
};

export const DateTimeInfo = ({ date, timezone }: DateTimeInfoProps) => (
    <span>
        <div>{formatDateInTimeZone(date, timezone)}</div>
        <div>{formatTimeInTimeZone(date, timezone)}</div>
    </span>
);

export const DateInfo = ({ date, timezone }: DateTimeInfoProps) => (
    <span>
        <div>{formatDateInTimeZone(date, timezone)}</div>
    </span>
);

type TransportDetailsProps = {
    compoundTransportOrder: CompoundTransportOrder;
};
export const TransportDetails = ({ compoundTransportOrder }: TransportDetailsProps) => {
    const rowClasses = classNames('display-flex', 'gap-10', 'margin-top-20', 'margin-left-25', 'align-items-start');
    return (
        <>
            <div className={rowClasses}>
                <LabelledInfoCell labelMessageId="outboundPortal.incomingCompoundTransportOrder.sidebar.unloadingLocation">
                    {compoundTransportOrder.deliveryLocation?.address !== undefined ? (
                        <AddressDetails address={compoundTransportOrder.deliveryLocation.address} />
                    ) : (
                        <NoValue />
                    )}
                </LabelledInfoCell>
                <LabelledInfoCell labelMessageId="outboundPortal.incomingCompoundTransportOrder.sidebar.finalDestination">
                    {compoundTransportOrder.vehicle.finalDestination !== undefined ? (
                        <AddressDetails address={compoundTransportOrder.vehicle.finalDestination} />
                    ) : (
                        <NoValue />
                    )}
                </LabelledInfoCell>
            </div>
            <div className="separator margin-left-20 margin-right-20">
                <hr className="margin-bottom-0 margin-top-20" />
            </div>
            <div className={rowClasses}>
                <LabelledInfoCell
                    labelMessageId="outboundPortal.incomingCompoundTransportOrder.sidebar.expectedDelivery"
                    additionalLabel={formatTimeZone(
                        compoundTransportOrder.expectedDeliveryAt,
                        compoundTransportOrder.timezone,
                    )}
                >
                    <DateTimeInfo
                        date={compoundTransportOrder.expectedDeliveryAt}
                        timezone={compoundTransportOrder.timezone}
                    />
                </LabelledInfoCell>
                <LabelledInfoCell labelMessageId="outboundPortal.incomingCompoundTransportOrder.sidebar.manufacturedAt">
                    {compoundTransportOrder.vehicle.manufacturedAt !== undefined ? (
                        <DateInfo
                            date={compoundTransportOrder.vehicle.manufacturedAt}
                            timezone={compoundTransportOrder.timezone}
                        />
                    ) : (
                        <NoValue />
                    )}
                </LabelledInfoCell>
            </div>
        </>
    );
};
