import { ReactNode } from 'react';
import { Vehicle } from '../common.types';
import { DetailsTable } from './DetailsTable';
import { DetailsTableRow } from './DetailsTableRow';
import { getShipmentTableData } from './ShipmentDetailsRows';

type ShipmentDetailsTableProps = {
    vehicle: Vehicle;
    serviceKey: string | undefined;
    displayServiceKey: boolean;
    additionalRows?: ReactNode;
};

export const ShipmentDetailsTable = ({
    vehicle,
    serviceKey,
    displayServiceKey,
    additionalRows,
}: ShipmentDetailsTableProps) => {
    const shipmentTableData = getShipmentTableData(vehicle, serviceKey, displayServiceKey);
    return (
        <DetailsTable>
            {shipmentTableData.map(({ messageId, value }, index) => (
                <DetailsTableRow key={index} messageId={messageId} value={value} />
            ))}
            {additionalRows}
        </DetailsTable>
    );
};
