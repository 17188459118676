export enum AccessoryType {
    KEY = 'key',
    LICENSE_PLATE = 'license-plate',
    MANUAL = 'manual',
    SERVICE_SCHEDULE = 'service-schedule',
    SUMMER_WHEELS = 'summer-wheels',
    VEHICLE_REGISTRATION_DOCUMENT = 'vehicle-registration-document',
    WINTER_WHEELS = 'winter-wheels',
}

export type CollectedAccessories = {
    readonly key?: number;
    readonly licensePlate?: number;
    readonly manual?: number;
    readonly serviceSchedule?: number;
    readonly summerWheels?: number;
    readonly vehicleRegistrationDocument?: number;
    readonly winterWheels?: number;
};
