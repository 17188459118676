import { FunctionComponent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { NoValue } from '../NoValue';

type DetailsTableRowProps = {
    messageId: string;
    value: ReactNode | undefined;
};

export const DetailsTableRow: FunctionComponent<DetailsTableRowProps> = ({ messageId, value }) => (
    <tr>
        <td>
            <FormattedMessage id={messageId} />
        </td>
        <td>{value ?? <NoValue />}</td>
    </tr>
);
