import { formatDateInTimeZone, formatTimeInTimeZone } from '../../../sharedComponents/dateHelper';
import { NoValue } from '../../../sharedComponents/NoValue';
import { IncomingVehicle } from '../../compoundManagement.types';
import {
    DispatchTypeDataContent,
    FreightForwarderDataContent,
    FuelTypeDataContent,
    ModelNameDataContent,
} from '../../table/CompoundDataTableContent';
import { IncomingVehiclesTableColumn } from './IncomingVehiclesTableColumn';

type TableDataContentProps = { column: IncomingVehiclesTableColumn; vehicle: IncomingVehicle };

export const IncomingVehiclesTableDataContent = ({ column, vehicle }: TableDataContentProps) => {
    switch (column) {
        case IncomingVehiclesTableColumn.VIN:
            return <div className="ellipsis-1">{vehicle.vehicle.vin}</div>;
        case IncomingVehiclesTableColumn.LOCATION_ID:
            return <span>{vehicle.compoundLocation.identifier}</span>;
        case IncomingVehiclesTableColumn.LOCATION_CITY:
            return <span>{vehicle.compoundLocation.city}</span>;
        case IncomingVehiclesTableColumn.EXPECTED_ARRIVAL_DATE:
            return (
                <>
                    <div>
                        {formatDateInTimeZone(vehicle.arrival.expectedArrivalAt, vehicle.compoundLocation.timeZone)}
                    </div>
                    <div>
                        {formatTimeInTimeZone(vehicle.arrival.expectedArrivalAt, vehicle.compoundLocation.timeZone)}
                    </div>
                </>
            );
        case IncomingVehiclesTableColumn.MANUFACTURER:
            return vehicle.vehicle.model.manufacturer ?? <NoValue />;
        case IncomingVehiclesTableColumn.MODEL:
            return <ModelNameDataContent vehicle={vehicle.vehicle} />;
        case IncomingVehiclesTableColumn.DISPATCH_TYPE:
            return <DispatchTypeDataContent vehicle={vehicle.vehicle} />;
        case IncomingVehiclesTableColumn.FUEL_TYPE:
            return <FuelTypeDataContent vehicle={vehicle.vehicle} />;
        case IncomingVehiclesTableColumn.MODEL_VERSION:
            return vehicle.vehicle.model.version ?? <NoValue />;

        case IncomingVehiclesTableColumn.ARRIVAL_FREIGHT_FORWARDER:
            return <FreightForwarderDataContent freightForwarder={vehicle.arrival.freightForwarder} />;
        case IncomingVehiclesTableColumn.MEANS_OF_TRANSPORT:
            return vehicle.arrival.meansOfTransport.id ?? <NoValue />;
    }
};
