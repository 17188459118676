import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import Multiselect from '@rio-cloud/rio-uikit/lib/es/Multiselect';
import { RootState } from '../../../../configuration/setup/store';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { ReactElement } from 'react';
import { StatusLabel, StatusLabelProps } from '../StatusLabel';

type FilterableStatus = StatusLabelProps['status'];

type StatusFilterProps<S> = {
    statusOptions: S[];
    getFilterSelector: (state: RootState) => S[];
    setFilterAction: ActionCreatorWithPayload<S[]>;
    optionClassName?: Map<S, string>;
};

type StatusFilterOption = {
    id: string;
    label: ReactElement;
};

export const STATUS_FILTER_PLACEHOLDER = 'outboundPortal.transportAssignments.table.toolbar.filter.status.placeholder';

const createStatusOptions = <S extends FilterableStatus>(
    options: S[],
    additionalClassNames?: Map<S, string>,
): StatusFilterOption[] => {
    return options.map((status) => ({
        id: status,
        label: (
            <StatusLabel
                status={status}
                className={classNames('custom-label-centering', additionalClassNames?.get(status))}
            />
        ),
    }));
};

export const StatusFilter = <S extends FilterableStatus>(props: StatusFilterProps<S>) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const statusFilter = useAppSelector(props.getFilterSelector);

    const statusOptions = createStatusOptions(props.statusOptions, props.optionClassName);

    const handleStatusFilterChange = (selectedFilters: S[]) => dispatch(props.setFilterAction(selectedFilters));

    return (
        <Multiselect
            placeholder={intl.formatMessage({
                id: STATUS_FILTER_PLACEHOLDER,
            })}
            options={statusOptions}
            value={[...statusFilter]}
            onChange={handleStatusFilterChange}
        />
    );
};
