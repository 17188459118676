import { TransportAssignmentStatus, TransportAssignmentType } from './transportAssignment.types';
import { getByDefaultHierarchy } from './TransportAssignmentUtils';
import { ActionButtonConfig } from '../sharedComponents/common.types';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';

const getUnconfirmedConfig = (type: TransportAssignmentType): ActionButtonConfig => {
    const labelId =
        type === TransportAssignmentType.TRANSPORT_CAPACITY_ORDER
            ? 'outboundPortal.transportAssignments.button.confirmCapacity'
            : 'outboundPortal.transportAssignments.button.confirmOrder';
    return {
        labelId,
        style: Button.DEFAULT,
        icon: 'rioglyph rioglyph-ok',
        expandSidebar: true,
        isSpinnerInverse: false,
    };
};

const getCompleteConfirmationConfig = (type: TransportAssignmentType) => {
    return type === TransportAssignmentType.TRANSPORT_CAPACITY_ORDER
        ? completeCapacityConfirmationConfig
        : completeConfirmationConfig;
};

const incompleteConfirmationConfig: ActionButtonConfig = {
    labelId: 'outboundPortal.transportAssignments.button.completeData',
    style: Button.WARNING,
    icon: 'rioglyph rioglyph-exclamation-sign',
    expandSidebar: true,
    isSpinnerInverse: true,
};

const completeConfirmationConfig: ActionButtonConfig = {
    labelId: 'outboundPortal.transportAssignments.button.confirmLoading',
    style: Button.PRIMARY,
    icon: 'rioglyph rioglyph-truck',
    expandSidebar: true,
    isSpinnerInverse: true,
};

const completeCapacityConfirmationConfig: ActionButtonConfig = {
    labelId: 'outboundPortal.transportAssignments.button.updateCapacity',
    style: Button.DEFAULT,
    icon: 'rioglyph rioglyph-refresh',
    expandSidebar: true,
    isSpinnerInverse: true,
};

const updateTimesButtonConfig: ActionButtonConfig = {
    labelId: 'outboundPortal.transportAssignments.button.updateTimes',
    style: Button.DEFAULT,
    icon: 'rioglyph rioglyph-time',
    expandSidebar: true,
    isSpinnerInverse: true,
};

const archiveButtonConfig: ActionButtonConfig = {
    labelId: 'outboundPortal.transportAssignments.button.archive',
    style: Button.DANGER,
    className: 'btn-outline',
    icon: 'rioglyph rioglyph-folder-closed',
    expandSidebar: false,
    isSpinnerInverse: true,
};

const loadedConfig: ActionButtonConfig = {
    labelId: 'outboundPortal.transportAssignments.button.confirmUnloading',
    style: Button.PRIMARY,
    icon: 'rioglyph rioglyph-finish',
    expandSidebar: true,
    isSpinnerInverse: true,
};

const getCancelledConfig = (type: TransportAssignmentType): ActionButtonConfig | undefined => {
    if (type !== TransportAssignmentType.TRANSPORT_CAPACITY_ORDER) {
        return archiveButtonConfig;
    } else {
        return undefined;
    }
};

export const getActionButtonConfig = (
    status: TransportAssignmentStatus,
    type: TransportAssignmentType,
    isArchived: boolean,
    isFinalized: boolean,
    isSidebar: boolean = false,
) => {
    return getByDefaultHierarchy(status, isArchived, isFinalized, {
        archived: undefined,
        finalized: archiveButtonConfig,
        unconfirmed: isSidebar ? undefined : getUnconfirmedConfig(type),
        cancelled: getCancelledConfig(type),
        unloaded: updateTimesButtonConfig,
        loaded: loadedConfig,
        incompleteConfirmed: isSidebar ? getCompleteConfirmationConfig(type) : incompleteConfirmationConfig,
        completeConfirmed: getCompleteConfirmationConfig(type),
    });
};

export type TransportAssignmentSidebarExpanderButtonStyle = {
    actionButtonStyles: string;
    actionButtonIconStyles: string;
};

export const defaultExpanderButtonStyle: TransportAssignmentSidebarExpanderButtonStyle = {
    actionButtonStyles: 'btn btn-default',
    actionButtonIconStyles: 'rioglyph rioglyph-refresh',
};

export const unconfirmedExpanderButtonStyle: TransportAssignmentSidebarExpanderButtonStyle = {
    actionButtonStyles: 'btn btn-primary',
    actionButtonIconStyles: 'rioglyph rioglyph-ok',
};
