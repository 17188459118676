import * as t from 'io-ts';

export const AddressCodec = t.intersection([
    t.type({
        name: t.string,
        street: t.string,
        postal_code: t.string,
        city: t.string,
        country_code: t.string,
    }),
    t.partial({
        additional_name_information: t.string,
        additional_street_information: t.string,
    }),
]);
export type ApiAddress = t.TypeOf<typeof AddressCodec>;
