import camelCase from 'lodash/camelCase';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Vehicle, VehicleConditionType } from '../common.types';
import {
    FormattedDimensions,
    FormattedFuelType,
    FormattedModelCategory,
    FormattedVehicleType,
    FormattedWeight,
} from './FormattedVehicleData';

export type ShipmentTableData = {
    messageId: string;
    value: string | undefined | ReactNode;
};

// TODO: add more translations here or map with other
const getFormattedMessageForDeliveryCondition = (vehicleConditionType: VehicleConditionType) => {
    return (
        <FormattedMessage
            id={`outboundPortal.transportAssignments.incident.SelectTypeOption.${camelCase(vehicleConditionType)}`}
        />
    );
};

const getFormattedPriority = (hasPriority: boolean | undefined): JSX.Element | undefined => {
    if (hasPriority === undefined) {
        return undefined;
    }
    return hasPriority ? (
        <FormattedMessage id="outboundOrderBook.common.yes" />
    ) : (
        <FormattedMessage id="outboundOrderBook.common.no" />
    );
};

export const getShipmentTableData = (
    vehicle: Vehicle,
    serviceKey: string | undefined,
    displayServiceKey: boolean,
): ShipmentTableData[] => {
    const beforeServiceKey = [
        {
            messageId: 'outboundOrderBook.common.sidebar.details.vehicle.model.modelGroup',
            value: vehicle.model.modelGroup,
        },
        {
            messageId: 'outboundOrderBook.common.sidebar.details.vehicle.dimensions',
            value: <FormattedDimensions dimensions={vehicle.dimensions} />,
        },
        {
            messageId: 'outboundOrderBook.common.sidebar.details.vehicle.weight',
            value: <FormattedWeight weight={vehicle.weight} />,
        },
        {
            messageId: 'outboundOrderBook.common.sidebar.details.vehicle.colour',
            value: vehicle.colour,
        },
        {
            messageId: 'outboundOrderBook.common.heading.vehicleType',
            value: <FormattedVehicleType vehicleType={vehicle.vehicleType} />,
        },
        {
            messageId: 'outboundOrderBook.common.sidebar.details.vehicle.licensePlate',
            value: vehicle.licensePlate,
        },
    ];

    const afterServiceKey = [
        {
            messageId: 'outboundPortal.transportAssignments.sidebar.shipment.details.vehicle.deliveryCondition',
            value:
                vehicle.deliveryConditions != null ? (
                    <span className="text-bold text-color-warning">
                        {getFormattedMessageForDeliveryCondition(vehicle.deliveryConditions)}
                    </span>
                ) : undefined,
        },
        {
            messageId: 'outboundOrderBook.common.sidebar.details.vehicle.model.vehicleCategory',
            value: <FormattedModelCategory category={vehicle.model.category} />,
        },
        {
            messageId: 'outboundOrderBook.common.heading.fuelType',
            value: <FormattedFuelType fuelType={vehicle.model.fuelType} />,
        },
        {
            messageId: 'outboundPortal.transportAssignments.sidebar.shipment.details.vehicle.expressTransport',
            value: getFormattedPriority(vehicle.hasPriority),
        },
    ];

    const specialInstructionValues =
        vehicle.specialInstructions.length === 0 ? [undefined] : vehicle.specialInstructions;
    const specialInstructions = specialInstructionValues.map((specialInstruction) => ({
        messageId: 'outboundPortal.transportAssignments.sidebar.shipment.details.vehicle.specialInstructions',
        value: specialInstruction,
    }));

    if (displayServiceKey) {
        return [
            ...beforeServiceKey,
            {
                messageId: 'outboundPortal.transportAssignments.sidebar.shipment.details.serviceKey',
                value: serviceKey,
            },
            ...afterServiceKey,
            ...specialInstructions,
        ];
    } else {
        return [...beforeServiceKey, ...afterServiceKey, ...specialInstructions];
    }
};
