import { ColumnDefinition } from '../../../sharedComponents/table/SortedTableHeader';

export enum DepartedVehiclesTableColumn {
    VIN = 'vin',
    LOCATION_ID = 'locationId',
    LOCATION_CITY = 'locationCity',
    MANUFACTURER = 'manufacturer',
    MODEL = 'model',
    DISPATCH_TYPE = 'dispatchType',
    FUEL_TYPE = 'fuelType',
    MODEL_VERSION = 'modelVersion',
    ARRIVAL_DATE = 'arrivalDate',
    ARRIVAL_FREIGHT_FORWARDER = 'arrivalFreightForwarder',
    DEPARTURE_DATE = 'departureDate',
    DEPARTURE_FREIGHT_FORWARDER = 'departureFreightForwarder',
}

export const departedVehiclesColumnDefinition: ColumnDefinition<DepartedVehiclesTableColumn>[] = [
    {
        column: DepartedVehiclesTableColumn.VIN,
        labelId: 'outboundOrderBook.common.heading.vin',
        sortable: false,
    },
    {
        column: DepartedVehiclesTableColumn.LOCATION_ID,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.locationId',
        sortable: true,
    },
    {
        column: DepartedVehiclesTableColumn.LOCATION_CITY,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.location',
        sortable: false,
    },
    {
        column: DepartedVehiclesTableColumn.MANUFACTURER,
        labelId: 'outboundOrderBook.common.heading.manufacturer',
        sortable: false,
    },
    {
        column: DepartedVehiclesTableColumn.MODEL,
        labelId: 'outboundOrderBook.common.heading.model',
        sortable: false,
    },
    {
        column: DepartedVehiclesTableColumn.DISPATCH_TYPE,
        labelId: 'outboundOrderBook.common.heading.vehicleType',
        sortable: false,
    },
    {
        column: DepartedVehiclesTableColumn.FUEL_TYPE,
        labelId: 'outboundOrderBook.common.heading.fuelType',
        sortable: false,
    },
    {
        column: DepartedVehiclesTableColumn.MODEL_VERSION,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.modelVersion',
        sortable: false,
    },
    {
        column: DepartedVehiclesTableColumn.ARRIVAL_DATE,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.arrivalDate',
        sortable: true,
    },
    {
        column: DepartedVehiclesTableColumn.ARRIVAL_FREIGHT_FORWARDER,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.arrivalCarrier',
        sortable: false,
    },
    {
        column: DepartedVehiclesTableColumn.DEPARTURE_DATE,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.departureDate',
        sortable: true,
    },
    {
        column: DepartedVehiclesTableColumn.DEPARTURE_FREIGHT_FORWARDER,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.departureCarrier',
        sortable: false,
    },
];
