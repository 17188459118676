import TableToolbar from '@rio-cloud/rio-uikit/lib/es/TableToolbar';
import { ServiceOrderStatusFilter } from './ServiceOrderStatusFilter';
import TableViewToggles from '@rio-cloud/rio-uikit/lib/es/TableViewToggles';
import { ServiceOrderToolbarSearch } from './ServiceOrderToolbarSearch';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/hooks';
import { getTableViewType, viewSlice } from '../../../reducers/viewSlice';
import { ServiceOrderCategoriesSelection } from './ServiceOrderCategoriesSelection';

export const ServiceOrderTableToolbar = () => {
    const viewType = useAppSelector(getTableViewType);
    const dispatch = useAppDispatch();

    const handleViewTypeChange = (newViewType: TableViewToggles) =>
        dispatch(viewSlice.actions.setTableViewType(newViewType));

    return (
        <TableToolbar>
            <div className="table-toolbar-container">
                <div className="table-toolbar-group-left">
                    <div className="table-toolbar-column">
                        <div className="btn-toolbar table-btn-toolbar">
                            <ServiceOrderCategoriesSelection />
                        </div>
                    </div>
                    <div className="table-toolbar-column">
                        <div className="min-width-200">
                            <ServiceOrderStatusFilter />
                        </div>
                    </div>
                </div>
                <div className="table-toolbar-group-right">
                    <div className="table-toolbar-column">
                        <ServiceOrderToolbarSearch />
                    </div>
                    <div className="table-toolbar-column">
                        <TableViewToggles initialViewType={viewType} onViewTypeChange={handleViewTypeChange} />
                    </div>
                </div>
            </div>
        </TableToolbar>
    );
};
