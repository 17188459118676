import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { prepareHeaders } from './utils';
import { Activation, TermsAndConditionsAgreement } from '../activation/activation.types';
import { decodeResponse, validateResponse } from './responseUtil';
import { ActivationStatusesCodec, TermsAndConditionsAgreementCodec } from './types/activationsApi.types';
import { mapTermsAndConditionsAgreement, mapToActivationStatuses } from './mappers/activationMapper';
import { RegistrationInputs } from '../activation/registration.types';
import { mapToApiActivationRequest } from './mappers/outgoingRequestMappers';
import {
    ACTIVATION_REQUESTS_PATH,
    ACTIVATION_STATUSES_PATH,
    TERMS_AND_CONDITIONS_AGREEMENT_PATH,
} from './transportOrderApi';

const supplierActivationTag = 'supplierActivation';
const termsAndConditionsTag = 'TermsAndConditions';

export const activationApi = createApi({
    reducerPath: 'activationApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.FINISHED_VEHICLES_SERVICE,
        prepareHeaders,
    }),
    tagTypes: [termsAndConditionsTag, supplierActivationTag],
    endpoints: (builder) => ({
        getTermsAndConditionsAgreements: builder.query<TermsAndConditionsAgreement, void>({
            query: () => ({
                url: TERMS_AND_CONDITIONS_AGREEMENT_PATH,
                validateStatus: validateResponse(TermsAndConditionsAgreementCodec),
            }),
            transformResponse: decodeResponse(TermsAndConditionsAgreementCodec, mapTermsAndConditionsAgreement),
            providesTags: [termsAndConditionsTag],
        }),
        postTermsAndConditionsAgreement: builder.mutation<void, void>({
            query: () => ({
                url: TERMS_AND_CONDITIONS_AGREEMENT_PATH,
                method: 'POST',
            }),
            invalidatesTags: [termsAndConditionsTag],
            transformResponse: () => {
                window.location.reload();
            },
        }),
        getActivationStatuses: builder.query<Activation[], void>({
            query: () => ({
                url: ACTIVATION_STATUSES_PATH,
                validateStatus: validateResponse(ActivationStatusesCodec),
            }),
            transformResponse: decodeResponse(ActivationStatusesCodec, mapToActivationStatuses),
            providesTags: [supplierActivationTag],
        }),
        postActivationRequest: builder.mutation<void, RegistrationInputs>({
            query: (registrationInputs) => ({
                url: ACTIVATION_REQUESTS_PATH,
                method: 'POST',
                body: mapToApiActivationRequest(registrationInputs),
            }),
            invalidatesTags: [supplierActivationTag],
        }),
    }),
});

export const {
    useGetActivationStatusesQuery,
    useGetTermsAndConditionsAgreementsQuery,
    usePostTermsAndConditionsAgreementMutation,
    usePostActivationRequestMutation,
} = activationApi;
