import { useFeatureFlag, User } from 'configcat-react';
import { useAppSelector } from '../setup/hooks';
import { getUserAccount } from '../login/loginSlice';

export const FeatureToggles = {
    TRACKING: 'tracking',
    LSP_ADMINISTRATION: 'lspAdministration',
    COMPOUND_MANAGEMENT: 'compoundManagement',
    TRAILER_ID: 'trailerId',
} as const;

export type FeatureTogglesType = (typeof FeatureToggles)[keyof typeof FeatureToggles];

type DefaultValue = string | boolean | number;
type DefaultValueTypeOf<T> = T extends boolean ? boolean : T extends number ? number : T extends string ? string : T;

export const useFeatureToggle = <V extends DefaultValue>(
    featureToggleId: FeatureTogglesType,
    defaultValue: V = false as V,
): { loading: boolean; value: DefaultValueTypeOf<V> } => {
    const account = useAppSelector(getUserAccount);
    const userObject = new User(account ?? 'NO_IDENTIFIER');
    const result = useFeatureFlag(featureToggleId, defaultValue, userObject);

    if (import.meta.env.MODE === 'development') {
        return { value: true as DefaultValueTypeOf<V>, loading: false };
    } else {
        return result;
    }
};
