import { getFirstMatchingCondition } from '../../../../utils';
import { ServiceOrderStatus } from '../ServiceOrders.types';
import { AlertConfig } from '../../sharedComponents/common.types';

export const cancelledRowConfig: AlertConfig = {
    highlight: 'danger',
    rowIcon: 'rioglyph-error-sign text-color-danger',
    hintMessageKey: 'outboundPortal.serviceOrders.alerts.cancelled',
};

export const unacknowledgedExternalChangesRowConfig: AlertConfig = {
    highlight: 'warning',
    rowIcon: 'rioglyph-exclamation-sign text-color-warning',
    hintMessageKey: 'outboundPortal.serviceOrders.alerts.unacknowledgedChanges',
};

export const getRowAlertConfiguration = (status: ServiceOrderStatus, hasUnacknowledgedExternalChanges: boolean) => {
    return getFirstMatchingCondition(
        [status === ServiceOrderStatus.CANCELLED, cancelledRowConfig],
        [hasUnacknowledgedExternalChanges, unacknowledgedExternalChangesRowConfig],
    );
};
