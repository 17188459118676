import {
    ColumnDefinition,
    ColumnTableHeader,
    getSortDir,
    SortColumnTableHeader,
} from '../../sharedComponents/table/SortedTableHeader';
import { useIntl } from 'react-intl';
import { transportAssignmentColumnDefinition, TransportAssignmentTableColumn } from './TransportAssignmentTableColumns';
import { FeatureToggles, useFeatureToggle } from '../../../../configuration/featureToggle/toggleHook';
import {
    getSelectedCategory,
    getSortingForCategory,
    transportAssignmentsSlice,
} from '../../reducers/transportAssignmentsSlice';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import OnboardingTip from '@rio-cloud/rio-uikit/lib/es/OnboardingTip';
import useLocalStorage from '@rio-cloud/rio-uikit/lib/es/useLocalStorage';
import { TableCategory } from './toolbar/CategoriesSelection';

export const TransportAssignmentTableHeader = () => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const activeTableCategory = useAppSelector(getSelectedCategory);
    const sorting = useAppSelector(getSortingForCategory(activeTableCategory));
    const trackingFeatureEnabled = useFeatureToggle(FeatureToggles.TRACKING, false).value;

    const [showOnboarding1, setShowOnboarding1] = useLocalStorage('onboarding.date.1', 'true');
    const [showOnboarding2, setShowOnboarding2] = useLocalStorage('onboarding.date.2', 'true');

    const getSortingHandler = (col: TransportAssignmentTableColumn) =>
        dispatch(
            transportAssignmentsSlice.actions.setSortingForCategory({
                category: activeTableCategory,
                sort: { column: col, dir: getSortDir(col, sorting) },
            }),
        );
    const isTrueOnboarding1 = () => showOnboarding1 === 'true';
    const isTrueOnboarding2 = () => !isTrueOnboarding1() && showOnboarding2 === 'true';

    const renderHeaderColumn = (def: ColumnDefinition<TransportAssignmentTableColumn>) => {
        switch (def.column) {
            case TransportAssignmentTableColumn.MODEL:
            case TransportAssignmentTableColumn.MODEL_GROUP:
            case TransportAssignmentTableColumn.MEANS_OF_TRANSPORT:
                return <ColumnTableHeader key={def.column} labelId={def.labelId} />;
            case TransportAssignmentTableColumn.HAS_CHANGES:
                return <th key={def.column} />;
            case TransportAssignmentTableColumn.EXTERNAL_ID:
                return (
                    <ColumnTableHeader
                        key={def.column}
                        additionalClassNames="white-space-normal"
                        labelId={def.labelId}
                    />
                );
            case TransportAssignmentTableColumn.STATUS:
                return (
                    <SortColumnTableHeader
                        key={def.column}
                        col={TransportAssignmentTableColumn.STATUS}
                        labelId={def.labelId}
                        childClassNames={trackingFeatureEnabled ? 'margin-left-25' : ''}
                        sortingHandler={getSortingHandler}
                        currentSort={sorting}
                    />
                );
            case TransportAssignmentTableColumn.FROM:
            case TransportAssignmentTableColumn.TO:
                return (
                    <SortColumnTableHeader
                        key={def.column}
                        col={def.column}
                        labelId={def.labelId}
                        sortingHandler={getSortingHandler}
                        currentSort={sorting}
                    />
                );
            case TransportAssignmentTableColumn.REQUESTED_PICKUP:
            case TransportAssignmentTableColumn.REQUESTED_DELIVERY:
                return (
                    <OnboardingTip
                        key={def.column}
                        className="z-index-10"
                        show={
                            def.column === TransportAssignmentTableColumn.REQUESTED_PICKUP
                                ? isTrueOnboarding1()
                                : isTrueOnboarding2()
                        }
                        placement="bottom-end"
                        width={250}
                        title={intl.formatMessage({ id: 'outboundOrderBook.common.onboarding.date.title' })}
                        content={intl.formatMessage({ id: 'outboundPortal.notification.onboarding.date.content' })}
                        onHide={
                            def.column === TransportAssignmentTableColumn.REQUESTED_PICKUP
                                ? () => {
                                      setShowOnboarding1('false');
                                  }
                                : () => {
                                      setShowOnboarding2('false');
                                  }
                        }
                    >
                        <SortColumnTableHeader
                            col={def.column}
                            labelId={def.labelId}
                            sortingHandler={getSortingHandler}
                            currentSort={sorting}
                        />
                    </OnboardingTip>
                );
            case TransportAssignmentTableColumn.ACTION:
                return activeTableCategory !== TableCategory.ARCHIVE ? (
                    <ColumnTableHeader key={def.column} labelId={def.labelId} />
                ) : null;
        }
    };

    return (
        <thead>
            <tr>{transportAssignmentColumnDefinition.map((def) => renderHeaderColumn(def))}</tr>
        </thead>
    );
};
