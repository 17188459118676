import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { CompoundTransportOrderSummary } from './compoundTransportOrder.types';

export const TRANSPORT_INCOMING_SUMMARY_TEST_ID = 'summary';

type SummaryCounterProps = {
    glyphName: string;
    count: number;
};

const SummaryCounter: FunctionComponent<SummaryCounterProps> = (props: SummaryCounterProps) => (
    <div className="display-flex align-items-center margin-left-10 margin-right-5">
        <span className="label label-condensed label-primary label-filled">{props.count}</span>
        <span className={`rioglyph ${props.glyphName} text-size-20 margin-left-5`} />
    </div>
);

export type IncomingVehiclesSummaryProps = {
    summary: CompoundTransportOrderSummary;
    className?: string;
};

export const TransportIncomingSummary: FunctionComponent<IncomingVehiclesSummaryProps> = ({
    summary,
    className,
}: IncomingVehiclesSummaryProps) => {
    const summaryClassNames = classNames('callout', 'callout-primary', 'display-flex', 'align-items-center', className);

    return (
        <div data-testid={TRANSPORT_INCOMING_SUMMARY_TEST_ID} className={summaryClassNames}>
            <FormattedMessage
                id="outboundOrderBook.incomingCompoundTransportOrder.summary"
                values={{
                    strong: (chunks) => <strong>{chunks}</strong>,
                    cars: <SummaryCounter glyphName="rioglyph-car" count={summary.numberOfVehicles} />,
                    trucks: <SummaryCounter glyphName="rioglyph-truck" count={summary.numberOfTransports} />,
                }}
            />
        </div>
    );
};
