import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isUserSessionExpired } from '../../../configuration/login/loginSlice';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';
import { FilterableApiStatus, useGetTransportAssignmentsQuery } from '../api/transportOrderApi';
import { handleQueryError } from '../notifications/ErrorNotification';
import {
    getFilters,
    getSelectedCategory,
    getSelectedTransportAssignment,
    getSortingForCategory,
    getTransportAssignmentSearchTerm,
    transportAssignmentsSlice,
} from '../reducers/transportAssignmentsSlice';
import { LoadMoreButton } from '../sharedComponents/table/LoadMoreButton';
import { useSetUserTriggeredFetching } from '../sharedComponents/userTriggeredFetchingHook';
import { TransportAssignmentsCardsSorting } from './table/TransportAssignmentsCardsSorting';
import { TransportAssignmentsTable } from './table/TransportAssignmentsTable';
import { TableCategory } from './table/toolbar/CategoriesSelection';
import { TransportAssignmentsTableToolbar } from './table/toolbar/TransportAssignmentsTableToolbar';
import { TransportAssignmentStatus } from './transportAssignment.types';

export const DEFAULT_TRANSPORT_ORDERS_LIMIT_SIZE = 100;

export const TransportAssignments = () => {
    const isSessionExpired = useAppSelector(isUserSessionExpired);
    const filters = useAppSelector(getFilters);
    const category = useAppSelector(getSelectedCategory);
    const sorting = useAppSelector(getSortingForCategory(category));
    const searchValue = useAppSelector(getTransportAssignmentSearchTerm);
    const selectedTransportAssignment = useAppSelector(getSelectedTransportAssignment);
    const dispatch = useAppDispatch();

    const [limit, setLimit] = useState(DEFAULT_TRANSPORT_ORDERS_LIMIT_SIZE);

    const {
        data: transportAssignmentsPage,
        isLoading,
        isFetching,
        isError,
        error,
    } = useGetTransportAssignmentsQuery(
        {
            archived: category === TableCategory.ARCHIVE,
            sortBy: sorting,
            statuses: setStatusFiltersForQuery(filters.statusFilter, category),
            requestedPickUpFrom: filters.modalFilters?.startDateFilter,
            requestedPickUpTo: filters.modalFilters?.endDateFilter,
            loadingCity: filters.modalFilters?.pickUpCityFilter,
            unloadingCity: filters.modalFilters?.deliveryCityFilter,
            search: searchValue.length === 0 ? undefined : searchValue,
            limit,
        },
        {
            pollingInterval: isSessionExpired ? undefined : 60000,
        },
    );

    useSetUserTriggeredFetching({
        isFetching,
        depsWhichTriggerUserFetching: [category, sorting, searchValue, filters],
    });

    const transportAssignments = transportAssignmentsPage?.items;
    if (selectedTransportAssignment !== undefined) {
        if (!transportAssignments?.find((it) => it.id === selectedTransportAssignment.id)) {
            dispatch(transportAssignmentsSlice.actions.deselectTransportAssignment());
        }
    }

    if (isError) {
        handleQueryError(error);
    }

    if (isLoading) {
        return (
            <div data-testid="spinner">
                <Spinner />
            </div>
        );
    }

    if (transportAssignmentsPage === undefined || transportAssignments === undefined) {
        return (
            <ErrorState
                headline={<FormattedMessage id="intl-msg:common-message.error.generic.headline" />}
                message={<FormattedMessage id="intl-msg:common-message.error.generic.message" />}
            />
        );
    }

    return (
        <>
            <TransportAssignmentsTableToolbar assignmentsPerStatus={transportAssignmentsPage.statusCount} />
            <TransportAssignmentsCardsSorting category={category} />
            <TransportAssignmentsTable transportAssignments={transportAssignments} />
            {transportAssignments.length > 0 && (
                <LoadMoreButton
                    onLoadMore={() => setLimit(limit + DEFAULT_TRANSPORT_ORDERS_LIMIT_SIZE)}
                    hasMoreToLoad={transportAssignmentsPage.hasMore}
                />
            )}
        </>
    );
};

const setStatusFiltersForQuery = (
    uiStatuses: TransportAssignmentStatus[],
    category: TableCategory,
): FilterableApiStatus[] => {
    switch (category) {
        case TableCategory.INBOX:
            if (uiStatuses.length > 0) {
                return uiStatuses.map(mapUIStatusToApiStatus);
            } else {
                return [
                    FilterableApiStatus.CANCELLATION,
                    FilterableApiStatus.CONFIRMED,
                    FilterableApiStatus.UNCONFIRMED,
                ];
            }
        case TableCategory.EN_ROUTE:
            return [FilterableApiStatus.LOADED];
        case TableCategory.FINISHED:
            return [FilterableApiStatus.UNLOADED];
        case TableCategory.ARCHIVE:
            return [];
    }
};

const mapUIStatusToApiStatus = (uiStatus: TransportAssignmentStatus): FilterableApiStatus => {
    switch (uiStatus) {
        case TransportAssignmentStatus.CANCELLATION:
            return FilterableApiStatus.CANCELLATION;
        case TransportAssignmentStatus.COMPLETE_CONFIRMED:
        case TransportAssignmentStatus.INCOMPLETE_CONFIRMED:
            return FilterableApiStatus.CONFIRMED;
        case TransportAssignmentStatus.UNCONFIRMED:
            return FilterableApiStatus.UNCONFIRMED;
        case TransportAssignmentStatus.LOADED:
            return FilterableApiStatus.LOADED;
        case TransportAssignmentStatus.UNLOADED:
            return FilterableApiStatus.UNLOADED;
    }
};
