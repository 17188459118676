import { serviceOrderColumnDefinition, ServiceOrdersTableColumns } from './ServiceOrdersTableColumns';
import {
    ColumnDefinition,
    ColumnTableHeader,
    getSortDir,
    SortColumnTableHeader,
} from '../../sharedComponents/table/SortedTableHeader';
import { getServiceOrderCategory, getServiceOrderSort, serviceOrdersSlice } from '../../reducers/serviceOrdersSlice';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import { ServiceOrderCategory } from '../ServiceOrders.types';

export const ServiceOrderTableHeader = () => {
    const dispatch = useAppDispatch();
    const sorting = useAppSelector(getServiceOrderSort);
    const activeTableCategory = useAppSelector(getServiceOrderCategory);

    const getSortingHandler = (col: ServiceOrdersTableColumns) =>
        dispatch(
            serviceOrdersSlice.actions.setSortingForActiveCategory({
                sort: { column: col, dir: getSortDir(col, sorting) },
            }),
        );
    const renderHeader = (def: ColumnDefinition<ServiceOrdersTableColumns>) => {
        switch (def.column) {
            case ServiceOrdersTableColumns.HIGHLIGHT:
            case ServiceOrdersTableColumns.EXTERNAL_ID:
            case ServiceOrdersTableColumns.VIN:
            case ServiceOrdersTableColumns.MANUFACTURER:
            case ServiceOrdersTableColumns.MODEL:
            case ServiceOrdersTableColumns.SERVICE_NAME:
                return <ColumnTableHeader key={def.column} labelId={def.labelId} />;
            case ServiceOrdersTableColumns.STATUS:
            case ServiceOrdersTableColumns.EXECUTION_DATE:
            case ServiceOrdersTableColumns.SERVICE_CODE:
                return (
                    <SortColumnTableHeader
                        key={def.column}
                        col={def.column}
                        labelId={def.labelId}
                        sortingHandler={getSortingHandler}
                        currentSort={sorting}
                    />
                );
            case ServiceOrdersTableColumns.ACTION_BUTTON:
                return activeTableCategory !== ServiceOrderCategory.ARCHIVE ? (
                    <ColumnTableHeader key={def.column} labelId={def.labelId} />
                ) : null;
        }
    };
    return (
        <thead>
            <tr>{serviceOrderColumnDefinition.map((def) => renderHeader(def))}</tr>
        </thead>
    );
};
