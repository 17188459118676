import { Interval, isAfter, isBefore, isWithinInterval } from 'date-fns';
import { dateMinusSeconds, isDateStringEmpty, isValidDate } from '../../../sharedComponents/dateHelper';
import { GRACE_PERIOD_IN_SECONDS_COMPLETION_DATE } from '../../../sharedComponents/commonConfig';
import { ValidationResult } from './confirmationForm.types';

export const PICKUP_DATE_VALIDATION_MESSAGES = {
    invalidDate: 'outboundPortal.common.invalidDate',
    beforeReceivedAt: 'outboundPortal.transportAssignments.sidebar.dateBeforeReceivedError',
    notWithinRequestedTimeframe:
        'outboundPortal.transportAssignments.sidebar.assignmentInformation.confirmedPickUpDate.isNotWithinRequestedPickupTimeframe',
    missing: 'outboundPortal.transportAssignments.sidebar.assignmentInformation.confirmedPickUpDate.feedbackWarning',
};

export const DELIVERY_DATE_VALIDATION_MESSAGES = {
    invalidDate: 'outboundPortal.common.invalidDate',
    beforeReceivedAt: 'outboundPortal.transportAssignments.sidebar.dateBeforeReceivedError',
    afterDeliveryDate:
        'outboundPortal.transportAssignments.sidebar.assignmentInformation.confirmedDeliveryDate.isAfterRequestedDeliveryDate',
    missing: 'outboundPortal.transportAssignments.sidebar.assignmentInformation.confirmedDeliveryDate.feedbackWarning',
};

export const validatePickUpDate = (
    assignment: {
        readonly receivedAt: Date;
        readonly requestedPickUpDate: Interval;
    },
    confirmedPickUpDate: Date | string | undefined,
): ValidationResult => {
    if (isDateStringEmpty(confirmedPickUpDate)) {
        return { error: undefined, warning: undefined };
    }
    if (!isValidDate(confirmedPickUpDate)) {
        return { error: PICKUP_DATE_VALIDATION_MESSAGES.invalidDate, warning: undefined };
    }

    if (
        isAfter(
            dateMinusSeconds(assignment.receivedAt, GRACE_PERIOD_IN_SECONDS_COMPLETION_DATE),
            confirmedPickUpDate as Date,
        )
    ) {
        return { error: PICKUP_DATE_VALIDATION_MESSAGES.beforeReceivedAt, warning: undefined };
    }
    if (!isWithinInterval(confirmedPickUpDate as Date, assignment.requestedPickUpDate)) {
        return { error: undefined, warning: PICKUP_DATE_VALIDATION_MESSAGES.notWithinRequestedTimeframe };
    }

    return { error: undefined, warning: undefined };
};

export const validateIncompletePickUpDate = (
    enabled: boolean,
    confirmedPickUpDate: Date | string | undefined,
): ValidationResult =>
    enabled && isDateStringEmpty(confirmedPickUpDate)
        ? { warning: PICKUP_DATE_VALIDATION_MESSAGES.missing, error: undefined }
        : { error: undefined, warning: undefined };

export const validateDeliveryDate = (
    assignment: {
        readonly receivedAt: Date;
        readonly requestedDeliveryDate: Date | undefined;
    },
    confirmedDeliveryDate: Date | string | undefined,
): ValidationResult => {
    if (isDateStringEmpty(confirmedDeliveryDate)) {
        return { error: undefined, warning: undefined };
    }
    if (!isValidDate(confirmedDeliveryDate)) {
        return { error: DELIVERY_DATE_VALIDATION_MESSAGES.invalidDate, warning: undefined };
    }
    if (
        isAfter(dateMinusSeconds(assignment.receivedAt, GRACE_PERIOD_IN_SECONDS_COMPLETION_DATE), confirmedDeliveryDate)
    ) {
        return { error: DELIVERY_DATE_VALIDATION_MESSAGES.beforeReceivedAt, warning: undefined };
    }

    if (
        assignment.requestedDeliveryDate !== undefined &&
        isAfter(confirmedDeliveryDate, assignment.requestedDeliveryDate)
    ) {
        return { error: undefined, warning: DELIVERY_DATE_VALIDATION_MESSAGES.afterDeliveryDate };
    }

    return { error: undefined, warning: undefined };
};

export const validateIncompleteDeliveryDate = (
    enabled: boolean,
    confirmedDeliveryDate: Date | string | undefined,
): ValidationResult =>
    enabled && isDateStringEmpty(confirmedDeliveryDate)
        ? { warning: DELIVERY_DATE_VALIDATION_MESSAGES.missing, error: undefined }
        : { error: undefined, warning: undefined };

export const getExecutionStatusDateErrorMessage = (
    date: Date | string | undefined,
    transportAssignmentReceivedAt: Date,
    loadingDate?: Date | string | undefined,
): string | undefined => {
    const isInTheFuture = isValidDate(date) && date > new Date();
    const statusDateBeforeReceived =
        isValidDate(date) &&
        isAfter(dateMinusSeconds(transportAssignmentReceivedAt, GRACE_PERIOD_IN_SECONDS_COMPLETION_DATE), date);
    const isBeforeLoadingDate = isValidDate(date) && isValidDate(loadingDate) && isBefore(date, loadingDate);

    if (isInTheFuture) return 'outboundPortal.transportAssignments.sidebar.executionStatus.inTheFuture';
    if (statusDateBeforeReceived) return 'outboundPortal.transportAssignments.sidebar.dateBeforeReceivedError';
    if (isBeforeLoadingDate)
        return 'outboundPortal.transportAssignments.sidebar.executionStatus.unloadingBeforeLoadingDate';
    if (date === '' || !isValidDate(date)) return 'outboundPortal.common.invalidDate';
    return undefined;
};
