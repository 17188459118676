import { TransportCapacityOrder } from '../transportAssignment.types';
import { TransportCapacityOrderTransportDetails } from './details/TransportCapacityOrderTransportDetails';
import { DetailSelector, SelectableDetails } from './TransportAssignmentSidebarBodyContent';
import { useState } from 'react';
import { ShipmentDetailAddress } from './details/shipmentDetails/ShipmentDetails';
import { FormattedMessage } from 'react-intl';

const TransportCapacityOrderShipmentDetails = (props: { transportCapacityOrder: TransportCapacityOrder }) => {
    const address = props.transportCapacityOrder.unloading.addressAndContact?.address;
    const generalInformation = props.transportCapacityOrder.generalInformation;

    return (
        <>
            <ShipmentDetailAddress address={address} />
            <div className="panel panel-default margin-bottom-0">
                <div className="panel-body">
                    <span className="text-bold">
                        <FormattedMessage id="outboundPortal.transportAssignments.sidebar.shipment.details.generalInformation" />
                    </span>
                    <div>
                        {generalInformation ?? (
                            <span className="text-color-gray">
                                <FormattedMessage id="outboundPortal.transportAssignments.sidebar.shipment.details.generalInformation.notSpecified" />
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

const TransportCapacityOrderSidebarTabContent = (props: {
    capacityOrder: TransportCapacityOrder;
    selectedDetail: SelectableDetails;
}) => {
    switch (props.selectedDetail) {
        case SelectableDetails.TRANSPORT:
            return <TransportCapacityOrderTransportDetails transportCapacityOrder={props.capacityOrder} />;
        case SelectableDetails.SHIPMENT:
            return <TransportCapacityOrderShipmentDetails transportCapacityOrder={props.capacityOrder} />;
    }
};

export const TransportCapacityOrderSidebarBodyContent = (props: { transportCapacityOrder: TransportCapacityOrder }) => {
    const hasShipmentDetails = props.transportCapacityOrder.generalInformation !== undefined;
    const [selectedDetail, setSelectedDetail] = useState(SelectableDetails.TRANSPORT);

    return (
        <div className="padding-20">
            <div className="nav nav-pills nav-pills-filled nav-justified margin-bottom-15">
                <DetailSelector
                    detail={SelectableDetails.TRANSPORT}
                    isSelected={selectedDetail === SelectableDetails.TRANSPORT}
                    onClick={setSelectedDetail}
                    isEnabled
                />
                <DetailSelector
                    detail={SelectableDetails.SHIPMENT}
                    isSelected={selectedDetail === SelectableDetails.SHIPMENT}
                    onClick={setSelectedDetail}
                    isEnabled={hasShipmentDetails}
                />
            </div>
            <TransportCapacityOrderSidebarTabContent
                capacityOrder={props.transportCapacityOrder}
                selectedDetail={selectedDetail}
            />
        </div>
    );
};
