export enum ServiceType {
    X01 = 'X01',
    X02 = 'X02',
    X03 = 'X03',
    X04 = 'X04',
    X05 = 'X05',
    X06 = 'X06',
    X07 = 'X07',
    X08 = 'X08',
    X09 = 'X09',
    X10 = 'X10',
    X11 = 'X11',
    X12 = 'X12',
    X13 = 'X13',
    X14 = 'X14',
    X15 = 'X15',
    X16 = 'X16',
    X17 = 'X17',
    X18 = 'X18',
    X19 = 'X19',
    X20 = 'X20',
    X21 = 'X21',
    X22 = 'X22',
    X23 = 'X23',
    X24 = 'X24',
    X25 = 'X25',
    X26 = 'X26',
    X27 = 'X27',
    X99 = 'X99',
}
