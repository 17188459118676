import Sidebar from '@rio-cloud/rio-uikit/lib/es/Sidebar';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import {
    usePostAcknowledgementOfExternalTransportOrderChangesMutation,
    usePostArchiveTransportAssignmentMutation,
} from '../../api/transportOrderApi';
import {
    TransportAssignmentStatus,
    TransportAssignmentType,
    TransportOrder,
    TransportOrderBundle,
} from '../transportAssignment.types';
import { TransportAssignmentSidebarBodyContent } from './TransportAssignmentSidebarBodyContent';
import { getSelectedTransportAssignment, transportAssignmentsSlice } from '../../reducers/transportAssignmentsSlice';
import { useEffect } from 'react';
import { handleQueryError } from '../../notifications/ErrorNotification';
import { showSuccessNotification } from '../../notifications/SuccessNotification';
import { SidebarExpander } from './SidebarExpander';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { getTransportAssignmentIds } from '../TransportAssignmentUtils';
import classNames from 'classnames';
import { SidebarHeader } from './SidebarHeader';
import { TransportOrderConfirmationForm } from './TransportOrderConfirmationForm';
import { runInBackground, runInBackgroundCallback } from '../../../../configuration/setup/backgroundActions';

export const TransportAssignmentSidebar = () => {
    const selected = useAppSelector(getSelectedTransportAssignment);
    const transportAssignment =
        selected?.type === TransportAssignmentType.TRANSPORT_ORDER ||
        selected?.type === TransportAssignmentType.TRANSPORT_ORDER_BUNDLE
            ? selected
            : undefined;
    return (
        <TransportAssignmentSidebarInternal transportAssignment={transportAssignment} key={transportAssignment?.id} />
    );
};
const TransportAssignmentSidebarInternal = ({
    transportAssignment,
}: {
    transportAssignment: TransportOrder | TransportOrderBundle | undefined;
}) => {
    const [acknowledgeChanges] = usePostAcknowledgementOfExternalTransportOrderChangesMutation();
    const [archiveTransportAssignment, archiveRequest] = usePostArchiveTransportAssignmentMutation();

    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            if (transportAssignment !== undefined && transportAssignment.hasUnacknowledgedExternalChanges) {
                runInBackground(
                    acknowledgeChanges({
                        transportOrderIds: getTransportAssignmentIds(transportAssignment),
                    }),
                );
            }
        };
    }, [acknowledgeChanges, transportAssignment]);

    const sidebarProperties = {
        showHeaderBorder: true,
        resizable: true,
        position: 'right',
        minWidth: 400,
        maxWidth: 550,
        width: 550,
        fly: true,
    };
    const isLoading = archiveRequest.isLoading;
    if (transportAssignment === undefined) {
        return <Sidebar {...sidebarProperties} closed />;
    }

    const hasArchiveButton =
        !transportAssignment.isArchived &&
        (transportAssignment.status === TransportAssignmentStatus.CANCELLATION || transportAssignment.isFinalized);

    const closeSidebar = () => {
        dispatch(transportAssignmentsSlice.actions.deselectTransportAssignment());
    };

    const handleArchiveTransportAssignment = runInBackgroundCallback(async () => {
        const result = await archiveTransportAssignment({
            transportOrderIds: getTransportAssignmentIds(transportAssignment),
            isArchived: true,
        });
        if (result.hasOwnProperty('error')) {
            handleQueryError((result as { error: FetchBaseQueryError | SerializedError }).error);
        } else {
            showSuccessNotification('outboundPortal.notification.archived.success');
            closeSidebar();
        }
    });

    const title =
        transportAssignment.type === TransportAssignmentType.TRANSPORT_ORDER ? (
            <FormattedMessage id="outboundPortal.transportAssignments.sidebar.header.titleTransportOrder" />
        ) : transportAssignment.type === TransportAssignmentType.TRANSPORT_ORDER_BUNDLE ? (
            <FormattedMessage
                id="outboundPortal.transportAssignments.sidebar.header.titleLoadOrder"
                values={{
                    amount: transportAssignment.numberOfVehicles,
                }}
            />
        ) : (
            <FormattedMessage id="Replace.Me" />
        );

    const footerButton = (
        <div className="display-flex gap-10 flex-row-reverse">
            <button className="btn btn-default" type="button" onClick={closeSidebar}>
                <FormattedMessage id="outboundPortal.close" />
            </button>
            {hasArchiveButton && (
                <button
                    className={classNames('btn btn-danger btn-outline', isLoading ? 'btn-loading' : '')}
                    type="button"
                    disabled={isLoading}
                    onClick={handleArchiveTransportAssignment}
                >
                    <span className="rioglyph rioglyph-folder-closed" />
                    <FormattedMessage id="outboundPortal.transportAssignments.button.archive" />
                </button>
            )}
        </div>
    );

    return (
        <Sidebar
            {...sidebarProperties}
            title={title}
            titleClassName="padding-left-10"
            closed={false}
            onClose={closeSidebar}
            footer={footerButton}
            footerClassName="margin-right-10"
        >
            <SidebarHeader
                transportAssignment={transportAssignment}
                hasUnacknowledgedChanges={transportAssignment.hasUnacknowledgedExternalChanges}
            />
            <SidebarExpander transportAssignment={transportAssignment}>
                <TransportOrderConfirmationForm transportAssignment={transportAssignment} />
            </SidebarExpander>
            <TransportAssignmentSidebarBodyContent transportAssignment={transportAssignment} />
        </Sidebar>
    );
};
