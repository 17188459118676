import { configureStore, StateFromReducersMapObject } from '@reduxjs/toolkit';

import { configReducer } from './configSlice';
import { loginReducer } from '../login/loginSlice';
import { langReducer } from '../lang/langSlice';
import { tokenReducer } from '../tokenHandling/tokenSlice';
import { appReducer } from '../../features/app/appSlice';
import { portalReducer } from '../../features/app/reducers/portalReducer';
import { transportOrderApi } from '../../features/app/api/transportOrderApi';
import { accountsApi } from '../../features/app/api/accountApi';
import { serviceOrderApi } from '../../features/app/api/serviceOrdersApi';
import { assetsApi } from '../../features/app/api/assetsApi';
import { compoundTransportOrderApi } from '../../features/app/api/compoundTransportOrderApi';
import { serviceProviderApi } from '../../features/app/api/serviceProviderApi';
import { compoundManagementApi } from '../../features/app/api/compoundManagementApi';
import { activationApi } from '../../features/app/api/activationApi';

const topLevelReducer = {
    config: configReducer,
    lang: langReducer,
    app: appReducer,
    login: loginReducer,
    tokenHandling: tokenReducer,
    portal: portalReducer,
    // Add the generated reducer as a specific top-level slice
    [transportOrderApi.reducerPath]: transportOrderApi.reducer,
    [accountsApi.reducerPath]: accountsApi.reducer,
    [activationApi.reducerPath]: activationApi.reducer,
    [assetsApi.reducerPath]: assetsApi.reducer,
    [serviceOrderApi.reducerPath]: serviceOrderApi.reducer,
    [compoundTransportOrderApi.reducerPath]: compoundTransportOrderApi.reducer,
    [compoundManagementApi.reducerPath]: compoundManagementApi.reducer,
    [serviceProviderApi.reducerPath]: serviceProviderApi.reducer,
};

export const configureFullStore = (initialState?: RootState) =>
    configureStore({
        reducer: topLevelReducer,
        // Adding the api middleware enables caching, invalidation, polling,
        // and other useful features of `rtk-query`.
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({ serializableCheck: false }).concat(
                transportOrderApi.middleware,
                accountsApi.middleware,
                activationApi.middleware,
                assetsApi.middleware,
                serviceOrderApi.middleware,
                compoundTransportOrderApi.middleware,
                compoundManagementApi.middleware,
                serviceProviderApi.middleware,
            ),
        ...(initialState && { preloadedState: initialState }),
    });

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = StateFromReducersMapObject<typeof topLevelReducer>;
export type RootDispatch = ReturnType<typeof configureFullStore>['dispatch'];
