import * as t from 'io-ts';
import { NonEmptyString } from 'io-ts-types';
import { fromEnum } from '../responseUtil';
import { AddressAndContactCodec } from './transportAssignment/addressAndContactApi.types';

export const VehicleIdCodec = t.partial({
    vin: NonEmptyString,
    production_number: NonEmptyString,
});

export enum ApiCategory {
    CAMPER_VAN = 'camper-van',
    CAR = 'car',
    MOTORCYCLE = 'motorcycle',
    QUAD = 'quad',
    SPECIAL_VEHICLE = 'special-vehicle',
    TRIKE = 'trike',
    TRUCK = 'truck',
    VAN = 'van',
}

export enum ApiFuelType {
    DIESEL = 'diesel',
    DIESEL_ELECTRIC = 'diesel-electric',
    ELECTRIC = 'electric',
    LIQUID_PROPANE_GAS = 'liquid-propane-gas',
    PETROL = 'petrol',
    PETROL_ELECTRIC = 'petrol-electric',
    HYDROGEN = 'hydrogen',
    COMPRESSED_NATURAL_GAS = 'compressed-natural-gas',
    LIQUID_NATURAL_GAS = 'liquid-natural-gas',
}

export const ModelCodec = t.intersection([
    t.type({
        name: t.string,
        model_group: NonEmptyString,
    }),
    t.partial({
        version: t.string,
        category: fromEnum<ApiCategory>('ApiCategory', ApiCategory),
        manufacturer: t.string,
        fuel_type: fromEnum<ApiFuelType>('ApiFuelType', ApiFuelType),
    }),
]);

export const VehicleDimensionsCodec = t.partial({
    length: t.number,
    width: t.number,
    height: t.number,
});

export enum ApiVehicleType {
    NEW = 'new',
    USED = 'used',
    OTHER = 'other',
}

export enum ApiVehicleConditionType {
    NOT_ON_SITE = 'not-on-site',
    CONTACT_UNKNOWN = 'contact-unknown',
    NOT_READY_FOR_PICKUP = 'not-ready-for-pickup',
    NO_KEYS = 'no-keys',
    NOT_READY_TO_DRIVE = 'not-ready-to-drive',
    NOT_READY_TO_MOVE = 'not-ready-to-move',
    OTHER = 'other',
}

export const VehicleConditionTypeCodec = fromEnum<ApiVehicleConditionType>(
    'ApiVehicleConditionType',
    ApiVehicleConditionType,
);

export const VehicleCodec = t.intersection([
    t.type({
        id: VehicleIdCodec,
        model: ModelCodec,
    }),
    t.partial({
        weight: t.number,
        colour: t.string,
        dimensions: VehicleDimensionsCodec,
        vehicle_type: fromEnum<ApiVehicleType>('ApiVehicleType', ApiVehicleType),
        license_plate: t.string,
        special_instructions: t.array(NonEmptyString),
        delivery_condition: VehicleConditionTypeCodec,
        has_priority: t.boolean,
        manufactured_at: t.string,
        final_destination: AddressAndContactCodec,
    }),
]);

export type ApiVehicle = t.TypeOf<typeof VehicleCodec>;
export type ApiModel = t.TypeOf<typeof ModelCodec>;
export type ApiVehicleId = t.TypeOf<typeof VehicleIdCodec>;
export type ApiVehicleDimensions = t.TypeOf<typeof VehicleDimensionsCodec>;
