import * as t from 'io-ts';
import { NonEmptyString } from 'io-ts-types';
import { fromEnum } from '../responseUtil';

export enum AssetType {
    TRUCK = 'truck',
}

export enum AssetStatus {
    ACTIVE = 'active',
}

const AssetTypeCodec = fromEnum<AssetType>('AssetType', AssetType);
const AssetStatusCodec = fromEnum<AssetStatus>('AssetStatus', AssetStatus);

const AssetsQueryParameterCodec = t.type({
    account_id: NonEmptyString,
    status: AssetStatusCodec,
    type: AssetTypeCodec,
});

const AssetCodec = t.intersection([
    t.type({
        id: NonEmptyString,
    }),
    t.partial({
        license_plate: NonEmptyString,
    }),
]);

export const AssetsCodec = t.type({
    items: t.array(AssetCodec),
});

export type ApiAssets = t.TypeOf<typeof AssetsCodec>;
export type ApiAsset = t.TypeOf<typeof AssetCodec>;
export type ApiAssetsQueryParameter = t.TypeOf<typeof AssetsQueryParameterCodec>;
