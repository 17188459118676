import { VehicleId } from '../../sharedComponents/common.types';
import {
    OriginatedTransportOrder,
    TransportAssignmentStatus,
    TransportAssignmentType,
    TransportOrder,
    TransportOrderBundle,
} from '../transportAssignment.types';
import { ConfirmationFormParams } from './TransportAssignmentConfirmationForm';

export const getVehicleId = (transportOrder: TransportOrder | OriginatedTransportOrder): VehicleId =>
    transportOrder.vehicle.id;

export const getId = (transportOrder: TransportOrder | OriginatedTransportOrder): string => transportOrder.id;

export const getPropertyOfNotFinalizedNotCancelledTransportAssignment = <T extends any>(
    transportAssignment: TransportOrder | TransportOrderBundle,
    getProperty: (transportAssignment: TransportOrder | OriginatedTransportOrder) => T,
): T[] => {
    switch (transportAssignment.type) {
        case TransportAssignmentType.TRANSPORT_ORDER:
            return transportAssignment.isFinalized ||
                transportAssignment.status === TransportAssignmentStatus.CANCELLATION
                ? []
                : [getProperty(transportAssignment)];
        case TransportAssignmentType.TRANSPORT_ORDER_BUNDLE:
            return transportAssignment.deliveries.flatMap((delivery) =>
                delivery.originatedTransportOrders
                    .filter((it) => !(it.isFinalized || it.isCancelled))
                    .map((it) => getProperty(it)),
            );
    }
};

export const mapFormParamsToTransportOrderConfirmation = (
    transportAssignment: TransportOrder | TransportOrderBundle,
    {
        newConfirmedPickUpDate,
        newConfirmedDeliveryDate,
        newMeansOfTransport,
        newUnloadingAddress,
    }: ConfirmationFormParams,
) => ({
    confirmation: {
        transportOrderIds: getPropertyOfNotFinalizedNotCancelledTransportAssignment(transportAssignment, getId),
        vehicleIds: getPropertyOfNotFinalizedNotCancelledTransportAssignment(transportAssignment, getVehicleId),
        pickUpAt: newConfirmedPickUpDate,
        deliverAt: newConfirmedDeliveryDate,
        changedAt: new Date(),
        meansOfTransportId: newMeansOfTransport?.id,
        meansOfTransportAssetId: newMeansOfTransport?.assetId,
        trailerId: newMeansOfTransport?.trailerId,
        unloadingAddressId: newUnloadingAddress?.identifier,
    },
});
