import { TransportCapacityOrder } from '../../transportAssignment.types';
import React from 'react';
import { CapacityDeliveryDetailsRow, PickUpDetailsRow } from './TransportDetailRow';
import { FormattedMessage } from 'react-intl';

type TransportCapacityOrderTransportDetailsProps = {
    readonly transportCapacityOrder: TransportCapacityOrder;
};

export const TransportCapacityOrderTransportDetails = ({
    transportCapacityOrder,
}: TransportCapacityOrderTransportDetailsProps) => {
    const capacity = transportCapacityOrder.requestedTransportCapacity;
    const vehicleStringId =
        capacity === 1
            ? 'outboundPortal.transportAssignments.table.vehicle'
            : 'outboundPortal.transportAssignments.table.vehicles';

    const reservationBox = (
        <div className="display-flex flex-column align-items-center justify-content-center rounded shadow-hard text-center padding-25">
            <div>
                <FormattedMessage id="outboundPortal.transportAssignments.sidebar.capacityDetail" />
                <span className="rioglyph text-size-18 padding-left-5 padding-right-5 rioglyph-group" />
                <span className="text-bold">{capacity}</span> &nbsp;
                <FormattedMessage id={vehicleStringId} />
            </div>
        </div>
    );

    return (
        <>
            <div className="margin-bottom-10">
                <PickUpDetailsRow loading={transportCapacityOrder.loading} footer={reservationBox} />
            </div>
            <div className="text-center text-size-20 margin-bottom-10">
                <span className="rioglyph rioglyph-arrow-down text-size-200pct text-color-gray" aria-hidden="true" />
            </div>
            <CapacityDeliveryDetailsRow unloading={transportCapacityOrder.unloading} />
        </>
    );
};
