import {
    ExecutionStatus,
    ExecutionStatusChanged,
    OrganizationIdentifier,
    OrganizationIdentifierType,
    TransportAssignmentStatus,
    TransportCapacityOrderConfirmation,
    TransportOrderConfirmation,
} from '../../transportAssignment/transportAssignment.types';
import {
    ApiIncidentList,
    ApiTransportCapacityOrderConfirmation,
    ApiTransportOrderConfirmation,
} from '../types/transportAssignment/confirmationApi.types';
import {
    ApiExecutionStatus,
    ApiSort,
    ApiStatus,
    ApiTransportAssignmentQueryParameter,
} from '../types/transportAssignment/transportAssignmentsApi.types';
import {
    ApiAcknowledgedTransportCapacityOrder,
    ApiAcknowledgedTransportOrders,
} from '../types/transportAssignment/acknowledgedTransportAssignmentApi.types';
import { ApiTransportAssignmentStatusChange } from '../types/transportAssignment/transportAssignmentStatusChangeApi.types';
import { ApiArchivedTransportOrders } from '../types/transportAssignment/archiveTransportAssignmentApi.type';
import { NonEmptyString } from 'io-ts-types';
import { ApiActivationRequest, ApiSupplier } from '../types/activationsApi.types';
import { RegistrationInputs } from '../../activation/registration.types';
import { FilterableApiStatus, GetTransportAssignmentsQueryParams, PostIncidentProps } from '../transportOrderApi';
import { TransportAssignmentTableColumn } from '../../transportAssignment/table/TransportAssignmentTableColumns';
import {
    ApiOrganizationIdentifier,
    ApiOrganizationIdentifierType,
} from '../types/transportAssignment/sharedTypesApi.types';
import { VehicleId } from '../../sharedComponents/common.types';
import { SortDirection, TableSort } from '../../sharedComponents/table/SortedTableHeader';
import { mapToApiVehicleId, mapToVehicleConditionApi } from './vehicleMappers';
import isEmpty from 'lodash/isEmpty';

const mapToApiExecutionStatus = (status: ExecutionStatus): ApiExecutionStatus => {
    switch (status) {
        case ExecutionStatus.LOADED:
            return ApiExecutionStatus.LOADED;
        case ExecutionStatus.UNLOADED:
            return ApiExecutionStatus.UNLOADED;
    }
};

export const mapToApiTransportOrderConfirmation = (
    confirmation: TransportOrderConfirmation,
): ApiTransportOrderConfirmation => ({
    transport_order_ids: confirmation.transportOrderIds as NonEmptyString[],
    pick_up_at: confirmation.pickUpAt?.toISOString(),
    deliver_at: confirmation.deliverAt?.toISOString(),
    changed_at: confirmation.changedAt.toISOString(),
    means_of_transport_id: confirmation.meansOfTransportId as NonEmptyString | undefined,
    means_of_transport_asset_id: confirmation.meansOfTransportAssetId as NonEmptyString | undefined,
    trailer_id: confirmation.trailerId as NonEmptyString | undefined,
    vehicle_ids: confirmation.vehicleIds.map((vehicleId: VehicleId) => mapToApiVehicleId(vehicleId)),
    unloading_address_id:
        confirmation.unloadingAddressId && mapToOrganizationIdentifierApi(confirmation.unloadingAddressId),
});

export const mapToApiTransportCapacityOrderConfirmation = (
    confirmation: TransportCapacityOrderConfirmation,
): ApiTransportCapacityOrderConfirmation => ({
    pick_up_at: confirmation.pickUpAt?.toISOString(),
    deliver_at: confirmation.deliverAt?.toISOString(),
    changed_at: confirmation.changedAt.toISOString(),
    means_of_transport_id: confirmation.meansOfTransportId as NonEmptyString | undefined,
    trailer_id: confirmation.trailerId as NonEmptyString | undefined,
});

const mapToOrganizationIdentifierApi = (organizationIdentifier: OrganizationIdentifier): ApiOrganizationIdentifier => ({
    id: organizationIdentifier.id as NonEmptyString,
    type: mapToOrganizationIdentifierType(organizationIdentifier.type),
});

const mapToOrganizationIdentifierType = (
    organizationIdentifierType: OrganizationIdentifierType,
): ApiOrganizationIdentifierType => {
    switch (organizationIdentifierType) {
        case OrganizationIdentifierType.BUYER:
            return ApiOrganizationIdentifierType.BUYER;
    }
};

export const mapToApiAcknowledgedTransportOrders = (transportOrderIds: string[]): ApiAcknowledgedTransportOrders => ({
    transport_order_ids: transportOrderIds,
});

export const mapToApiAcknowledgedTransportCapacityOrder = (
    hasUnacknowledgedExternalChanges: boolean,
): ApiAcknowledgedTransportCapacityOrder => ({
    has_unacknowledged_external_changes: hasUnacknowledgedExternalChanges,
});

export const mapToApiTransportAssignmentStatusChange = ({
    transportOrderIds,
    status,
    vehicleIds,
    changedAt,
    statusAt,
}: ExecutionStatusChanged): ApiTransportAssignmentStatusChange => ({
    transport_order_ids: transportOrderIds,
    changed_at: changedAt.toISOString(),
    statuses: [
        {
            execution_status: mapToApiExecutionStatus(status),
            vehicle_ids: vehicleIds.map((vehicleId: VehicleId) => mapToApiVehicleId(vehicleId)),
            status_at: statusAt.toISOString(),
        },
    ],
});

export const mapToApiArchivedTransportOrders = ({
    transportOrderIds,
    isArchived,
}: {
    transportOrderIds: string[];
    isArchived: boolean;
}): ApiArchivedTransportOrders => ({
    transport_order_ids: transportOrderIds,
    is_archived: isArchived,
});

export const mapToApiActivationRequest = (registrationInputs: RegistrationInputs): ApiActivationRequest => ({
    supplier: ApiSupplier.VW,
    service_provider_identification: registrationInputs.serviceProviderIdentification as NonEmptyString,
    duns: registrationInputs.duns as NonEmptyString,
    interface_access_requested: registrationInputs.interfaceAccessRequested === 'interface',
    ...(registrationInputs.phone.length !== 0 && { phone: registrationInputs.phone as NonEmptyString }),
});

export const mapToTransportAssignmentQueryParameters = (
    queryParameters: GetTransportAssignmentsQueryParams | void,
): ApiTransportAssignmentQueryParameter => ({
    statuses: queryParameters?.statuses && mapFilterableStatusesToApiStatuses(queryParameters.statuses),
    archived: queryParameters?.archived,
    sort: queryParameters?.sortBy && mapToApiSort(queryParameters.sortBy),
    requested_pick_up_at_end_at_after: queryParameters?.requestedPickUpFrom?.toISOString(),
    requested_pick_up_at_start_at_before: queryParameters?.requestedPickUpTo?.toISOString(),
    unloading_city: queryParameters?.unloadingCity,
    loading_city: queryParameters?.loadingCity,
    q: queryParameters?.search,
    limit: queryParameters?.limit?.toFixed(0),
});

export const mapToApiSort = (sorting: TableSort<TransportAssignmentTableColumn>): ApiSort => {
    const sortDirection = sorting.dir;
    if (sortDirection === SortDirection.ASCENDING) {
        switch (sorting.column) {
            case TransportAssignmentTableColumn.REQUESTED_DELIVERY:
                return ApiSort.REQUESTED_DELIVERY_AT_ASC;
            case TransportAssignmentTableColumn.REQUESTED_PICKUP:
                return ApiSort.REQUESTED_PICK_UP_AT_ASC;
            case TransportAssignmentTableColumn.FROM:
                return ApiSort.LOADING_CITY_ASC;
            case TransportAssignmentTableColumn.TO:
                return ApiSort.UNLOADING_CITY_ASC;
            case TransportAssignmentTableColumn.STATUS:
                return ApiSort.STATUS_ASC;
        }
    } else if (sortDirection === SortDirection.DESCENDING) {
        switch (sorting.column) {
            case TransportAssignmentTableColumn.REQUESTED_DELIVERY:
                return ApiSort.REQUESTED_DELIVERY_AT_DES;
            case TransportAssignmentTableColumn.REQUESTED_PICKUP:
                return ApiSort.REQUESTED_PICK_UP_AT_DES;
            case TransportAssignmentTableColumn.FROM:
                return ApiSort.LOADING_CITY_DES;
            case TransportAssignmentTableColumn.TO:
                return ApiSort.UNLOADING_CITY_DES;
            case TransportAssignmentTableColumn.STATUS:
                return ApiSort.STATUS_DES;
        }
    }
    throw new Error('forgot to implement mapper for sorting or column');
};

export const mapFilterableStatusesToApiStatuses = (statusFilter: FilterableApiStatus[]): ApiStatus[] | undefined => {
    if (statusFilter.length <= 0) {
        return undefined;
    }
    return statusFilter.map((status) => mapFilterableStatusToApiStatus(status));
};

export const mapFilterableStatusToApiStatus = (filterableStatus: FilterableApiStatus): ApiStatus => {
    switch (filterableStatus) {
        case FilterableApiStatus.CANCELLATION:
            return ApiStatus.CANCELLATION;
        case FilterableApiStatus.CONFIRMED:
            return ApiStatus.CONFIRMED;
        case FilterableApiStatus.UNCONFIRMED:
            return ApiStatus.UNCONFIRMED;
        case FilterableApiStatus.LOADED:
            return ApiStatus.LOADED;
        case FilterableApiStatus.UNLOADED:
            return ApiStatus.UNLOADED;
    }
};

export const mapToApiStatus = (status: TransportAssignmentStatus): ApiStatus => {
    switch (status) {
        case TransportAssignmentStatus.CANCELLATION:
            return ApiStatus.CANCELLATION;
        case TransportAssignmentStatus.COMPLETE_CONFIRMED:
        case TransportAssignmentStatus.INCOMPLETE_CONFIRMED:
            return ApiStatus.CONFIRMED;
        case TransportAssignmentStatus.LOADED:
            return ApiStatus.LOADED;
        case TransportAssignmentStatus.UNLOADED:
            return ApiStatus.UNLOADED;
        case TransportAssignmentStatus.UNCONFIRMED:
            return ApiStatus.UNCONFIRMED;
    }
};

export const mapToIncidentApi = (incidentList: PostIncidentProps): ApiIncidentList => {
    return {
        changed_at: incidentList.changedAt.toISOString(),
        incidents: incidentList.incidents.map((incident) => ({
            transport_order_id: incident.transportOrderId as NonEmptyString,
            type: mapToVehicleConditionApi(incident.incident.type),
            empty_run: incident.incident.isEmptyRun,
            vehicle_id: mapToApiVehicleId(incident.vehicleId),
            description: isEmpty(incident.incident.description?.trim()) ? undefined : incident.incident.description,
        })),
    };
};
