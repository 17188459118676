import { TransportAssignmentStatus } from './transportAssignment.types';
import { getFirstMatchingCondition } from '../../../utils';
import { AlertConfig } from '../sharedComponents/common.types';

export const getCancelledRowConfig = (isCapacityOrder: boolean): AlertConfig => ({
    highlight: 'danger',
    rowIcon: 'rioglyph-error-sign text-color-danger',
    hintMessageKey: isCapacityOrder
        ? 'outboundPortal.transportAssignments.alerts.cancellationForCapacity'
        : 'outboundPortal.transportAssignments.alerts.cancellation',
});
export const getIncompleteConfirmedRowConfig = (isCapacityOrder: boolean): AlertConfig => ({
    highlight: 'warning',
    rowIcon: 'rioglyph-exclamation-sign text-color-warning',
    hintMessageKey: isCapacityOrder
        ? 'outboundPortal.transportAssignments.sidebar.expanderTitle.incompleteDataForCapacity'
        : 'outboundPortal.transportAssignments.sidebar.expanderTitle.incompleteData',
});
export const getUnacknowledgedExternalChangesRowConfig = (isCapacityOrder: boolean): AlertConfig => ({
    highlight: 'warning',
    rowIcon: 'rioglyph-exclamation-sign text-color-warning',
    hintMessageKey: isCapacityOrder
        ? 'outboundPortal.transportAssignments.alerts.unacknowledgedChangesForCapacity'
        : 'outboundPortal.transportAssignments.alerts.unacknowledgedChanges',
});

export const activeIncidentRowConfig: AlertConfig = {
    rowIcon: 'rioglyph rioglyph-damages text-color-gray',
    hintMessageKey: 'outboundPortal.transportAssignments.alerts.incident',
};

export const getRowAlertConfiguration = (
    hasUnacknowledgedExternalChanges: boolean,
    status: TransportAssignmentStatus,
    hasActiveIncident: boolean,
    isCapacityOrder: boolean,
): AlertConfig[] => {
    const changesConfig = getFirstMatchingCondition(
        [hasUnacknowledgedExternalChanges, getUnacknowledgedExternalChangesRowConfig(isCapacityOrder)],
        [status === TransportAssignmentStatus.INCOMPLETE_CONFIRMED, getIncompleteConfirmedRowConfig(isCapacityOrder)],
        [status === TransportAssignmentStatus.CANCELLATION, getCancelledRowConfig(isCapacityOrder)],
    );
    const incidentConfig = hasActiveIncident ? activeIncidentRowConfig : undefined;

    return [changesConfig ?? [], incidentConfig ?? []].flat();
};
