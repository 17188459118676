import ActionBarItem from '@rio-cloud/rio-uikit/lib/es/ActionBarItem';
import { config } from '../../../config';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { getLocale } from '../../../configuration/lang/langSlice';
import { Link } from 'react-router-dom';
import { WELCOME_ROUTE } from '../routes/routes';
import { SCROLL_TO_FAQ } from '../activation/WelcomePage';
import * as React from 'react';

const ServiceInfoList = (props: { languageForLinks: string }) => {
    const supportFormUrlParams = new URLSearchParams();
    supportFormUrlParams.set('opener', window.location.origin);
    supportFormUrlParams.set('locale', useAppSelector(getLocale) ?? '');
    const supportFormUrl = `https://contact-form.rio.cloud/contact?${supportFormUrlParams.toString()}`;

    return (
        <ActionBarItem.List>
            <ActionBarItem.ListItem icon="rioglyph-question-sign" hasLink>
                <Link to={WELCOME_ROUTE} state={{ scrollTo: SCROLL_TO_FAQ }}>
                    <FormattedMessage id="welcomePage.faq" />
                </Link>
            </ActionBarItem.ListItem>
            <ActionBarItem.ListItem icon="rioglyph-scale" hasLink>
                <a
                    href={`https://rio.cloud/${props.languageForLinks}/documents/outbound-order-book/privacy-policy`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FormattedMessage id="outboundPortal.header.serviceInfo.privacyPolicy" />
                </a>
            </ActionBarItem.ListItem>
            <ActionBarItem.ListItem icon="rioglyph-question-sign" hasLink>
                <a href={supportFormUrl} rel="noreferrer noopener" target="_blank">
                    <FormattedMessage id="outboundPortal.header.serviceInfo.contactSupport" />
                </a>
            </ActionBarItem.ListItem>
        </ActionBarItem.List>
    );
};

export const ServiceInfo = () => {
    const userLanguage = useAppSelector(getLocale) ?? 'en';
    const languageForLinks = userLanguage.substring(0, 2);

    return (
        <ActionBarItem id="serviceInfo">
            <ActionBarItem.Icon>
                <span className="icon rioglyph rioglyph-info-sign" />
            </ActionBarItem.Icon>
            <ActionBarItem.Popover title={config.application.name}>
                <ServiceInfoList languageForLinks={languageForLinks} />
            </ActionBarItem.Popover>
        </ActionBarItem>
    );
};
