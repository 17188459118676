import { FormattedMessage, useIntl } from 'react-intl';
import { getObjectKeyForAccessory, getServiceNameByType } from '../serviceOrderUtils';
import { ServiceOrder, ServiceOrderStatus } from '../ServiceOrders.types';
import DatePicker from '@rio-cloud/rio-uikit/lib/es/DatePicker';
import React from 'react';
import classNames from 'classnames';
import { AccessoryType, CollectedAccessories } from '../accessory.types';
import {
    CompletedAtErrorType,
    ServiceOrderSidebarCompletion,
    SidebarFormErrors,
    SidebarFormFieldError,
} from './ServiceOrderSidebar';
import moment, { Moment } from 'moment';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import { isValidDate } from '../../sharedComponents/dateHelper';
import { LabelWithTimezone } from '../../sharedComponents/LabelWithTimezone';
import { InputSign } from '../../sharedComponents/InputSign';

const AccessoriesInput = (props: {
    requestedAccessories: AccessoryType[];
    onChange: (collectedAccessories: CollectedAccessories) => void;
    collectedAccessoriesFormData: CollectedAccessories;
    accessoryErrors: Pick<SidebarFormErrors, AccessoryType>;
    isDisabled: boolean;
}) => {
    const { requestedAccessories, onChange, collectedAccessoriesFormData } = props;
    return (
        <div>
            <label>
                <FormattedMessage id="outboundPortal.serviceOrder.sidebar.quantities" />
            </label>
            <div className="border rounded padding-15">
                {requestedAccessories.map((accessory: AccessoryType, idx) => (
                    <div
                        className={classNames(
                            idx + 1 < requestedAccessories.length && 'margin-bottom-20',
                            'display-flex justify-content-between align-items-center',
                        )}
                        key={accessory}
                    >
                        <div className="text-size-16 ">
                            <FormattedMessage id={`outboundPortal.serviceOrder.sidebar.accessory.${accessory}`} />
                        </div>
                        <div
                            className={classNames('form-group margin-0', {
                                'has-feedback has-error': !!props.accessoryErrors[accessory],
                            })}
                        >
                            <div className="input-group">
                                <input
                                    id={`${accessory}Input`}
                                    className="width-60 form-control padding-right-0"
                                    disabled={props.isDisabled}
                                    value={
                                        collectedAccessoriesFormData[getObjectKeyForAccessory(accessory)]?.toString() ??
                                        ''
                                    }
                                    onChange={(event) => {
                                        if (isNaN(Number(event.currentTarget.value))) {
                                            return;
                                        }
                                        onChange({
                                            ...collectedAccessoriesFormData,
                                            [getObjectKeyForAccessory(accessory)]: Number(event.currentTarget.value),
                                        });
                                    }}
                                />
                                <div className="input-group-addon">
                                    <FormattedMessage id="outboundPortal.serviceOrder.sidebar.accessory.pcs" />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export const ServiceOrderSidebarBody = (props: {
    serviceOrder: ServiceOrder;
    setCompletionFormData: (formData: ServiceOrderSidebarCompletion) => void;
    completionFormData: ServiceOrderSidebarCompletion;
    errors: SidebarFormErrors;
}) => {
    const { setCompletionFormData, completionFormData, serviceOrder } = props;

    const isDisabled =
        serviceOrder.status === ServiceOrderStatus.CANCELLED || serviceOrder.isFinalized || serviceOrder.isArchived;
    return (
        <div className="margin-20 padding-20 bg-highlight-lightest border border-color-primary rounded">
            <div className="text-color-primary text-medium text-size-14 margin-bottom-20">
                <FormattedMessage id="outboundPortal.serviceOrder.sidebar.pleaseProvide" />
            </div>
            <div className="bg-white border rounded margin-bottom-20 padding-15">
                <div className="text-medium text-size-16">
                    <span className="rioglyph rioglyph-component-custom padding-right-5" />
                    {getServiceNameByType(serviceOrder.type)}
                </div>
                {serviceOrder.description !== undefined && (
                    <div className="margin-top-5">
                        <span className="text-medium">
                            <FormattedMessage id="outboundPortal.serviceOrder.sidebar.serviceDescription" />
                            &nbsp;
                        </span>
                        {serviceOrder.description}
                    </div>
                )}
            </div>
            <div>
                {Object.keys(props.errors).some((key) => Object.values<string>(AccessoryType).includes(key)) && (
                    <InvalidAccessoriesAlert messageId="outboundPortal.common.invalidAccessory" />
                )}
            </div>
            {serviceOrder.vehicle.requestedAccessories.length > 0 && (
                <div className="margin-bottom-20">
                    <AccessoriesInput
                        requestedAccessories={serviceOrder.vehicle.requestedAccessories}
                        onChange={(accessories) => setCompletionFormData({ ...completionFormData, accessories })}
                        collectedAccessoriesFormData={completionFormData.accessories}
                        accessoryErrors={props.errors}
                        isDisabled={isDisabled}
                    />
                </div>
            )}
            <div className="margin-bottom-20">
                <DatePickerForm
                    value={completionFormData.completedAt}
                    onChange={(date) =>
                        setCompletionFormData({
                            ...completionFormData,
                            completedAt: date,
                        })
                    }
                    error={props.errors.completedAt}
                    timeZone={serviceOrder.timeZone}
                    isDisabled={isDisabled}
                />
            </div>
            <div>
                <label className="display-flex flex-wrap justify-content-between" htmlFor="generalInformation">
                    <FormattedMessage id="outboundPortal.serviceOrder.sidebar.generalInformation" />
                    <span className="text-italic">
                        <FormattedMessage id="outboundPortal.serviceOrder.sidebar.generalInformation.optional" />
                    </span>
                </label>
                <textarea
                    id="generalInformation"
                    disabled={isDisabled}
                    className="width-100pct form-control"
                    style={{ resize: 'none' }}
                    rows={5}
                    maxLength={255}
                    value={completionFormData.generalInformation ?? ''}
                    onChange={(text) => {
                        setCompletionFormData({
                            ...completionFormData,
                            generalInformation: text.target.value,
                        });
                    }}
                />
            </div>
        </div>
    );
};

type DatePickerFormProps = {
    error: SidebarFormFieldError<CompletedAtErrorType> | undefined;
    value: Date | string;
    onChange: (value: Date | string) => void;
    timeZone: string;
    isDisabled: boolean;
};

const DatePickerForm = (props: DatePickerFormProps) => {
    const intl = useIntl();

    const { value, timeZone } = props;

    return (
        <div
            className={classNames('width-50pct padding-left-5', {
                'form-group has-feedback margin-bottom-25 has-error': !!props.error,
            })}
        >
            <LabelWithTimezone
                htmlFor="completionDate"
                messageId="outboundPortal.serviceOrder.sidebar.completionDate"
                timeZoneData={{ timeZone, date: isValidDate(value) ? value : undefined }}
            />
            <DatePicker
                inputProps={{
                    id: 'completionDate',
                    disabled: props.isDisabled,
                }}
                clearableInput
                mandatory={false}
                locale={intl.locale}
                alignLeft
                closeOnSelect={false}
                value={isValidDate(value) ? toZonedTime(value, timeZone) : value}
                onChange={(newValue: Moment | string) => {
                    if (moment.isMoment(newValue)) {
                        props.onChange(fromZonedTime(newValue.toDate(), timeZone));
                    } else {
                        props.onChange(newValue);
                    }
                }}
                className={classNames({
                    'has-error margin-bottom-0': !!props.error,
                })}
            />
            {getDateErrorMessage(props.error)}
        </div>
    );
};

const getDateErrorMessage = (error: SidebarFormFieldError<CompletedAtErrorType> | undefined) => {
    if (error === undefined) return undefined;

    switch (error.type) {
        case 'invalidDate':
            return <InputSign messageId="outboundPortal.common.invalidDate" glyph="rioglyph-error-sign" />;
        case 'dateInFuture':
            return (
                <InputSign
                    messageId="outboundPortal.serviceOrder.sidebar.completionDate.inTheFuture"
                    glyph="rioglyph-error-sign"
                />
            );
        case 'completedAtBeforeReceivedAt':
            return (
                <InputSign
                    messageId="outboundPortal.serviceOrder.sidebar.completionDate.dateBeforeReceivedError"
                    glyph="rioglyph-error-sign"
                />
            );
    }
};

const InvalidAccessoriesAlert = (props: { messageId: string }) => {
    return (
        <div className="alert alert-dismissible alert-danger margin-bottom-10">
            <div className="display-flex">
                <span className="text-color-danger text-size-18 margin-right-10 rioglyph rioglyph rioglyph-error-sign" />
                <div>
                    <span className="text-size-14">
                        <FormattedMessage id={props.messageId} />
                    </span>
                </div>
            </div>
        </div>
    );
};
