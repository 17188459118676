import { ToolbarSearch } from '../../../sharedComponents/filter/ToolbarSearch';
import {
    getIncomingCompoundTransportOrderSearch,
    incomingCompoundTransportOrdersSlice,
} from '../../../reducers/incomingCompoundTransportOrdersSlice';

export const IncomingCompoundTransportOrderToolbarSearch = () => {
    return (
        <ToolbarSearch
            getSearchTerm={getIncomingCompoundTransportOrderSearch}
            setSearchTerm={incomingCompoundTransportOrdersSlice.actions.setSearchTerm}
            tooltipMessageId="outboundOrderBook.transportAssignments.table.toolbar.search.tooltip"
            placeholderMessageId="outboundOrderBook.transportAssignments.table.toolbar.search.placeholder"
        />
    );
};
