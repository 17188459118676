import * as t from 'io-ts';
import { NonEmptyString } from 'io-ts-types';

const OptionalAddressCodec = t.partial({
    name: NonEmptyString,
    street: NonEmptyString,
    additional_name_information: NonEmptyString,
    additional_street_information: NonEmptyString,
    postal_code: NonEmptyString,
    city: NonEmptyString,
    country_code: NonEmptyString,
});

export const FinalDestinationCodec = t.partial({
    identifier: NonEmptyString,
    address: OptionalAddressCodec,
});

export type ApiOptionalAddress = t.TypeOf<typeof OptionalAddressCodec>;
export type ApiFinalDestination = t.TypeOf<typeof FinalDestinationCodec>;
