import { useId } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MeansOfTransport } from '../../../transportAssignment.types';
import { ValidatedFormGroup } from '../ValidatedFormGroup';
import { ValidationResult } from '../confirmationForm.types';
import { SimpleAssetInput } from '../meansOfTransportInput/SimpleAssetInput';

type TrailerFormInputProps = {
    meansOfTransport: MeansOfTransport | undefined;
    setTrailerId: (value: string | undefined) => void;
    validation: ValidationResult;
    isFormDisabled: boolean;
};
export const TrailerFormInput = ({
    meansOfTransport,
    setTrailerId,
    validation,
    isFormDisabled,
}: TrailerFormInputProps) => {
    const inputId = useId();
    const intl = useIntl();

    const inputProps = {
        id: inputId,
        placeholder: intl.formatMessage({
            id: 'outboundOrderBook.transportAssignments.sidebar.confirmationForm.trailerId.placeholder',
        }),
        value: meansOfTransport?.trailerId ?? '',
        disabled: isFormDisabled,
    };

    return (
        <div className="width-50pct padding-left-5">
            <label htmlFor={inputId} className="display-flex flex-column flex-wrap justify-content-between height-35">
                <span>
                    <FormattedMessage id="outboundOrderBook.transportAssignments.sidebar.confirmationForm.trailerId" />
                </span>
                <span className="text-italic">
                    <FormattedMessage id="outboundOrderBook.transportAssignments.sidebar.confirmationForm.trailerId.info" />
                </span>
            </label>
            <ValidatedFormGroup validation={validation}>
                <SimpleAssetInput setAssetName={setTrailerId} {...inputProps} />
            </ValidatedFormGroup>
        </div>
    );
};
