import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import { formatIntervalTimeZone, formatTimeZone } from './dateHelper';
import { Interval } from 'date-fns';

type LabelWithTimezoneProps = {
    messageId: string;
    timeZoneData: { timeZone: string; date?: Date | number | Interval };
    htmlFor?: string;
    className?: string;
};
export const LabelWithTimezone = (props: LabelWithTimezoneProps) => {
    const intl = useIntl();

    let timeZoneString;
    if (props.timeZoneData.date === undefined) {
        return (
            <label htmlFor={props.htmlFor} className={props.className}>
                <FormattedMessage id={props.messageId} />
            </label>
        );
    }
    if (isInterval(props.timeZoneData.date)) {
        timeZoneString = formatIntervalTimeZone(props.timeZoneData.date, props.timeZoneData.timeZone);
    } else {
        timeZoneString = formatTimeZone(props.timeZoneData.date, props.timeZoneData.timeZone);
    }
    return (
        <label
            htmlFor={props.htmlFor}
            className={props.className}
        >{`${intl.formatMessage({ id: props.messageId })} (${timeZoneString})`}</label>
    );
};

const isInterval = (value: Date | number | Interval | undefined): value is Interval => {
    return value !== undefined && typeof value !== 'number' && 'start' in value && 'end' in value;
};
