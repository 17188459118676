import { ActivationStatus } from '../activation/activation.types';

export enum ServiceProviderAction {
    REQUEST_ACCEPT = 'REQUEST_ACCEPT',
    REQUEST_REJECT = 'REQUEST_REJECT',
    CLIENT_CREATE = 'CLIENT_CREATE',
    CLIENT_DELETE = 'CLIENT_DELETE',
}

export enum Tenant {
    VW_PROD = 'VW_PROD',
    VW_TEST = 'VW_TEST',
    VW_QA = 'VW_QA',
    RIO_TEST = 'RIO_TEST',
    LSP_TEST = 'LSP_TEST',
}

export type ServiceProvider = {
    accountId: string;
    supplier: string;
    serviceProviderName: string;
    serviceProviderId: string;
    duns: string;
    registrationDate: Date;
    activationDate?: Date;
    apiAccessRequested: boolean;
    activationStatus: ActivationStatus;
    tenants: Tenant[];
    clientId?: string;
};

export type ServiceProviderActivation = {
    accountId: string;
    activatedAt: Date;
    activationStatus: ActivationStatus;
    serviceProviderId: string;
    apiAccessGranted: boolean;
};

export type ServiceProviderPage = {
    serviceProviders: ServiceProvider[];
    allowedActions: ServiceProviderAction[];
};
