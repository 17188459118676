export enum ApiServiceType {
    X01 = 'x01',
    X02 = 'x02',
    X03 = 'x03',
    X04 = 'x04',
    X05 = 'x05',
    X06 = 'x06',
    X07 = 'x07',
    X08 = 'x08',
    X09 = 'x09',
    X10 = 'x10',
    X11 = 'x11',
    X12 = 'x12',
    X13 = 'x13',
    X14 = 'x14',
    X15 = 'x15',
    X16 = 'x16',
    X17 = 'x17',
    X18 = 'x18',
    X19 = 'x19',
    X20 = 'x20',
    X21 = 'x21',
    X22 = 'x22',
    X23 = 'x23',
    X24 = 'x24',
    X25 = 'x25',
    X26 = 'x26',
    X27 = 'x27',
    X99 = 'x99',
}
