import classNames from 'classnames';
import partition from 'lodash/fp/partition';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { ActivationStatus } from '../activation/activation.types';
import { ServiceProvider } from './serviceProvider.types';

export const COUNT_DISPLAY_TEST_ID = 'COUNT_DISPLAY_TEST_ID';

type CountDisplayProps = { count: number; labelId: string; color: string };

type ServiceProviderCountsProps = {
    serviceProviders: ServiceProvider[];
};

const CountDisplay: FunctionComponent<CountDisplayProps> = ({ count, labelId, color }) => (
    <div data-testid={COUNT_DISPLAY_TEST_ID} className="display-flex align-items-center margin-left-10 margin-right-5">
        <span className={`label label-condensed ${color} label-filled text-size-16`}>{count}</span>
        <span className="text-size-16 margin-left-5">
            <FormattedMessage id={labelId} />
        </span>
    </div>
);

export const ServiceProviderCounts: FunctionComponent<ServiceProviderCountsProps> = ({ serviceProviders }) => {
    const summaryClassNames = classNames(
        'callout',
        'callout-primary',
        'display-flex',
        'align-items-center',
        'margin-bottom-15',
    );

    const [active, pending] = partition((sp) => sp.activationStatus === ActivationStatus.ACTIVE, serviceProviders);

    return (
        <div className={summaryClassNames}>
            <CountDisplay
                color="label-primary"
                count={pending.length}
                labelId="outboundOrderBook.serviceProviders.pending"
            />
            <CountDisplay
                color="label-success"
                count={active.length}
                labelId="outboundOrderBook.serviceProviders.active"
            />
        </div>
    );
};
