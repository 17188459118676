import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import { ReactNode, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { runInBackgroundCallback } from '../../../../../configuration/setup/backgroundActions';
import { useAppDispatch } from '../../../../../configuration/setup/hooks';
import { usePostIncidentMutation } from '../../../api/transportOrderApi';
import { handleQueryError } from '../../../notifications/ErrorNotification';
import { showSuccessNotification } from '../../../notifications/SuccessNotification';
import { transportAssignmentsSlice } from '../../../reducers/transportAssignmentsSlice';
import { Model, VehicleConditionType, VehicleId } from '../../../sharedComponents/common.types';
import { formatDateTimeInTimeZone } from '../../../sharedComponents/dateHelper';
import {
    ReportedIncident,
    TransportAssignment,
    TransportAssignmentStatus,
    TransportOrderBundleDeliveries,
} from '../../transportAssignment.types';
import { isBundle, isTransportOrder } from '../../TransportAssignmentUtils';

export const MAX_DESCRIPTION_LENGTH = 255;

type IncidentRowData = {
    incident?: ReportedIncident;
    vehicleId: VehicleId;
    model: Model;
    isDisabled: boolean;
};

type IncidentModalProps = {
    transportAssignment: TransportAssignment;
};

type IncidentRowProps = {
    data: IncidentRowData;
    formData: FormData;
    onChange: (newValue: FormData) => void;
};

type IncidentModalDialogProps = {
    transportAssignment: TransportAssignment;
    show: boolean;
    onHide: () => void;
};

type FormData = {
    transportOrderId: string;
    isDisabled: boolean;
    selected: boolean;
    incidentType: VehicleConditionType | undefined;
    emptyRun: boolean;
    description: string;
    hasTypeSelectError: boolean;
    hasDescriptionError: boolean;
};

const getIncidentTypeSelectOptions = (): {
    id: VehicleConditionType;
    label: ReactNode;
}[] =>
    Object.values(VehicleConditionType).map((vehicleConditionType) => ({
        id: vehicleConditionType,
        label: (
            <FormattedMessage
                id={`outboundPortal.transportAssignments.incident.SelectTypeOption.${camelCase(vehicleConditionType)}`}
            />
        ),
    }));

const mapTransportAssignmentToIncidentRow = (
    transportAssignment: TransportAssignment,
): Map<string, IncidentRowData> => {
    const rowData = new Map<string, IncidentRowData>();
    if (isBundle(transportAssignment)) {
        transportAssignment.deliveries.forEach((deliveries: TransportOrderBundleDeliveries) =>
            deliveries.originatedTransportOrders.forEach((oto) => {
                const isDisabled = oto.isFinalized || oto.isCancelled || oto.isLoaded;
                rowData.set(oto.id, {
                    incident: oto.incident,
                    vehicleId: oto.vehicle.id,
                    model: oto.vehicle.model,
                    isDisabled,
                });
            }),
        );
    }
    if (isTransportOrder(transportAssignment)) {
        const isDisabled =
            transportAssignment.isFinalized ||
            transportAssignment.status === TransportAssignmentStatus.CANCELLATION ||
            transportAssignment.status === TransportAssignmentStatus.LOADED;
        rowData.set(transportAssignment.id, {
            incident: transportAssignment.incident,
            vehicleId: transportAssignment.vehicle.id,
            model: transportAssignment.vehicle.model,
            isDisabled,
        });
    }
    return rowData;
};

const IncidentTypeSelect = ({ formData, onChange }: Pick<IncidentRowProps, 'formData' | 'onChange'>) => {
    const incidentTypeSelectOptions = useMemo(getIncidentTypeSelectOptions, []);
    return (
        <div
            className={classNames('form-group margin-0 flex-basis-100pct', {
                'has-error': formData.hasTypeSelectError,
                'has-feedback': formData.hasTypeSelectError,
            })}
        >
            <Select
                placeholder={<FormattedMessage id="outboundPortal.transportAssignments.incident.selectIncident" />}
                options={incidentTypeSelectOptions}
                onChange={(item) => onChange({ ...formData, incidentType: item?.id, hasTypeSelectError: false })}
                value={formData.incidentType !== undefined ? [formData.incidentType] : undefined}
                disabled={formData.isDisabled}
                hasError={formData.hasTypeSelectError}
            />
            {formData.hasTypeSelectError && <span className="form-control-feedback rioglyph rioglyph-error-sign" />}
        </div>
    );
};

const IncidentRow = (props: IncidentRowProps) => {
    const { formData, onChange, data } = props;
    const intl = useIntl();

    return (
        <>
            <div className="separator">
                <hr className="margin-0" />
            </div>
            <div
                className={classNames('display-flex gap-10 align-items-center padding-10', {
                    'bg-lightest': formData.isDisabled,
                    'bg-highlight-lightest': formData.selected && !formData.isDisabled,
                    'padding-bottom-25': formData.hasDescriptionError,
                })}
            >
                <Checkbox
                    className="flex-basis-70pct"
                    onChange={() => onChange({ ...formData, selected: !formData.selected })}
                    checked={formData.selected}
                    disabled={formData.isDisabled}
                >
                    <span className="rioglyph rioglyph-car margin-right-5 pull-left margin-top-4" aria-hidden="true" />
                    <span title={data.model.name} className="display-block ellipsis-1 overflow-hidden max-width-150">
                        {data.model.name}
                    </span>
                </Checkbox>
                <span className="flex-basis-70pct">{data.vehicleId.vin ?? data.vehicleId.productionNumber}</span>
                <div className="flex-basis-100pct text-bold">
                    {data.incident && (
                        <FormattedMessage id="outboundPortal.transportAssignments.incident.incidentReported">
                            {(txt) => (
                                <>
                                    {[
                                        txt,
                                        formatDateTimeInTimeZone(
                                            data.incident!.changedAt,
                                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                                        ),
                                    ].join(' ')}
                                </>
                            )}
                        </FormattedMessage>
                    )}
                </div>
                <IncidentTypeSelect formData={formData} onChange={onChange} />
                <div className="margin-right-10">
                    <label className="margin-bottom-0">
                        <FormattedMessage id="outboundPortal.transportAssignments.incident.emptyRun" />
                    </label>
                    <Checkbox
                        onChange={() => onChange({ ...formData, emptyRun: !formData.emptyRun })}
                        checked={formData.emptyRun}
                        disabled={formData.isDisabled}
                    >
                        <FormattedMessage id="outboundOrderBook.common.yes" />
                    </Checkbox>
                </div>
                <div
                    className={classNames('form-group margin-0 flex-basis-100pct', {
                        'has-error': formData.hasDescriptionError,
                        'has-feedback': formData.hasDescriptionError,
                    })}
                >
                    <input
                        type="text"
                        className="form-control"
                        placeholder={intl.formatMessage({
                            id: 'outboundPortal.transportAssignments.incident.addDescription',
                        })}
                        value={formData.description}
                        onChange={(event) =>
                            onChange({
                                ...formData,
                                description: event.currentTarget.value,
                                hasDescriptionError:
                                    formData.hasDescriptionError &&
                                    event.currentTarget.value.length > MAX_DESCRIPTION_LENGTH,
                            })
                        }
                        disabled={formData.isDisabled}
                    />
                    {formData.hasDescriptionError && (
                        <>
                            <span className="form-control-feedback rioglyph rioglyph-error-sign" />
                            <span className="help-block">
                                <span>
                                    <FormattedMessage id="outboundPortal.transportAssignments.incident.descriptionLenghtError" />{' '}
                                    (Max 255)
                                </span>
                            </span>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

const IncidentModalDialog = (props: IncidentModalDialogProps) => {
    const incidentRows = mapTransportAssignmentToIncidentRow(props.transportAssignment);
    const [query, queryResult] = usePostIncidentMutation();
    const dispatch = useAppDispatch();

    const initialFormData: FormData[] = [...incidentRows.entries()].map(([id, rowData]) => {
        return {
            transportOrderId: id,
            selected: !!rowData.incident,
            incidentType: rowData.incident?.type,
            emptyRun: rowData.incident?.isEmptyRun ?? false,
            description: rowData.incident?.description ?? '',
            hasTypeSelectError: false,
            hasDescriptionError: false,
            isDisabled: rowData.isDisabled || rowData.incident !== undefined,
        };
    });
    const [formData, setFormData] = useState(initialFormData);

    const isActiveAndSelectedRow = (data: FormData) => data.selected && !data.isDisabled;

    const isFormDataValid = () => {
        return formData
            .filter(isActiveAndSelectedRow)
            .every((data) => data.incidentType !== undefined && data.description.length <= MAX_DESCRIPTION_LENGTH);
    };

    const updateFormError = () => {
        const newFormData = [...formData];
        newFormData.filter(isActiveAndSelectedRow).forEach((data) => {
            data.hasTypeSelectError = data.incidentType === undefined;
            data.hasDescriptionError = data.description.length > MAX_DESCRIPTION_LENGTH;
        });
        setFormData(newFormData);
    };

    const sendIncident = runInBackgroundCallback(async () => {
        if (isFormDataValid()) {
            const result = await query({
                changedAt: new Date(),
                incidents: formData.filter(isActiveAndSelectedRow).map((data) => ({
                    transportOrderId: data.transportOrderId,
                    incident: { type: data.incidentType!, description: data.description, isEmptyRun: data.emptyRun },
                    vehicleId: incidentRows.get(data.transportOrderId)!.vehicleId,
                })),
            });

            if (result.hasOwnProperty('error')) {
                handleQueryError((result as { error: FetchBaseQueryError | SerializedError }).error);
            } else {
                showSuccessNotification('outboundPortal.notification.reportIncident.success');
                closeDialog();
                dispatch(transportAssignmentsSlice.actions.deselectTransportAssignment());
            }
        } else {
            updateFormError();
        }
    });

    const closeDialog = () => {
        setFormData(initialFormData);
        props.onHide();
    };

    const updateFormData = (newData: FormData, id: string) => {
        const newFormData = formData.map((data) => {
            if (data.transportOrderId === id) {
                return newData;
            }
            return data;
        });
        setFormData(newFormData);
    };

    const allSelectableAreSelected =
        formData.filter((data) => !data.isDisabled).length > 0 &&
        formData.filter((data) => !data.isDisabled).every((it) => it.selected);

    const selectAll = () => {
        const newFormData = formData.map((data) => {
            if (!data.isDisabled) {
                return { ...data, selected: !allSelectableAreSelected };
            }
            return data;
        });
        setFormData(newFormData);
    };

    const incidentModalBody = (
        <>
            <div>
                <FormattedMessage
                    id="outboundPortal.transportAssignments.incident.pleaseSelectVehicles"
                    values={{
                        strong: (chunks) => <strong>{chunks}</strong>,
                    }}
                />
            </div>
            <Checkbox
                className="padding-10"
                onChange={selectAll}
                checked={allSelectableAreSelected}
                disabled={formData.every((data) => data.isDisabled)}
            >
                <FormattedMessage id="outboundPortal.selectAll" />
            </Checkbox>
            {formData.map((data) => (
                <IncidentRow
                    data={incidentRows.get(data.transportOrderId)!}
                    formData={data}
                    onChange={(newValue: FormData) => updateFormData(newValue, data.transportOrderId)}
                    key={data.transportOrderId}
                />
            ))}
        </>
    );

    const footer = (
        <div className="pull-right btn-toolbar">
            <button type="button" className="btn btn-default" onClick={closeDialog}>
                <FormattedMessage id="outboundPortal.cancel" />
            </button>
            <button
                type="button"
                className="btn btn-primary"
                onClick={sendIncident}
                disabled={!formData.some(isActiveAndSelectedRow) || queryResult.isLoading}
                data-testid="incident-submit-button"
            >
                <FormattedMessage id="outboundPortal.transportAssignments.reportIncident" />
            </button>
        </div>
    );

    return (
        <Dialog
            show={props.show}
            title={<FormattedMessage id="outboundPortal.transportAssignments.reportIncident" />}
            body={incidentModalBody}
            footer={footer}
            bsSize={Dialog.SIZE_LG}
            onHide={closeDialog}
            showCloseButton
        />
    );
};

export const IncidentModal = (props: IncidentModalProps) => {
    const [showDialog, setShowDialog] = useState(false);

    return (
        <>
            <button type="button" className="btn btn-primary, btn-link" onClick={() => setShowDialog(true)}>
                <span className="rioglyph rioglyph-damages" aria-hidden="true" />
                <FormattedMessage id="outboundPortal.transportAssignments.reportIncident" />
            </button>
            <IncidentModalDialog
                transportAssignment={props.transportAssignment}
                show={showDialog}
                onHide={() => setShowDialog(false)}
                key={
                    /* this resets all internal IncidentModalDialog component state if transportAssignment changes */
                    props.transportAssignment.id
                }
            />
        </>
    );
};
