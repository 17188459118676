import * as t from 'io-ts';
import { fromEnum } from '../../responseUtil';
import { NonEmptyString } from 'io-ts-types';
import { ApiCategory, ApiFuelType, ApiVehicleType, VehicleDimensionsCodec } from '../vehicleApi.types';
import { FinalDestinationCodec } from './finalDestination.types';

export const ModelCodec = t.intersection([
    t.type({
        name: NonEmptyString,
        group: NonEmptyString,
    }),
    t.partial({
        version: NonEmptyString,
        fuel_type: fromEnum<ApiFuelType>('ApiFuelType', ApiFuelType),
        manufacturer: NonEmptyString,
        category: fromEnum<ApiCategory>('ApiCategory', ApiCategory),
    }),
]);
export const VehicleCodec = t.intersection([
    t.type({
        vin: NonEmptyString,
        model: ModelCodec,
    }),
    t.partial({
        vehicle_type: fromEnum<ApiVehicleType>('ApiVehicleType', ApiVehicleType),
        manufactured_at: NonEmptyString,
        final_destination: FinalDestinationCodec,
        dimensions: VehicleDimensionsCodec,
        weight: t.number,
        colour: NonEmptyString,
        license_plate: NonEmptyString,
    }),
]);

export type ApiModel = t.TypeOf<typeof ModelCodec>;
export type ApiVehicle = t.TypeOf<typeof VehicleCodec>;
