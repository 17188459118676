import { combineReducers, ReducersMapObject } from '@reduxjs/toolkit';
import { compoundManagementSlice, CompoundManagementState } from './compoundManagementSlice';
import {
    incomingCompoundTransportOrdersSlice,
    IncomingCompoundTransportOrdersState,
} from './incomingCompoundTransportOrdersSlice';
import { serviceOrdersSlice, ServiceOrdersState } from './serviceOrdersSlice';
import { transportAssignmentsSlice, TransportAssignmentsState } from './transportAssignmentsSlice';
import { viewSlice, ViewState } from './viewSlice';

export type PortalState = {
    transportAssignments: TransportAssignmentsState;
    incomingCompoundTransportOrders: IncomingCompoundTransportOrdersState;
    serviceOrders: ServiceOrdersState;
    compoundManagement: CompoundManagementState;
    view: ViewState;
};

export const portalReducer = combineReducers<ReducersMapObject<PortalState>>({
    transportAssignments: transportAssignmentsSlice.reducer,
    incomingCompoundTransportOrders: incomingCompoundTransportOrdersSlice.reducer,
    serviceOrders: serviceOrdersSlice.reducer,
    compoundManagement: compoundManagementSlice.reducer,
    view: viewSlice.reducer,
});
