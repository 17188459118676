import { FormattedMessage } from 'react-intl';

type UnacknowledgedChangesAlertProps = {
    handleOnClick: (value: boolean) => void;
    alertMessage: string;
};

export const UnacknowledgedChangesAlert = ({ handleOnClick, alertMessage }: UnacknowledgedChangesAlertProps) => {
    return (
        <div className="margin-10">
            <div className="alert alert-dismissible alert-warning">
                <button
                    type="button"
                    className="btn btn-icon-only close"
                    data-dismiss="alert"
                    onClick={() => {
                        handleOnClick(false);
                    }}
                >
                    <span className="rioglyph rioglyph-remove" />
                </button>
                <FormattedMessage id={alertMessage} />
            </div>
        </div>
    );
};
