import * as t from 'io-ts';

export const CollectedAccessoriesCodec = t.partial({
    key: t.number,
    license_plate: t.number,
    manual: t.number,
    service_schedule: t.number,
    summer_wheels: t.number,
    vehicle_registration_document: t.number,
    winter_wheels: t.number,
});

export enum ApiAccessoryType {
    KEY = 'key',
    LICENSE_PLATE = 'license-plate',
    MANUAL = 'manual',
    SERVICE_SCHEDULE = 'service-schedule',
    SUMMER_WHEELS = 'summer-wheels',
    VEHICLE_REGISTRATION_DOCUMENT = 'vehicle-registration-document',
    WINTER_WHEELS = 'winter-wheels',
}

export type ApiCollectedAccessories = t.TypeOf<typeof CollectedAccessoriesCodec>;
